import { useState } from "react";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";
import {
  Button,
  Spinner,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  FormGroup,
  Modal,
  Input,
} from "reactstrap";
import { FaArrowRight } from "react-icons/fa";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { getSessionEmail, getAuthorization } from "webauthn/session";
import { webAuthnRegister } from "../../../webauthn";

import CheckoutForm from "../../../views/components/checkoutForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Settings = (props) => {
  const history = useHistory();
  const birthDate = props.businessProfile.customer_profile?.birth_date;

  const [loading, setLoading] = useState(false);

  const [customerLoading, setCustomerLoading] = useState(false);
  const [firstName, setFirstName] = useState(
    props.businessProfile.customer_profile?.first_name
  );
  const [lastName, setLastName] = useState(
    props.businessProfile.customer_profile?.last_name
  );
  const [phone, setPhone] = useState(
    props.businessProfile.customer_profile?.phone
  );

  const [taxFileNumber, setTaxFileNumber] = useState(
    props.businessProfile.customer_profile?.tax_file_number
  );
  const [dayOfBirth, setDayOfBirth] = useState(
    birthDate ? DateTime.fromISO(birthDate).toFormat("d") : ""
  );
  const [monthOfBirth, setMonthOfBirth] = useState(
    birthDate ? DateTime.fromISO(birthDate).toFormat("LL") : "01"
  );
  const [yearOfBirth, setYearOfBirth] = useState(
    birthDate ? DateTime.fromISO(birthDate).toFormat("yyyy") : ""
  );

  const [stripeLoading, setStripeLoading] = useState(false);
  const [planModalOpen, setPlanModalOpen] = useState(false);
  const [stripeClientSecret, setStripeClientSecret] = useState();
  const [total, setTotal] = useState(0);
  const [planId, setPlanId] = useState(0);
  const [subscriptionStarted, setSubcriptionStarted] = useState(false);

  /*
  const register = async () => {
    setLoading(true);
    const email = getSessionEmail();
    await webAuthnRegister({
      displayName: email,
      name: email,
      username: email,
    });

    const authorization = await getAuthorization();

    await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/enrol-passkey`,
      {
        headers: {
          Authorization: authorization,
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify({}),
      }
    );

    setLoading(false);

    // TODO: do this nicely later
    window.location.reload();
  };
  */

  const updateCustomer = async () => {
    if (!yearOfBirth || !dayOfBirth) {
      alert("Please specify your birth date");
      return;
    }

    setCustomerLoading(true);
    const authorization = await getAuthorization();

    await fetch(`${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer`, {
      headers: {
        Authorization: authorization,
        "Content-Type": "application/json",
      },
      method: "put",
      body: JSON.stringify({
        first_name: firstName,
        last_name: lastName,
        phone,
        tax_file_number: taxFileNumber,
        birth_date: `${yearOfBirth}-${monthOfBirth}-${dayOfBirth}`,
      }),
    });

    setCustomerLoading(false);

    // TODO: do this nicely later
    window.location.reload();
  };

  const startSubscription = async () => {
    setSubcriptionStarted(true);
    setStripeLoading(true);

    // Record conversion in Google Ads
    window.gtag("event", "conversion", {
      send_to: "AW-312837668/OexRCPGxzaADEKSMlpUB",
    }); // Begin checkout conversion action

    const options = {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: await getAuthorization(),
      },
      body: JSON.stringify({
        plan_id: planId,
      }),
    };
    const result = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/service-fee/credit-card`,
      options
    );
    const resultJSON = await result.json();

    setStripeClientSecret(resultJSON.client_secret);
    setStripeLoading(false);
  };

  const modalToggled = async () => {
    setPlanModalOpen(!planModalOpen);
    setStripeClientSecret(undefined);
    if (planModalOpen) {
      setSubcriptionStarted(false);
    }
  };

  const changePlan = async (selectedPlanId) => {
    setPlanId(selectedPlanId);
    if (selectedPlanId === 1) {
      setTotal(2900);
    } else if (selectedPlanId === 2) {
      setTotal(4900);
    } else if (selectedPlanId === 3) {
      setTotal(11900);
    }
  };

  const upgradePlan = async () => {
    setPlanModalOpen(true);
    setPlanId(1);
  };

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={planModalOpen}
        toggle={() => modalToggled()}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Select Plan
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => modalToggled()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="custom-control custom-radio mb-3">
            <input
              className="custom-control-input"
              defaultChecked
              id="customRadio2"
              name="custom-radio-1"
              type="radio"
              onClick={() => changePlan(1)}
              disabled={subscriptionStarted}
            />
            <label className="custom-control-label" htmlFor="customRadio2">
              Sole Trader Plan - $29/month
            </label>
          </div>
          <div className="custom-control custom-radio mb-3">
            <input
              className="custom-control-input"
              id="customRadio3"
              name="custom-radio-1"
              type="radio"
              onClick={() => changePlan(2)}
              disabled={subscriptionStarted}
            />
            <label className="custom-control-label" htmlFor="customRadio3">
              Sole Trader GST Plan - $49/month + GST
            </label>
          </div>
          <div className="custom-control custom-radio mb-3">
            <input
              className="custom-control-input"
              id="customRadio4"
              name="custom-radio-1"
              type="radio"
              onClick={() => changePlan(3)}
              disabled={subscriptionStarted}
            />
            <label className="custom-control-label" htmlFor="customRadio4">
              Company Accounting Plan - $79/month + GST
            </label>
          </div>
        </div>
        <Row>
          <Col xs="12">
            <div className="text-right ml-4 mr-4 mb-4">
              {stripeClientSecret ? (
                <Elements
                  stripe={stripePromise}
                  options={{ clientSecret: stripeClientSecret }}
                >
                  <CheckoutForm
                    total={total}
                    payButtonText={"Subscribe"}
                    returnURL={
                      process.env.REACT_APP_STRIPE_SUBSCRIPTION_REDIRECT_URL
                    }
                  />
                </Elements>
              ) : (
                <Button
                  color="success"
                  onClick={() => startSubscription(planId)}
                  disabled={stripeLoading}
                >
                  {stripeLoading ? (
                    <Spinner animation="border" variant="light" size="sm" />
                  ) : null}{" "}
                  Subscribe <FaArrowRight />
                </Button>
              )}
            </div>
          </Col>
        </Row>
      </Modal>
      {/* Page content */}
      <Container className="mt--5" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Account Settings</h3>
                  </Col>
                  <Col className="text-right" xs="4"></Col>
                </Row>
              </CardHeader>
              <CardBody>
                <h6 className="heading-small text-muted mb-4">
                  Passkey Security
                </h6>
                <div className="text-center">
                  <Button
                    className="mt-4"
                    color="primary"
                    type="button"
                    onClick={() => history.push("/dashboard/settings/passkeys")}
                  >
                    <>
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-lock" />
                      </span>
                      <span className="btn-inner--text">View Passkeys</span>
                    </>
                  </Button>
                  <br />
                  <br />
                </div>
                <hr />
                <h6 className="heading-small text-muted mb-4">
                  Business Owner Details
                </h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-first-name"
                        >
                          First name
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-first-name"
                          placeholder="First name"
                          type="text"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-last-name"
                        >
                          Last name
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-last-name"
                          placeholder="Last name"
                          type="text"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-phone"
                        >
                          Phone
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-phone"
                          placeholder="Enter a phone number"
                          type="text"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-phone"
                        >
                          Tax File Number
                        </label>
                        <Input
                          className="form-control-alternative"
                          placeholder="Tax File Number"
                          type="text"
                          value={taxFileNumber}
                          onChange={(e) => setTaxFileNumber(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-phone"
                        >
                          Date of Birth
                        </label>
                        <Row>
                          <Col lg="4">
                            <Input
                              className="form-control-alternative"
                              placeholder="Day"
                              type="text"
                              value={dayOfBirth}
                              onChange={(e) => setDayOfBirth(e.target.value)}
                            />
                          </Col>
                          <Col lg="4">
                            <Input
                              type="select"
                              onChange={(e) => setMonthOfBirth(e.target.value)}
                              value={monthOfBirth}
                            >
                              <option value="01">January</option>
                              <option value="02">February</option>
                              <option value="03">March</option>
                              <option value="04">April</option>
                              <option value="05">May</option>
                              <option value="06">June</option>
                              <option value="07">July</option>
                              <option value="08">August</option>
                              <option value="09">September</option>
                              <option value="10">October</option>
                              <option value="11">November</option>
                              <option value="12">December</option>
                            </Input>
                          </Col>
                          <Col lg="4">
                            <Input
                              className="form-control-alternative"
                              placeholder="Year"
                              type="text"
                              value={yearOfBirth}
                              onChange={(e) => setYearOfBirth(e.target.value)}
                            />
                          </Col>
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Button
                      className="mt-4"
                      color="primary"
                      type="button"
                      onClick={() => updateCustomer()}
                      disabled={customerLoading}
                    >
                      <span className="btn-inner--text">
                        {customerLoading ? (
                          <Spinner
                            animation="border"
                            variant="light"
                            size="sm"
                          />
                        ) : null}{" "}
                        Save Changes
                      </span>
                    </Button>
                  </FormGroup>
                </div>
                <hr />
                <h6 className="heading-small text-muted mb-4">Select Plan</h6>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          {props.businessProfile.stripe_subscriptions?.[0]?.plan
                            ? props.businessProfile.stripe_subscriptions[0].plan
                            : "Free Trial Plan"}
                        </label>
                        <p>
                          $
                          {props.businessProfile.stripe_subscriptions?.[0]
                            ?.amount
                            ? props.businessProfile.stripe_subscriptions[0]
                                .amount / 100
                            : "0"}
                          /month
                        </p>
                      </FormGroup>
                      <FormGroup>
                        <Button
                          className="mt-4"
                          color="primary"
                          type="button"
                          onClick={() => upgradePlan()}
                        >
                          <span className="btn-inner--text">Upgrade Plan</span>
                        </Button>
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-username"
                        >
                          Payment Card
                        </label>
                        <p>
                          {props.businessProfile.stripe_payment_methods?.[0]
                            ?.last4
                            ? `•••• ${props.businessProfile.stripe_payment_methods[0].last4}`
                            : "None on file"}
                        </p>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Settings;
