import {

    Progress,
} from 'reactstrap';

const ProgressBar = (props) => {

    const currentProgressPercentage = (props.step / props.stepCount * 100).toFixed(0);

    return (
        <>
            <h1>{props.title}</h1>
            <div className="progress-wrapper">
                <div className="progress-info">
                    <div className="progress-label">
                        <span>Step {props.step} of {props.stepCount}: {props.stepName}</span>
                    </div>
                    <div className="progress-percentage">
                        <span>{currentProgressPercentage}%</span>
                    </div>
                </div>
                <Progress max={props.stepCount} value={props.step} />
            </div>
        </>
    );
}

export default ProgressBar;