import React from "react";
import { Container } from "reactstrap";

import FlowNavbar from "components/Navbars/FlowNavbar";
import SimpleFooter from "components/Footers/SimpleFooter.js";

const Terms = () => {
  return (
    <>
      <FlowNavbar />
      <Container>
        <br />
        <h1>Terms &amp; Conditions</h1>
        <br />
        <p>
          By subscribing to any plan, or making any purchase you agree that in
          the event you subsequently change your mind and request a refund you
          will agree to pay a $5 administrative charge. We reserve the right to
          waive this fee at our sole discretion.
        </p>
        <p>
          In the event that we have made a filing or registration on your behalf
          to a government agency or other entity, and we cannot obtain a refund
          you agree to indemnify us for that charge and we may set it off
          against any fees you have already paid us.
        </p>
        <p>
          We endeavour to use our best efforts to obtain and process your
          refunds and/or amend your registrations, but in some cases government
          filings and registrations are final and cannot be refunded.
        </p>
        <br />
        <p>
          By submitting the ABN Registration form, I declare that Thornton &amp;
          Associates Pty Ltd (ABN 28 631 107 714) is authorised to submit an ABN
          application to the Australian Tax Office on my behalf and to do so as
          my registered tax agent, and if necessary, submit a Business Name
          application to the Australian Securities & Investments Commission
          (ASIC). I understand that Thornton & Associates Pty Ltd will utilise
          information supplied by me in this form to apply for an ABN, and if
          necessary, will remain my ASIC agent and notify me when my business
          name requires renewal. I confirm that I have received the consent of
          all individuals referred to in this application form, to the
          submission of their personal information contained in this form to
          Thornton & Associates Pty Ltd and to the ATO and ASIC as necessary to
          process this application. I verify that the information supplied is,
          to the best of my knowledge, accurate and complete. I also confirm:
        </p>
        <ul>
          <li>
            I am not disqualified from managing corporations under Section
            206B(1) of the Corporations Act 2001.
          </li>
          <li>
            Within the last 5 years I have not been convicted of, or released
            from prison after being convicted of, and serving a term of
            imprisonment for, any of the criminal offences referred to in
            Section 32(1)(c) or (d) of the Business Names Registration Act 2011.
          </li>
          <li>
            This application is submitted under, and is compliant with, the
            terms and conditions of the ASIC Electronic Lodgement Protocol.
          </li>
          <li>
            The information supplied is, to the best of my knowledge, accurate
            and complete.
          </li>
          <li>
            I have the TFN, date of birth and name, OR name, address and date of
            birth for all key people in my business.
          </li>
          <li>
            I can provide evidence that I am entitled to an ABN if requested.
          </li>
          <li>
            To provide true and correct information in my ABN application.
            Penalties of up to $12,600 can apply for each false or misleading
            statement made in an ABN application.
          </li>
          <li>
            Once I have an ABN I must keep it up to date (within 28 days of any
            change) and cancel it once I am no longer trading or carrying on an
            enterprise.
          </li>
        </ul>
        <h2>Accounting Services</h2>
        <p>
          If you instruct us to act on your behalf or on behalf of your
          business, including by but not limited to: purchasing a subscription
          plan that includes accounting services or BAS or Tax Return
          preparation, you give us (Thornton & Associates Pty Ltd) express
          permission and authority to submit and lodge any and all documents
          required to process your tax affairs to the Australian Tax Office
          (ATO), the Australian Securities and Investments Commission (ASIC) or
          any other taxation or government authority required.
        </p>
        <p>
          If you discontinue your payments under any plan we may discontinue to
          act for you and you will become solely responsible for the lodgement
          of any BAS or Tax Returns or any regulatory obligations for yourself
          or your business or any related party.
        </p>
        <h2>Software Platform Services</h2>
        <p>
          The accountedfor Software Platform is provided by A4 Technology Pty
          Ltd (ABN 49 672 208 472).
        </p>
        <h2>Financial Services</h2>
        <p>
          A4 Technology Pty Ltd may provide you with financial services during
          your usage of the accountefor Software Platform, or in order to
          provide accounting services to you.
        </p>
        <p>
          If A4 Technology Pty Ltd does so, it provides you with a financial
          service under an exemption to Corporations Act 2001 (Cth) s 911A
          contained within Corporations (FinTech Sandbox Australian Financial
          Services Licence Exemption) Regulations 2020.
        </p>
        <p>
          Under that law, A4 Technology Pty Ltd is required to, and maintains,
          adequate professional indemnity insurance and is a member of
          Australian Financial Complains Authority (AFCA), an external dispute
          resolution body that can handle disputes that may arise during and
          after you receive financial services. However, A4 Technology Pty Ltd
          is not an Australian Financial Services (AFS) licensee or an
          authorised representative of an AFS licensee which means that some of
          the normal protections associated with receiving financial services
          will not apply.
        </p>
        <p>
          While A4 Technology Pty Ltd provides you financial services, it will
          also provide to you written notice about a range of things important
          to you about its financial services, including but not limited to the
          following:
        </p>
        <ul>
          <li>
            If A4 Technology Pty Ltd has ceased to carry on a financial services
            business
          </li>

          <li>
            If A4 Technology Pty Ltd has become a Chapter 5 body corporate or,
            under a foreign law, if A4 Technology Pty Ltd has started to be in a
            similar position to a Chapter 5 body corporate
          </li>
          <li>
            If A4 Technology Pty Ltd has become bankrupt or has applied to take
            the benefit of any law for the relief of bankrupt or insolvent
            debtors
          </li>
          <li>
            If A4 Technology Pty Ltd has compounded with its creditors, or has
            made an assignment of your remuneration for the benefit of its
            creditors
          </li>
          <li>
            If A4 Technology Pty Ltd has become authorised by an AFS licence to
            provide financial services
          </li>
          <li>
            A4 Technology Pty Ltd has become an authorised representative for
            the provision of financial services
          </li>
          <li>
            If A4 Technology Pty Ltd has ceased to rely on the exemption
            provided under the Corporations (FinTech Sandbox Australian
            Financial Services Licence Exemption) Regulations 2020
          </li>
          <li>
            If the financial service A4 Technology Pty Ltd provides or a
            financial product to which it relates has materially changed
          </li>
          <li>
            If A4 Technology Pty Ltd is no longer offering the financial service
            and/or a financial product to which it relates to new clients.
          </li>
        </ul>
        <p>
          In addition to the above, A4 Technology Pty Ltd is delighted to have
          the opportunity to serve you, and seeks to provide a new and
          innovative experience of payments, taxation and compliance that
          endures and grows with your business.
        </p>
        <br />
        <br />
        <br />
        <br />
      </Container>
      <SimpleFooter />
    </>
  );
};

export default Terms;
