
import React from 'react';
import {
    NavbarBrand,
    Navbar,
    NavItem,
    Nav,
    NavLink,
    Container,
} from 'reactstrap';


const FlowNavbar = () => {
    return (
        <>
            <Navbar className="navbar-dark bg-primary mb-4" expand="lg">
            <Container>
              <NavbarBrand href="/" style={{textTransform: 'none'}}>
                accountedfor.com.au
              </NavbarBrand>

              <Nav className="ml-auto" navbar>
                <NavItem>
                    <NavLink
                      href="tel:+611300201329"
                      style={{color: 'white'}}
                    >
                      <span className="btn-inner--icon text-white">
                        Support <i className="fa fa-phone mr-2" />1300 201 329
                      </span>
                    </NavLink>
                  </NavItem>
                  </Nav>
            </Container>
          </Navbar>
        </>
    );
}

export default FlowNavbar;