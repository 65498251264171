import { useState } from 'react';
import { DateTime } from 'luxon';
import {
    Button,
    Spinner,
    Card,
    CardHeader,
    CardBody,
    Container,
    Row,
    Col,
    FormGroup,
    Modal,
    Input,
} from 'reactstrap';
import { FaArrowRight } from 'react-icons/fa';

import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { AutofillDisabledInput } from 'views/components/autofillDisabledInput';

import { getAuthorization } from 'webauthn/session';

const IdentityVerification = (props) => {
    const birthDate = props.businessProfile.customer_profile?.birth_date;

    const [loading, setLoading] = useState(false);

    const [customerLoading, setCustomerLoading] = useState(false);
    const [firstName, setFirstName] = useState(props.businessProfile.customer_profile?.first_name);
    const [lastName, setLastName] = useState(props.businessProfile.customer_profile?.last_name);

    const [driverLicenceState, setDriverLicenceState] = useState('NSW');
    const [driverLicenceNumber, setDriverLicenceNumber] = useState('');
    const [driverLicenceCardNumber, setDriverLicenceCardNumber] = useState('');

    const [dayOfBirth, setDayOfBirth] = useState(
        birthDate ? DateTime.fromISO(birthDate).toFormat('d') : ''
    );
    const [monthOfBirth, setMonthOfBirth] = useState(
        birthDate ? DateTime.fromISO(birthDate).toFormat('LL') : '01'
    );
    const [yearOfBirth, setYearOfBirth] = useState(
        birthDate ? DateTime.fromISO(birthDate).toFormat('yyyy') : ''
    );

    const [autocompletedAddress, setAutocompletedAddress] = useState(null);
    const [geocodedAddress, setGeocodedAddress] = useState(null);
    const handleAutocompletedAddressChange = async (address) => {
        setAutocompletedAddress(address);
        if (address) {
            const result = await geocodeByPlaceId(address.value.place_id);
            setGeocodedAddress(result);
        }
    };

    const verifyIdentity = async () => {
        if (!yearOfBirth || !dayOfBirth) {
            alert('Please specify your birth date');
            return;
        }

        if (!autocompletedAddress) {
            alert('Please specify your address');
            return;
        }

        if (!driverLicenceNumber || !driverLicenceCardNumber) {
            alert('Please specify your Driver Licence details');
            return;
        }

        setCustomerLoading(true);
        const authorization = await getAuthorization();

        const result = await fetch(`${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/identity-verification`, {
            headers: {
                Authorization: authorization,
                'Content-Type': 'application/json',
            },
            method: 'post',
            body: JSON.stringify({
                first_name: firstName,
                last_name: lastName,
                birth_date: `${yearOfBirth}-${monthOfBirth}-${dayOfBirth}`,
                address: geocodedAddress,
                driver_licence_state: driverLicenceState,
                driver_licence_number: driverLicenceNumber,
                driver_licence_card_number: driverLicenceCardNumber,
            }),
        });

        const resultJSON = await result.json();
        setCustomerLoading(false);

        if (resultJSON.status === 'ok') {
            alert('Successfully submitted Identity Verification Documents');

            // XXX: this is a hack that is required to reload the document verification status on the
            // payment accounts page
            window.location.href = '/dashboard/payment-accounts';
        } else {
            alert('Error: Could not submit Identity Verification Documents');
        }
    }

    return (
        <>
            {/* Page content */}
            <Container className="mt--5" fluid>
                <Row>
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">Identity Verification</h3>
                                    </Col>
                                    <Col className="text-right" xs="4">
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <h6 className="heading-small text-muted mb-4">
                                    Individual's Document Details
                                </h6>
                                <div className="pl-lg-4">
                                    <Row>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-first-name"
                                                >
                                                    First Name
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-first-name"
                                                    placeholder="First name"
                                                    type="text"
                                                    value={firstName}
                                                    onChange={e => setFirstName(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col lg="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-last-name"
                                                >
                                                    Last Name
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-last-name"
                                                    placeholder="Last name"
                                                    type="text"
                                                    value={lastName}
                                                    onChange={e => setLastName(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-phone"
                                                >
                                                    Date of Birth
                                                </label>
                                                <Row>
                                                    <Col lg="4">
                                                        <Input
                                                            className="form-control-alternative"
                                                            placeholder="Day"
                                                            type="text"
                                                            value={dayOfBirth}
                                                            onChange={e => setDayOfBirth(e.target.value)}
                                                        />
                                                    </Col>
                                                    <Col lg="4">
                                                        <Input
                                                            type="select"
                                                            onChange={(e) => setMonthOfBirth(e.target.value)}
                                                            value={monthOfBirth}
                                                        >
                                                            <option value="01">January</option>
                                                            <option value="02">February</option>
                                                            <option value="03">March</option>
                                                            <option value="04">April</option>
                                                            <option value="05">May</option>
                                                            <option value="06">June</option>
                                                            <option value="07">July</option>
                                                            <option value="08">August</option>
                                                            <option value="09">September</option>
                                                            <option value="10">October</option>
                                                            <option value="11">November</option>
                                                            <option value="12">December</option>
                                                        </Input>
                                                    </Col>
                                                    <Col lg="4">
                                                        <Input
                                                            className="form-control-alternative"
                                                            placeholder="Year"
                                                            type="text"
                                                            value={yearOfBirth}
                                                            onChange={e => setYearOfBirth(e.target.value)}
                                                        />
                                                    </Col>
                                                </Row>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-phone"
                                                >
                                                    Address
                                                </label>
                                                <GooglePlacesAutocomplete
                                                    apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                                                    autocompletionRequest={{
                                                        componentRestrictions: {
                                                            country: ['au'],
                                                        },
                                                        types: ['address'],
                                                    }}
                                                    selectProps={{
                                                        components: { Input: AutofillDisabledInput },
                                                        isClearable: true,
                                                        value: autocompletedAddress,
                                                        onChange: handleAutocompletedAddressChange,
                                                        placeholder: 'Start typing address',

                                                    }}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-phone"
                                                >
                                                    Driver Licence State
                                                </label>
                                                <Input
                                                    type="select"
                                                    onChange={(e) => setDriverLicenceState(e.target.value)}
                                                    value={driverLicenceState}
                                                >
                                                    <option value="ACT">ACT</option>
                                                    <option value="NSW">NSW</option>
                                                    <option value="NT">NT</option>
                                                    <option value="QLD">QLD</option>
                                                    <option value="SA">SA</option>
                                                    <option value="TAS">TAS</option>
                                                    <option value="VIC">VIC</option>
                                                    <option value="WA">WA</option>
                                                </Input>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-phone"
                                                >
                                                    Driver Licence Number
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    placeholder="Driver Licence Number"
                                                    type="text"
                                                    value={driverLicenceNumber}
                                                    onChange={e => setDriverLicenceNumber(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="12">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-phone"
                                                >
                                                    Driver Licence Card Number
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    placeholder="Driver Licence Card Number"
                                                    type="text"
                                                    value={driverLicenceCardNumber}
                                                    onChange={e => setDriverLicenceCardNumber(e.target.value)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <FormGroup>
                                        <Button
                                            className="mt-4"
                                            color="primary"
                                            type="button"
                                            onClick={() => verifyIdentity()}
                                            disabled={customerLoading}
                                        >
                                            <span className="btn-inner--text">
                                                {customerLoading
                                                    ? <Spinner animation="border" variant="light" size="sm" />
                                                    : null
                                                } Verify
                                            </span>
                                        </Button>
                                    </FormGroup>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default IdentityVerification;
