import React, { useState } from 'react';
import { Card, CardHeader, CardBody, CardFooter, Form, FormGroup, FormFeedback, Label, Input, Spinner, Button, Container, Row, Col, Table } from 'reactstrap';
import { FaArrowRight } from 'react-icons/fa';
import Select from 'react-select';
import countryList from 'react-select-country-list';

import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { AutofillDisabledInput } from 'views/components/autofillDisabledInput';

import FlowNavbar from 'components/Navbars/FlowNavbar';
import ProgressBar from '../../../components/progressBar';
import SimpleFooter from 'components/Footers/SimpleFooter.js';


const ShareStructure = (props) => {
    const stepNumber = 4;

    const [loading, setLoading] = useState(false);
    const [submissionAttempted, setSubmissionAttempted] = useState(false);

    const soleDirectorName = `${localStorage.getItem('firstName')} ${localStorage.getItem('lastName')}`

    const [shareClass1, setShareClass1] = useState('ORD')
    const [shareCount1, setShareCount1] = useState(1)
    const [sharePrice1, setSharePrice1] = useState('2.00')
    const [beneficiallyHeld1, setBeneficiallyHeld1] = useState(true)

    const [shareClass2, setShareClass2] = useState('')
    const [shareCount2, setShareCount2] = useState('')
    const [sharePrice2, setSharePrice2] = useState('')
    const [beneficiallyHeld2, setBeneficiallyHeld2] = useState(true)

    const [shareClass3, setShareClass3] = useState('')
    const [shareCount3, setShareCount3] = useState('')
    const [sharePrice3, setSharePrice3] = useState('')
    const [beneficiallyHeld3, setBeneficiallyHeld3] = useState(true)


    const submitForm = async () => {

        const result = await fetch(`${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/company-application/share-classes`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                company_uuid: localStorage.getItem('companyUUID'),
                share_classes: [
                    {
                        member_uuid: localStorage.getItem('soleDirectorShareholderMemberUUID'), // XXX: Hack, for now!
                        share_class_name: shareClass1,
                        share_count: shareCount1,
                        share_price: sharePrice1,
                        beneficially_held: beneficiallyHeld1,
                    },
                    {
                        member_uuid: localStorage.getItem('soleDirectorShareholderMemberUUID'), // XXX: Hack, for now!
                        share_class_name: shareClass2,
                        share_count: shareCount2,
                        share_price: sharePrice2,
                        beneficially_held: beneficiallyHeld2,
                    },
                    {
                        member_uuid: localStorage.getItem('soleDirectorShareholderMemberUUID'), // XXX: Hack, for now!
                        share_class_name: shareClass3,
                        share_count: shareCount3,
                        share_price: sharePrice3,
                        beneficially_held: beneficiallyHeld3,
                    },
                ]
            })
        });
        await result.json();

        //window.location = '/lodge/company-registration/user-registration';

        localStorage.setItem('planId', 6);
        window.location = '/checkout';
    };

    return (
        <>
            <FlowNavbar />
            <Container>
                <br />
                <ProgressBar title={`${localStorage.getItem('companyName')} PTY LTD`} step={stepNumber} stepCount={5} stepName="Share Structure" />
                <br />

                <div>
                    <Form onSubmit={async (e) => { e.preventDefault(); await submitForm() }}>

                        <Card className="text-left shadow border-1 mb-5">
                            <CardHeader>Step {stepNumber}. Share Structure</CardHeader>
                            <CardBody>

                                {/*<h3>Share Structure</h3>

                                <br />
                                <br />
                                <br />

                                put pie chart here

                                <br />
                                <br />
                                <br />*/}

                                <h3>Share Allocation</h3>
                                <p>Companies are required to list the different classes of shares at incorporation.
                                However, for most sole director shareholder companies, a simple capital structure is best</p>
                                <p>Note: You can create up to 3 share classes now, but this can be changed once you have started your company</p>
                                <br />
                                <br />
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th width="30%">Shareholder</th>
                                            <th>Share Class</th>
                                            <th>Number of Shares</th>
                                            <th>Price per Share ($)</th>
                                            <th>Beneficially Held</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">
                                                <Input
                                                    type="select"
                                                    onChange={(e) => { }}
                                                    value={1}
                                                >
                                                    <option value="1">{soleDirectorName}</option>
                                                </Input>
                                            </th>
                                            <td>
                                                <Input
                                                    type="text"
                                                    placeholder="ORD"
                                                    value={shareClass1}
                                                    onChange={(e) => setShareClass1(e.target.value)}
                                                    invalid={false}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type="text"
                                                    placeholder="100"
                                                    value={shareCount1}
                                                    onChange={(e) => setShareCount1(e.target.value)}
                                                    invalid={false}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type="text"
                                                    placeholder="$1.00"
                                                    value={sharePrice1}
                                                    onChange={(e) => setSharePrice1(e.target.value)}
                                                    invalid={false}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type="select"
                                                    onChange={(e) => setBeneficiallyHeld1(e.target.value === "yes")}
                                                    value={beneficiallyHeld1 === true ? "yes" : "no"}
                                                >
                                                    <option value="yes">Yes</option>
                                                    <option value="no">No</option>
                                                </Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">
                                                <Input
                                                    type="select"
                                                    onChange={(e) => { }}
                                                    value={1}
                                                >
                                                    <option value="1">{soleDirectorName}</option>
                                                </Input>
                                            </th>
                                            <td>
                                                <Input
                                                    type="text"
                                                    placeholder=""
                                                    value={shareClass2}
                                                    onChange={(e) => setShareClass2(e.target.value)}
                                                    invalid={false}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type="text"
                                                    placeholder=""
                                                    value={shareCount2}
                                                    onChange={(e) => setShareCount2(e.target.value)}
                                                    invalid={false}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type="text"
                                                    placeholder=""
                                                    value={sharePrice2}
                                                    onChange={(e) => setSharePrice2(e.target.value)}
                                                    invalid={false}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type="select"
                                                    onChange={(e) => setBeneficiallyHeld2(e.target.value === "yes")}
                                                    value={beneficiallyHeld2 === true ? "yes" : "no"}
                                                >
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </Input>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">
                                                <Input
                                                    type="select"
                                                    onChange={(e) => { }}
                                                    value={1}
                                                >
                                                    <option value="1">{soleDirectorName}</option>
                                                </Input>
                                            </th>
                                            <td>
                                                <Input
                                                    type="text"
                                                    placeholder=""
                                                    value={shareClass3}
                                                    onChange={(e) => setShareClass3(e.target.value)}
                                                    invalid={false}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type="text"
                                                    placeholder=""
                                                    value={shareCount3}
                                                    onChange={(e) => setShareCount3(e.target.value)}
                                                    invalid={false}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type="text"
                                                    placeholder=""
                                                    value={sharePrice3}
                                                    onChange={(e) => setSharePrice3(e.target.value)}
                                                    invalid={false}
                                                />
                                            </td>
                                            <td>
                                                <Input
                                                    type="select"
                                                    onChange={(e) => setBeneficiallyHeld3(e.target.value === "yes")}
                                                    value={beneficiallyHeld3 === true ? "yes" : "no"}
                                                >
                                                    <option value="true">Yes</option>
                                                    <option value="false">No</option>
                                                </Input>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>


                            </CardBody>
                            <CardFooter className="text-right">
                                <Button
                                    type="submit"
                                    color="success"
                                    disabled={loading}
                                >
                                    {loading
                                        ? <Spinner animation="border" variant="light" size="sm" />
                                        : null
                                    } Continue <FaArrowRight />
                                </Button>
                            </CardFooter>
                        </Card>

                    </Form>
                </div>
            </Container>
            <SimpleFooter />
        </>
    );
}

export default ShareStructure;