import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

const businessChecklistAction = () => {
  alert('Coming soon!');
}

const Index = (props) => {
  const abnRegistration = props.businessProfile.abn_registration;
  const abnPending = abnRegistration && abnRegistration.abn === null;
  const abn = abnRegistration && abnRegistration.abn !== null;

  return (
    <>
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="8">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Business Checklist</h3>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Item</th>
                    <th scope="col" className="text-right">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Register ABN</th>
                    <td className="text-right">
                      {abn ? `${abnRegistration.abn} ✅` : null}
                      {abnPending ? `ABN PENDING ✅` : null}
                      {!abnRegistration
                        ? <>
                          <Button
                            color="primary"
                            onClick={() => businessChecklistAction()}
                            size="lg"
                          >
                            BYO ABN
                          </Button>
                          <Button
                            color="success"
                            href="/lodge/abn-application"
                            size="lg"
                          >
                            Register ABN
                          </Button>
                        </>
                        : null}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Open Business Account</th>
                    <td className="text-right">
                      {props.businessProfile.bank_account?.bsb
                        ? <>BSB: {props.businessProfile.bank_account?.bsb.slice(0, 3)}-{props.businessProfile.bank_account?.bsb.slice(3)} Account Number: {props.businessProfile.bank_account?.account_number} ✅</>
                        : <Button
                          color="success"
                          onClick={() => window.location.href = `/dashboard/payment-accounts`}
                          size="lg"
                        >
                          Open Business Account
                        </Button>}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Create First Customer</th>
                    <td className="text-right">
                      <Button
                        color="success"
                        onClick={() => window.location.href = `/dashboard/customers/add`}
                        size="lg"
                      >
                        Create Customers
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Send First Invoice</th>
                    <td className="text-right">
                      <Button
                        color="success"
                        onClick={() => window.location.href = `/dashboard/live-invoice/add`}
                        size="lg"
                      >
                        Send Invoice
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Reconcile First Transaction</th>
                    <td className="text-right">
                      <Button
                        color="success"
                        onClick={() => businessChecklistAction()}
                        size="lg"
                      >
                        Reconcile Transactions
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Accept Card Payments</th>
                    <td className="text-right">
                      <Button
                        color="success"
                        onClick={() => businessChecklistAction()}
                        size="lg"
                      >
                        Setup Card Payments
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Setup a Company</th>
                    <td className="text-right">
                      <Button
                        color="success"
                        onClick={() => window.location.href = `/lodge/company-registration`}
                        size="lg"
                      >
                        Register a Company
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
          <Col xl="4">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Business Healthcheck</h3>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Item</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">First Card Expense</th>
                    <td>Pending</td>
                  </tr>
                  <tr>
                    <th scope="row">First Invoice Viewed</th>
                    <td>Pending</td>
                  </tr>
                  <tr>
                    <th scope="row">First Invoice Paid</th>
                    <td>Pending</td>
                  </tr>
                  <tr>
                    <th scope="row">First Profitable Month</th>
                    <td>Pending</td>
                  </tr>
                  <tr>
                    <th scope="row">Revenue Goal Met 🎉</th>
                    <td>Pending</td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;