import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DateTime } from "luxon";
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
  Col,
  Button,
  Badge,
} from "reactstrap";
import { getPasskeyJWTAsync } from "../../../webauthn/securityStepUp";
import { getAuthorization } from "webauthn/session";

const ListExpenses = () => {
  const principalLegalEntity = JSON.parse(
    localStorage.getItem("principalLegalEntity")
  );

  const history = useHistory();

  const [invoices, setInvoices] = useState([]);

  const [expenses, setExpenses] = useState([]);

  useEffect(() => {
    (async () => {
      if (principalLegalEntity?.agent_legal_entity?.customer_uuid) {
        const response = await fetch(
          `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/expense`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
              "x-principal-legal-entity-uuid":
                principalLegalEntity.legal_entity_uuid,
            },
          }
        );
        const json = await response.json();
        setExpenses(json.invoices);
      } else {
        const authorization = await getAuthorization();
        const response = await fetch(
          `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/expense`,
          {
            headers: {
              Authorization: authorization,
            },
          }
        );
        const json = await response.json();
        setExpenses(json.invoices);
      }
    })();
  }, [setExpenses]);

  const mapInvoiceStatus = (status) => {
    const invoiceStatusMap = {
      DRAFT: "Draft",
      SENT: "Sent",
    };

    return invoiceStatusMap[status];
  };

  const mapInvoiceStatusIcon = (status) => {
    const invoiceStatusIconMap = {
      DRAFT: "bg-info",
      SENT: "bg-success",
    };

    return invoiceStatusIconMap[status];
  };

  const duplicateInvoice = async (invoiceUUID) => {
    const authorization = await getAuthorization();
    const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/invoice/${invoiceUUID}/duplicate`,
      {
        headers: {
          Authorization: authorization,
        },
        method: "post",
      }
    );
    if (response.ok) {
      const json = await response.json();
      history.push(`/dashboard/live-invoice/${json.invoice_uuid}`);
    } else {
      alert("Could not duplicate invoice");
    }
  };

  return (
    <>
      {/* Page content */}
      <Container className="mt--5" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Expenses</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="success"
                      onClick={() => history.push("/dashboard/expenses/add")}
                      size="sm"
                    >
                      Add Expense
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Supplier</th>
                    <th scope="col">Amount</th>
                    <th scope="col">GST</th>
                    <th scope="col">Created Date</th>
                    <th scope="col" className="text-center">
                      Receipt
                    </th>
                    <th scope="col" className="text-center">
                      Edit
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {expenses.map((expense, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {DateTime.fromISO(
                            expense.effective_date
                          ).toLocaleString(DateTime.DATE_MED)}
                        </td>
                        <th>
                          {expense.counterparty_business_name === ""
                            ? expense.counterparty_abr_main_name
                            : expense.counterparty_business_name}
                        </th>
                        <td>
                          {new Intl.NumberFormat("en-AU", {
                            style: "currency",
                            currency: "AUD",
                          }).format(
                            parseFloat(expense.invoice_amount) +
                              parseFloat(expense.tax_amount)
                          )}
                        </td>
                        <td>
                          {new Intl.NumberFormat("en-AU", {
                            style: "currency",
                            currency: "AUD",
                          }).format(parseFloat(expense.tax_amount))}
                        </td>
                        <td>
                          {DateTime.fromISO(expense.created_at).toLocaleString(
                            DateTime.DATETIME_MED
                          )}
                        </td>
                        <td className="text-center">
                          {expense.attachment_s3_object_key ? "🧾" : ""}
                        </td>
                        <td className="text-center">
                          <UncontrolledDropdown>
                            <DropdownToggle
                              className="btn-icon-only text-light"
                              role="button"
                              size="sm"
                              color=""
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fas fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-arrow" right>
                              <DropdownItem
                                onClick={() =>
                                  history.push(
                                    `/dashboard/expenses/${expense.uuid}`
                                  )
                                }
                              >
                                Edit Expense
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <CardFooter className="py-4"></CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ListExpenses;