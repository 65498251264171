
import React from 'react';
import {
    NavbarBrand,
    Navbar,
    NavItem,
    Nav,
    Container,
    Button,
} from 'reactstrap';


const CustomerNavbar = (props) => {
    return (
        <>
            <Navbar className="navbar-light bg-white mb-4" expand="lg" style={{ zIndex: 10 }}>
                <Container>
                    <NavbarBrand onClick={() => { }} style={{ textTransform: 'none' }}>
                        {props.merchantName}
                    </NavbarBrand>
                    <Nav className="ml-auto" navbar>
                        {props.actionDisabled
                            ? <Button
                                color="primary"
                                href={`${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/public/invoice/${props.invoiceUUID}/download`}
                                size="md"
                            >
                                Download PDF
                            </Button>
                            : <NavItem className="align-items-lg-center ml-lg-auto">
                                <Button
                                    className="btn-primary btn-icon"
                                    color="success"
                                    onClick={() => props.action()}
                                >
                                    <span className="nav-link-inner--text ml-1">
                                        Pay Invoice
                                    </span>
                                </Button>
                            </NavItem>
                        }
                    </Nav>
                </Container>
            </Navbar>
        </>
    );
}

export default CustomerNavbar;