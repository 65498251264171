import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { DateTime } from "luxon";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Modal,
  Spinner,
} from "reactstrap";
import AsyncSelect from "react-select/async";
import { DatePicker } from "reactstrap-date-picker";

import { emptyLiveInvoiceUUID } from "../../../platform/customer/invoice/taxDocument";

import { PDFViewer } from "@react-pdf/renderer";
import { InvoicePreview } from "../Invoice/InvoicePreview";

import {
  getNextInvoiceSerial,
  getMerchantsCustomerList,
  sendTest,
  saveDraft,
  sendNow,
  getBusinessProfile,
  getMerchantLogo,
  loadAsyncSelectMerchantsCustomerList,
  lineItemDescriptionChange,
  lineItemQuantityChange,
  lineItemUnitPriceChange,
  lineItemGSTChange,
  getInvoiceByUUID,
} from "./invoice";

const LiveInvoiceDetail = () => {
  const history = useHistory();
  const params = useParams();

  const [invoiceLoaded, setInvoiceLoaded] = useState(false);

  const [testLoading, setTestLoading] = useState(false);
  const [saveDraftLoading, setSaveDraftLoading] = useState(false);
  const [sendLoading, setSendLoading] = useState(false);

  const [previewPDFModalIsOpen, setPreviewPDFModalIsOpen] = useState(false);
  const [previewEmailModalIsOpen, setPreviewEmailModalIsOpen] = useState(false);
  const [previewSMSModalIsOpen, setPreviewSMSModalIsOpen] = useState(false);

  const [profile, setProfile] = useState({});
  const [bankAccount, setBankAccount] = useState({});

  const [merchantLogo, setMerchantLogo] = useState();

  const [customerContacts, setCustomerContacts] = useState([]);

  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const customerContactUUID = selectedCustomer[0]?.value;

  const [invoiceDate, setInvoiceDate] = useState(new Date().toISOString());
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invoiceLineItems, setInvoiceLineItems] = useState([
    {
      description: "",
      quantity: "",
      unit_price: "",
      tax_rate: "0",
    },
  ]);
  const [invoiceNotes, setInvoiceNotes] = useState("");

  const [externalBSB, setExternalBSB] = useState("");
  const [externalAccountNumber, setExternalAccountNumber] = useState("");

  useEffect(() => {
    (async () => {
      const profile = await getBusinessProfile();
      setProfile(profile.customer_profile);
      setBankAccount(profile.bank_account);

      const invoiceSerial = await getNextInvoiceSerial();
      setInvoiceNumber(invoiceSerial);

      const logoURL = await getMerchantLogo();
      setMerchantLogo(logoURL);

      const counterpartyList = await getMerchantsCustomerList();

      if (params.uuid !== "add") {
        const invoice = await getInvoiceByUUID(params.uuid);

        if (invoice.customer_contact) {
          setSelectedCustomer([invoice.customer_contact]);
        }
        if (invoice.counterparty_uuid) {
          setSelectedCustomer([
            counterpartyList.filter(
              (counterparty) => counterparty.value === invoice.counterparty_uuid
            )[0],
          ]);
        }
        setInvoiceDate(invoice.effective_date);
        setInvoiceNumber(invoice.invoice_serial);
        const preLoadedLineItems = [
          ...invoice.line_items,
          {
            description: "",
            quantity: "",
            unit_price: "",
            tax_rate: "0",
          },
        ];
        setInvoiceLineItems(preLoadedLineItems);
        setInvoiceNotes(invoice.notes);
        setExternalBSB(invoice.external_bsb);
        setExternalAccountNumber(invoice.external_account_number);
      }

      setInvoiceLoaded(true);
    })();
  }, [
    params.uuid,
    setInvoiceNumber,
    setInvoiceDate,
    setSelectedCustomer,
    setInvoiceLineItems,
    setInvoiceLoaded,
  ]);

  const fetchCustomerContacts = async () => {
    setCustomerContacts(await getMerchantsCustomerList());
  };

  // XXX: lol
  const data = {
    logo: merchantLogo,
    firstName: selectedCustomer?.[0]?.label,
    lastName: "",
    businessName:
      profile?.business_name || `${profile?.first_name} ${profile?.last_name}`,
    abn: profile?.abn,
    invoiceNumber: invoiceNumber,
    invoiceDate: DateTime.fromISO(invoiceDate).toLocaleString(
      DateTime.DATE_SHORT
    ),
    lineItems: invoiceLineItems.slice(0, invoiceLineItems.length - 1),
    subtotal: `${invoiceLineItems
      .reduce((previous, current) => {
        return previous + current.quantity * current.unit_price;
      }, 0)
      .toFixed(2)}`,
    totalGST: `$${invoiceLineItems
      .reduce((previous, current) => {
        return (
          previous +
          current.quantity *
            current.unit_price *
            (parseFloat(current.tax_rate ?? 0) === 0
              ? 0
              : 1 / parseFloat(current.tax_rate))
        );
      }, 0)
      .toFixed(2)}`,
    totalDue: `$${invoiceLineItems
      .reduce((previous, current) => {
        return (
          previous +
          current.quantity *
            current.unit_price *
            (parseFloat(current.tax_rate ?? 0) === 0
              ? 1
              : (parseFloat(current.tax_rate) + 1) / 10)
        );
      }, 0)
      .toFixed(2)}`,
    notes: invoiceNotes,
    accountName: profile?.business_name,
    accountBSB: bankAccount?.bsb ? bankAccount?.bsb : externalBSB,
    accountNumber: bankAccount?.account_number
      ? bankAccount?.account_number
      : externalAccountNumber,
  };

  const validatedTestSend = async () => {
    if (!profile.phone || !profile.email) {
      alert(
        "Before you can send yourself a test, add your phone and email in Settings"
      );
      return;
    }

    setTestLoading(true);
    try {
      const result = await sendTest({
        invoiceUUID: params.uuid === "add" ? undefined : params.uuid,
        counterpartyUUID: customerContactUUID,
        customerContactUUID,
        invoiceDate: DateTime.fromISO(invoiceDate).toISODate(),
        invoiceNumber,
        lineItems: invoiceLineItems.slice(0, invoiceLineItems.length - 1),
        invoiceNotes,
        externalBSB,
        externalAccountNumber,
      });

      if (result.new_invoice_uuid) {
        history.push(`/dashboard/live-invoice/${result.new_invoice_uuid}`);
      }
    } catch (e) {
      alert(
        "There was an error sending test. Please check the invoice details and try again"
      );
    }
    setTestLoading(false);
  };

  const validatedSaveDraft = async () => {
    setSaveDraftLoading(true);
    try {
      await saveDraft({
        invoiceUUID: params.uuid === "add" ? undefined : params.uuid,
        counterpartyUUID: customerContactUUID,
        customerContactUUID,
        invoiceDate: DateTime.fromISO(invoiceDate).toISODate(),
        invoiceNumber,
        lineItems: invoiceLineItems.slice(0, invoiceLineItems.length - 1),
        invoiceNotes,
        externalBSB,
        externalAccountNumber,
      });
    } catch (e) {
      console.log(e);
      alert(
        "There was an error saving the draft. Please check the invoice details and try again"
      );
    }
    setSaveDraftLoading(false);

    history.push("/dashboard/invoices");
  };

  const validatedSendNow = async () => {
    if (!selectedCustomer[0]) {
      alert("Please select a customer to send the invoice to");
      return;
    }
    setSendLoading(true);

    try {
      await sendNow({
        invoiceUUID: params.uuid === "add" ? undefined : params.uuid,
        customerContactUUID,
        invoiceDate: DateTime.fromISO(invoiceDate).toISODate(),
        invoiceNumber,
        lineItems: invoiceLineItems.slice(0, invoiceLineItems.length - 1),
        invoiceNotes,
        externalBSB,
        externalAccountNumber,
      });
    } catch (e) {
      alert(
        "There was an error sending the invoice. Please check the invoice details and try again"
      );
    }
    setSendLoading(false);

    history.push("/dashboard/invoices");
  };

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={previewPDFModalIsOpen}
        toggle={() => setPreviewModalIsOpen()}
        size="xl"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            PDF Preview
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setPreviewPDFModalIsOpen(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body text-center">
          <PDFViewer height="640px" width="450px">
            <InvoicePreview data={data} />
          </PDFViewer>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            data-dismiss="modal"
            type="button"
            onClick={() => setPreviewPDFModalIsOpen(false)}
          >
            Close
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={previewEmailModalIsOpen}
        toggle={() => setPreviewEmailModalIsOpen()}
        size="md"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Email Preview
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setPreviewEmailModalIsOpen(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <h4>{profile?.first_name} has sent you a new Invoice</h4>
          <br />
          <h5 className="text-center">You have a new invoice ready</h5>
          <p className="text-center">
            {profile?.first_name} has prepared an invoice for you, please click
            the button to view and pay now, or copy the URL if you can't see the
            link
          </p>
          <p className="text-center">
            <a
              href={`${window.origin}/customer/invoice/${params.uuid}`}
            >{`${window.origin}/customer/invoice/${params.uuid}`}</a>
          </p>
          <p class="text-center">
            <Button
              color="success"
              size="md"
              active
              aria-pressed="true"
              href={`${window.origin}/customer/invoice/${params.uuid}`}
            >
              View Invoice
            </Button>
          </p>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            data-dismiss="modal"
            type="button"
            onClick={() => setPreviewEmailModalIsOpen(false)}
          >
            Close
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={previewSMSModalIsOpen}
        toggle={() => setPreviewSMSModalIsOpen()}
        size="md"
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            SMS Preview
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setPreviewSMSModalIsOpen(false)}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <p>
            Hi {data.firstName ? data.firstName : "{Customer}"},{" "}
            {profile?.first_name ? profile.first_name : "{Business}"} has
            prepared a new invoice for you. To view it online, click here:{" "}
            <a
              href={`${window.origin}/customer/invoice/${
                params.uuid === "add" ? emptyLiveInvoiceUUID : params.uuid
              }`}
              style={{ lineBreak: "loose" }}
            >{`${window.origin}/customer/invoice/${
              params.uuid === "add" ? emptyLiveInvoiceUUID : params.uuid
            }`}</a>{" "}
            Thanks, {data.businessName ? data.businessName : "{Business}"}.
            Please note: you cannot reply to this message
          </p>
        </div>
        <div className="modal-footer">
          <Button
            color="primary"
            data-dismiss="modal"
            type="button"
            onClick={() => setPreviewSMSModalIsOpen(false)}
          >
            Close
          </Button>
        </div>
      </Modal>
      <Container className="mt--5" fluid>
        <Row>
          <Col className="order-xl-1" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col sm="3">
                    <h3 className="mb-0">Draft Invoice</h3>
                  </Col>
                  <Col className="text-right d-none d-sm-block" sm="9">
                    <Button
                      color="primary"
                      onClick={() => setPreviewPDFModalIsOpen(true)}
                      size="sm"
                    >
                      Preview PDF
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => setPreviewEmailModalIsOpen(true)}
                      size="sm"
                    >
                      Preview Email
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => setPreviewSMSModalIsOpen(true)}
                      size="sm"
                    >
                      Preview SMS
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className="align-items-center d-sm-none d-block">
                  <Col className="text-center mb-4" xs="12">
                    <Button
                      color="primary"
                      onClick={() => setPreviewPDFModalIsOpen(true)}
                      size="sm"
                    >
                      Preview PDF
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => setPreviewEmailModalIsOpen(true)}
                      size="sm"
                    >
                      Preview Email
                    </Button>
                    <Button
                      color="primary"
                      onClick={() => setPreviewSMSModalIsOpen(true)}
                      size="sm"
                    >
                      Preview SMS
                    </Button>
                  </Col>
                </Row>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Invoice Information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Customer
                          </label>
                          <AsyncSelect
                            isMulti
                            loadOptions={async (inputValue, callback) =>
                              await loadAsyncSelectMerchantsCustomerList(
                                inputValue,
                                callback
                              )
                            }
                            defaultOptions={customerContacts}
                            onFocus={() => fetchCustomerContacts()}
                            onInputChange={(newValue) => newValue}
                            onChange={(selectedCustomer) =>
                              setSelectedCustomer(selectedCustomer)
                            }
                            isOptionDisabled={() => selectedCustomer.length > 0}
                            noOptionsMessage={() =>
                              "Start typing to search customers..."
                            }
                            value={selectedCustomer}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Invoice Number
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-invoice-number"
                            placeholder="INV-1038"
                            value={invoiceNumber}
                            onChange={(e) => setInvoiceNumber(e.target.value)}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Invoice Date
                          </label>
                          <DatePicker
                            value={invoiceDate}
                            onChange={(value) => setInvoiceDate(value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">Line Items</h6>
                  <div className="pl-lg-4">
                    {invoiceLineItems &&
                      invoiceLineItems.map((lineItem, index) => {
                        return (
                          <Row key={index}>
                            <Col md="5">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-address"
                                >
                                  Description
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  placeholder="Describe your product or service"
                                  value={lineItem.description}
                                  type="text"
                                  onChange={(e) =>
                                    lineItemDescriptionChange(
                                      invoiceLineItems,
                                      setInvoiceLineItems,
                                      index,
                                      e.target.value
                                    )
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="2">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-city"
                                >
                                  Quantity
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  value={lineItem.quantity}
                                  type="text"
                                  onChange={(e) =>
                                    lineItemQuantityChange(
                                      invoiceLineItems,
                                      setInvoiceLineItems,
                                      index,
                                      e.target.value
                                    )
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="2">
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-city"
                                >
                                  Unit Price
                                </label>
                                <Input
                                  className="form-control-alternative"
                                  value={lineItem.unit_price}
                                  type="text"
                                  onChange={(e) =>
                                    lineItemUnitPriceChange(
                                      invoiceLineItems,
                                      setInvoiceLineItems,
                                      index,
                                      e.target.value
                                    )
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col lg="1">
                              <label
                                className="form-control-label"
                                htmlFor="input-city"
                              >
                                GST
                              </label>
                              <div className="custom-control custom-checkbox mb-3">
                                <input
                                  className="custom-control-input"
                                  id={`checkbox-${index}`}
                                  type="checkbox"
                                  onChange={(e) =>
                                    lineItemGSTChange(
                                      invoiceLineItems,
                                      setInvoiceLineItems,
                                      index
                                    )
                                  }
                                  checked={
                                    parseFloat(lineItem.tax_rate) === 10
                                      ? true
                                      : undefined
                                  }
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor={`checkbox-${index}`}
                                >
                                  10%
                                </label>
                              </div>
                            </Col>
                            <Col lg="2" className="text-center">
                              <label>
                                <b>Subtotal</b>
                              </label>
                              <p
                                style={{ marginTop: "8px" }}
                                className="text-right"
                              >
                                <b>
                                  $
                                  {(
                                    invoiceLineItems[index].quantity *
                                    invoiceLineItems[index].unit_price
                                  ).toFixed(2)}
                                </b>
                              </p>
                            </Col>
                          </Row>
                        );
                      })}
                  </div>
                  <hr className="my-4" />
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="7"></Col>
                      <Col lg="3" className="text-right">
                        <h6>Subtotal</h6>
                      </Col>
                      <Col lg="2" className="text-right">
                        <h6>
                          $
                          {invoiceLineItems
                            .reduce((previous, current) => {
                              return (
                                previous + current.quantity * current.unit_price
                              );
                            }, 0)
                            .toFixed(2)}
                        </h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="7"></Col>
                      <Col lg="3" className="text-right">
                        <h6>GST</h6>
                      </Col>
                      <Col lg="2" className="text-right">
                        <h6>
                          $
                          {invoiceLineItems
                            .reduce((previous, current) => {
                              return (
                                previous +
                                current.quantity *
                                  current.unit_price *
                                  (parseFloat(current.tax_rate ?? 0) === 0
                                    ? 0
                                    : 1 / parseFloat(current.tax_rate))
                              );
                            }, 0)
                            .toFixed(2)}
                        </h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="7"></Col>
                      <Col lg="3" className="text-right">
                        <h6>Total</h6>
                      </Col>
                      <Col lg="2" className="text-right">
                        <h5>
                          $
                          {invoiceLineItems
                            .reduce((previous, current) => {
                              return (
                                previous +
                                current.quantity *
                                  current.unit_price *
                                  (parseFloat(current.tax_rate ?? 0) === 0
                                    ? 1
                                    : (parseFloat(current.tax_rate) + 1) / 10)
                              );
                            }, 0)
                            .toFixed(2)}
                        </h5>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">Notes</h6>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <label>Invoice notes</label>
                      <Input
                        className="form-control-alternative"
                        rows="4"
                        type="textarea"
                        value={invoiceNotes}
                        onChange={(e) => setInvoiceNotes(e.target.value)}
                      />
                    </FormGroup>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Payment Instructions
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col xs="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-bsb"
                          >
                            BSB
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-bsb"
                            value={externalBSB}
                            onChange={(e) => setExternalBSB(e.target.value)}
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col xs="6">
                        <label
                          className="form-control-label"
                          htmlFor="input-account-number"
                        >
                          Account Number
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-account-number"
                          value={externalAccountNumber}
                          onChange={(e) =>
                            setExternalAccountNumber(e.target.value)
                          }
                          type="text"
                        />
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Delivery Options
                  </h6>
                  <div className="pl-lg-4">
                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        className="custom-control-input"
                        id="email-notification"
                        type="checkbox"
                        onChange={() => {}}
                        checked={true}
                        disabled={true}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="email-notification"
                      >
                        Send by Email
                      </label>
                    </div>
                  </div>
                  <div className="pl-lg-4">
                    <div className="custom-control custom-checkbox mb-3">
                      <input
                        className="custom-control-input"
                        id="email-notification"
                        type="checkbox"
                        onChange={() => {}}
                        checked={true}
                        disabled={true}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="email-notification"
                      >
                        Send by SMS
                      </label>
                    </div>
                  </div>
                  <Row>
                    <Col className="text-center d-sm-none" xs="12">
                      <Button
                        color="primary"
                        onClick={validatedTestSend}
                        size="md"
                        disabled={!invoiceLoaded || testLoading}
                      >
                        {testLoading ? (
                          <Spinner
                            animation="border"
                            variant="light"
                            size="sm"
                          />
                        ) : null}{" "}
                        Send me a Test
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
              <CardFooter>
                <Row className="align-items-center">
                  <Col className="text-right d-sm-none" xs="6">
                    <Button
                      color="info"
                      onClick={validatedSaveDraft}
                      size="md"
                      disabled={!invoiceLoaded || saveDraftLoading}
                    >
                      {saveDraftLoading ? (
                        <Spinner animation="border" variant="light" size="sm" />
                      ) : null}{" "}
                      Save as Draft
                    </Button>
                  </Col>
                  <Col className="text-right d-sm-none" xs="6">
                    <Button
                      color="success"
                      onClick={validatedSendNow}
                      size="md"
                      disabled={!invoiceLoaded || sendLoading}
                    >
                      {sendLoading ? (
                        <Spinner animation="border" variant="light" size="sm" />
                      ) : null}{" "}
                      Send Now
                    </Button>
                  </Col>
                  <Col className="text-left d-none d-sm-block" sm="8">
                    <Button
                      color="primary"
                      onClick={validatedTestSend}
                      size="md"
                      disabled={!invoiceLoaded || testLoading}
                    >
                      {testLoading ? (
                        <Spinner animation="border" variant="light" size="sm" />
                      ) : null}{" "}
                      Send me a Test
                    </Button>
                  </Col>
                  <Col className="text-right d-none d-sm-block" sm="4">
                    <Button
                      color="info"
                      onClick={validatedSaveDraft}
                      size="md"
                      disabled={!invoiceLoaded || saveDraftLoading}
                    >
                      {saveDraftLoading ? (
                        <Spinner animation="border" variant="light" size="sm" />
                      ) : null}{" "}
                      Save as Draft
                    </Button>
                    <Button
                      color="success"
                      onClick={validatedSendNow}
                      size="md"
                      disabled={!invoiceLoaded || sendLoading}
                    >
                      {sendLoading ? (
                        <Spinner animation="border" variant="light" size="sm" />
                      ) : null}{" "}
                      Send Now
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LiveInvoiceDetail;
