import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
} from "reactstrap";

import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

import { getAuthorization, getSessionEmail } from "../../webauthn/session";
import { passkeyAssertion } from "../../webauthn/securityStepUp";

const AuthorityList = () => {
  const history = useHistory();

  const [customer, setCustomer] = useState();
  const [authorities, setAuthorities] = useState([]);
  const [loading, setLoading] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    (async () => {
      setLoading(true);
      const authorization = await getAuthorization();

      const authoritiesResponse = await fetch(
        `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/agent-authority`,
        {
          headers: {
            Authorization: authorization,
            "Content-Type": "application/json",
          },
          method: "GET",
        }
      );
      const authoritiesJSON = await authoritiesResponse.json();
      setCustomer(authoritiesJSON.customer);
      setAuthorities(authoritiesJSON.authorities);
      setLoading(false);
    })();
  }, [setCustomer, setAuthorities, setLoading]);

  const selectAuthority = async (legalEntityUUID) => {
    setLoading(true);

    localStorage.setItem("currentLegalEntityUUID", legalEntityUUID);

    if (customer?.legal_entity_uuid === legalEntityUUID) {
      const principalLegalEntity = {
        legal_entity_uuid: legalEntityUUID,
        legal_entity_name: customer?.legal_entity_name,
        customer_uuid: customer?.customer_uuid,
      };
      localStorage.setItem(
        "principalLegalEntity",
        JSON.stringify(principalLegalEntity)
      );

      setLoading(false);
      history.push("/dashboard");
    } else {
      const principalLegalEntity = {
        legal_entity_uuid: legalEntityUUID,
        legal_entity_name: "",
        agent_legal_entity: {
          legal_entity_uuid: customer?.legal_entity_uuid,
          legal_entity_name: customer?.legal_entity_name,
          customer_uuid: customer?.customer_uuid,
        },
      };
      localStorage.setItem(
        "principalLegalEntity",
        JSON.stringify(principalLegalEntity)
      );

      try {
        await passkeyAssertion({ email: getSessionEmail() });
        setLoading(false);
        history.push("/dashboard");
      } catch (e) {
        console.log(e);
      }

      setLoading(false);
    }
  };

  return (
    <>
      <DemoNavbar />
      <main>
        <section className="section section-shaped section-lg">
          <div className="shape shape-style-1 bg-gradient-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="pt-lg-7">
            <Row className="justify-content-center">
              <Col lg="8">
                <Card className="bg-secondary shadow border-0">
                  <CardHeader className="bg-white">
                    <div className="text-muted text-center mb-3">
                      <small>Select from your Authorities</small>
                    </div>
                    <div className="text-center">
                      <i className="fas fa-lock fa-3x" />
                    </div>
                  </CardHeader>
                  <CardBody className="px-lg-5 pb-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>
                        You can represent yourself by passkey or password:
                      </small>
                    </div>
                    {errorMessage === "" ? null : (
                      <p className="text-center text-danger">{errorMessage}</p>
                    )}
                    <Form role="form">
                      <FormGroup>
                        <ListGroup>
                          <ListGroupItem action tag="div">
                            <Row className="align-items-center">
                              <Col xs={9}>
                                {customer?.legal_entity_name
                                  ? customer?.legal_entity_name
                                  : getSessionEmail()}
                                <br />
                                {/*<span style={{ fontWeight: '100' }}>(ABN 00 000 000 000)</span>*/}
                              </Col>
                              <Col xs={3}>
                                <Button
                                  color="primary"
                                  onClick={() =>
                                    selectAuthority(customer?.legal_entity_uuid)
                                  }
                                  disabled={loading}
                                >
                                  Select
                                </Button>
                              </Col>
                            </Row>
                          </ListGroupItem>
                        </ListGroup>
                      </FormGroup>
                    </Form>

                    <div className="text-center text-muted mb-4">
                      <small>
                        You <strong>must</strong> use a passkey to represent the
                        following entities:
                      </small>
                    </div>
                    {errorMessage === "" ? null : (
                      <p className="text-center text-danger">{errorMessage}</p>
                    )}
                    <Form role="form">
                      <FormGroup>
                        <ListGroup>
                          {authorities.map((authority, index) => {
                            return (
                              <ListGroupItem key={index} action tag="div">
                                <Row className="align-items-center">
                                  <Col xs={9}>
                                    {authority.legal_entity_name}
                                    <br />
                                    {/*<span style={{ fontWeight: '100' }}>(ABN 00 000 000 000)</span>*/}
                                  </Col>
                                  <Col xs={3}>
                                    <Button
                                      color="primary"
                                      onClick={() =>
                                        selectAuthority(
                                          authority.legal_entity_uuid
                                        )
                                      }
                                      disabled={loading}
                                    >
                                      Select
                                    </Button>
                                  </Col>
                                </Row>
                              </ListGroupItem>
                            );
                          })}
                        </ListGroup>
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <SimpleFooter />
    </>
  );
};

export default AuthorityList;
