import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
    Container,
    Form,
    Card,
    CardTitle,
    CardBody,
    Button,
    Input,
    Spinner,
    FormGroup,
    CardFooter,
} from 'reactstrap';

import {
    FaRegCheckCircle,
    FaRegTimesCircle,
    FaArrowRight,
} from 'react-icons/fa';

// core components
import FlowNavbar from 'components/Navbars/FlowNavbar';
import SimpleFooter from 'components/Footers/SimpleFooter.js';


const CompanyNameIconPositive = styled(FaRegCheckCircle)`
  font-size: 8rem;
  color: #28a745;
`;

const CompanyNameIconNegative = styled(FaRegTimesCircle)`
  font-size: 8rem;
  color: #dc3545;
`;

const CompanyNameIconLoading = styled(Spinner)`
  height: 8rem;
  width: 8rem;
  border: 1em solid currentColor;
  border-right-color: transparent;
`;

const NameAvailability = (props) => {
    const queryHashProposedName = decodeURIComponent(props.location.hash.substr(1));
    const history = useHistory();

    const submitForm = () => {
        history.push('/structure-selection');
    };

    const [initialNameSearch, setInitialNameSearch] = useState(true);

    const [secondaryProposedName, setSecondaryProposedName] = useState('');
    const [secondaryProposedNameLoading] = useState(false);

    const [businessNameLoading, setBusinessNameLoading] = useState(false);
    const [businessNameAvailable, setBusinessNameAvailable] = useState(false);

    const searchName = async (businessName) => {
        setBusinessNameLoading(true);

        // Record this as a conversion in Google Ads
        window.gtag('event', 'conversion', { 'send_to': 'AW-312837668/pXbCCKCz3JsDEKSMlpUB' }); // 'Search Name' conversion action

        const result = await fetch(`${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/name-search?name=${encodeURIComponent(businessName)}`);
        const resultJSON = await result.json();

        if (resultJSON.result.code !== 'Unavailable') {
            localStorage.setItem('companyName', businessName);
            localStorage.setItem('nameSearchResultId', resultJSON.nameSearchResultId);
            setBusinessNameAvailable(true);
        } else {
            setBusinessNameAvailable(false);
        }

        setBusinessNameLoading(false);
    }

    const searchAgain = async () => {
        history.push(`/name-availability#${secondaryProposedName}`);
    }

    useEffect(() => {
        if (!queryHashProposedName) {
            history.push('/');
        } else if (initialNameSearch) {
            (async () => {
                await searchName(queryHashProposedName);
                setInitialNameSearch(false);
            })();

        }

    }, [initialNameSearch, setInitialNameSearch, queryHashProposedName, history]);

    return (
        <>
            <FlowNavbar />
            <Container className="pt-3">
                <Card className="text-center shadow border-1 pt-3 mb-5">
                    <CardTitle className="mb-3" tag="h4">Australian Company Name Search Result</CardTitle>
                    <CardBody>
                        <h1 style={{ textTransform: 'uppercase' }}>{queryHashProposedName}</h1>
                        <p>
                            {businessNameLoading
                                ? <CompanyNameIconLoading animation="border" variant="secondary" size="lg" />
                                : (
                                    businessNameAvailable
                                        ? <CompanyNameIconPositive />
                                        : <CompanyNameIconNegative />
                                )
                            }
                        </p>
                        <h1>
                            {businessNameLoading
                                ? 'Company name status loading'
                                : (
                                    businessNameAvailable
                                        ? 'This name is AVAILABLE'
                                        : 'This name is UNAVAILABLE'
                                )
                            }
                        </h1>
                        <p>
                            {businessNameLoading
                                ? "We're checking on your name, please wait while we query the companies database"
                                : (
                                    businessNameAvailable
                                        ? 'You can register a company with this name by clicking "Proceed to registration" below!'
                                        : 'You must select a different name by searching again below before you can proceed'
                                )
                            }
                        </p>
                        <hr />
                        <h3 className="text-left">Not the name you want?</h3>
                        <p className="text-left">You can search for a new name again</p>
                        <Form onSubmit={e => searchAgain()} inline className="p-3">
                            <FormGroup className="mr-4">
                                <Input
                                    type="text"
                                    placeholder="Check another name"
                                    value={secondaryProposedName}
                                    onChange={(e) => setSecondaryProposedName(e.target.value)}
                                />
                            </FormGroup>
                            <FormGroup>

                                <Button
                                    type="submit"
                                    color="info"
                                >
                                    {secondaryProposedNameLoading
                                        ? <span><Spinner animation="border" variant="light" size="sm" /> Searching</span>
                                        : <span>Search again</span>
                                    }
                                </Button>
                            </FormGroup>
                        </Form>
                    </CardBody>
                    <CardFooter className="text-right">
                        <Button
                            color={'success'}
                            disabled={!businessNameAvailable}
                            onClick={_ => submitForm()}
                        >
                            Proceed to registration <FaArrowRight />
                        </Button>

                    </CardFooter>
                </Card>
            </Container>
            <SimpleFooter />
        </>
    );
};

export default NameAvailability;