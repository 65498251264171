import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardImg,
  Form,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
} from 'reactstrap';
import DemoNavbar from 'components/Navbars/DemoNavbar';
import Footer from 'components/Footers/SimpleFooter';

import BuilderImage from '../../assets/img/theme/builder-1.png';
import ApplePayImage from '../../assets/img/theme/apple-pay-generic-content-image-phone1.png';


const Landing = () => {
  const history = useHistory();
  const [nameQuery, setNameQuery] = useState('');

  const queryNameAvailability = () => {
    if (!nameQuery) {
      alert('Please search a business name to get started');
    } else {
      history.push(`/name-availability#${nameQuery}`);
    }
  }

  const selectMicroBusiness = () => {
    localStorage.removeItem('abnApplicationId');
    localStorage.removeItem('businessNameApplicationId');
    localStorage.setItem('planId', 1);
    
    // For now, redirect to Stripe subscription page
    window.location.href = process.env.REACT_APP_STRIPE_PAYMENT_LINK_MICROBUSINESS;
  }

  const selectScaleUp = () => {
    localStorage.removeItem('abnApplicationId');
    localStorage.removeItem('businessNameApplicationId');
    localStorage.setItem('planId', 2);

    // For now, redirect to Stripe subscription page
    window.location.href = process.env.REACT_APP_STRIPE_PAYMENT_LINK_SCALEUP;
  }

  const selectLiftOff = () => {
    localStorage.removeItem('abnApplicationId');
    localStorage.removeItem('businessNameApplicationId');
    localStorage.setItem('planId', 3);

    // For now, redirect to Stripe subscription page
    window.location.href = process.env.REACT_APP_STRIPE_PAYMENT_LINK_LIFTOFF;
  }

  return (
    <>
      <DemoNavbar />
      <main>

        <div className="position-relative">
          {/* Hero for FREE version */}
          <section className="section section-hero section-shaped">
            {/* Background circles */}
            <div className="shape shape-style-1 shape-default">
              <span className="span-150" />
              <span className="span-50" />
              <span className="span-50" />
              <span className="span-75" />
              <span className="span-100" />
              <span className="span-75" />
              <span className="span-50" />
              <span className="span-100" />
              <span className="span-50" />
              <span className="span-100" />
            </div>
            <Container className="shape-container d-flex align-items-center py-lg">
              <div className="col px-0">
                <Row>
                  <Col xs="12">
                    <h1 className="text-center text-white">We've got your new business accountedfor.</h1>
                  </Col>
                </Row>
                <Row className="align-items-center justify-content-center">
                  <Col className="text-center" lg="6">
                    <p className="lead text-white">
                      Start your new business today<br />and we'll make sure it's all accountedfor.
                    </p>

                    <Form onSubmit={queryNameAvailability}>
                    <FormGroup>
                      <Input
                        type="text"
                        placeholder="Find a business name to get started..."
                        value={nameQuery}
                        onChange={e => setNameQuery(e.target.value)}
                      />
                    </FormGroup>


                    <div className="btn-wrapper mt-5">
                      <Button
                        className="btn-success btn-icon mb-3 mb-sm-0"
                        onClick={queryNameAvailability}
                        size="lg"
                      >
                        <span className="btn-inner--icon mr-1">
                          <i className="fa fa-search" />
                        </span>
                        <span className="btn-inner--text">Check Availability</span>
                      </Button>

                    </div>
                    </Form>
                    <div className="mt-5">
                      <small className="text-white font-weight-bold mb-0 mr-2">
                        Powered by <a href="https://www.thorntonassociates.com.au" className="text-white" style={{ textDecoration: 'underline' }}>Thornton &amp; Associates</a> paperless accounting
                      </small>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
        </div>
        <section className="section section-lg">
          <Container>
            <Row className="row-grid align-items-center">
              <Col className="order-md-2" md="6">
                <img
                  alt="..."
                  className="img-fluid"
                  src={BuilderImage}
                />
              </Col>
              <Col className="order-md-1" md="6">
                <div className="pr-md-5">
                  <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                    <i className="ni ni-settings-gear-65" />
                  </div>
                  <h3>It's never been a better time to build a business</h3>
                  <p>
                    accountedfor guides you through the fully online process of
                    setting up a new business, provides you with a transaction
                    account and then automatically uses your transaction history to
                    file your taxes at the end of the year - stress free.
                  </p>
                  <ul className="list-unstyled mt-5">
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge
                            className="badge-circle mr-3"
                            color="success"
                          >
                            <i className="ni ni-settings-gear-65" />
                          </Badge>
                        </div>
                        <div>
                          <h6 className="mb-0">
                            Easy to use transaction account
                          </h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge
                            className="badge-circle mr-3"
                            color="success"
                          >
                            <i className="ni ni-html5" />
                          </Badge>
                        </div>
                        <div>
                          <h6 className="mb-0">Business tools to help you succeed</h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge
                            className="badge-circle mr-3"
                            color="success"
                          >
                            <i className="ni ni-satisfied" />
                          </Badge>
                        </div>
                        <div>
                          <h6 className="mb-0">
                            Ongoing accounting support and monthly reporting
                          </h6>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section bg-secondary">
          <Container>
            <Row className="row-grid align-items-center">
              <Col md="6">
                <Card className="bg-default shadow border-0">
                  <CardImg
                    alt="..."
                    src={ApplePayImage}
                    top
                  />
                  <blockquote className="card-blockquote">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="svg-bg"
                      preserveAspectRatio="none"
                      viewBox="0 0 583 95"
                    >
                      <polygon
                        className="fill-default"
                        points="0,52 583,95 0,95"
                      />
                      <polygon
                        className="fill-default"
                        opacity=".2"
                        points="0,42 583,95 683,0 0,95"
                      />
                    </svg>
                    <h4 className="display-3 font-weight-bold text-white">
                      Start it right with a digital Business Card
                    </h4>
                    <p className="lead text-italic text-white">
                      Use a payment card in your favourite digital wallet to
                      keep all your business expenses accounted for.
                    </p>
                  </blockquote>
                </Card>
              </Col>
              <Col md="6">
                <div className="pl-md-5">
                  <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-5">
                    <i className="ni ni-settings" />
                  </div>
                  <h3>A full scale business account in the palm of your hands</h3>
                  <p className="lead">

                  </p>
                  <p>
                    With access to cutting edge services to receive income and make
                    payments, it's all too easy to keep everything in your business
                    fully accounted for.
                  </p>
                  <p>
                    accountedfor puts your business tax on autopilot year-round so
                    you can forget that 30 June even exists.
                  </p>
                  <a
                    className="font-weight-bold text-warning mt-5"
                    href="#pricing"
                  >
                    Start your business now
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section section-lg bg-gradient-default">
          <Container className="pt-lg pb-100">
            <Row className="text-center justify-content-center">
              <Col lg="10">
                <h2 className="display-3 text-white">What is accountedfor?</h2>
                <p className="lead text-white">
                  accountedfor is a hybrid tax advisory and business registration app provided to help businesses get started trading in Australia and meet their ongoing tax compliance obligations.
                </p>
              </Col>
            </Row>
            <Row className="row-grid mt-5">
              <Col lg="4">
                <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                  <i className="ni ni-settings text-primary" />
                </div>
                <h5 className="text-white mt-3">Start quickly</h5>
                <p className="text-white mt-3">
                  Go from zero to in-business totally online and in only a few minutes
                  with our easy ABN application and digital wallet expense cards.
                </p>
              </Col>
              <Col lg="4">
                <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                  <i className="ni ni-ruler-pencil text-primary" />
                </div>
                <h5 className="text-white mt-3">Grow your business</h5>
                <p className="text-white mt-3">
                  Take advantage of the business tools we provide to automate common tasks
                  in your business.
                </p>
              </Col>
              <Col lg="4">
                <div className="icon icon-lg icon-shape bg-gradient-white shadow rounded-circle text-primary">
                  <i className="ni ni-atom text-primary" />
                </div>
                <h5 className="text-white mt-3">Forget about taxes</h5>
                <p className="text-white mt-3">
                  Backed by an Australian government registered tax agent, our accountants are always working
                  to help you minimise your tax payable.
                </p>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section section-lg" id="pricing">
          <Container>
            <Row className="text-center justify-content-center">
              <Col lg="10">
                <h2 className="display-3">Pricing</h2>
                <p className="lead mb-md">
                  Choose from three plans to suit your business size and accounting needs.
                </p>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col lg="12">
                <Row className="row-grid">
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <h3 className="display-4">$29<span style={{ fontSize: '16px' }}>per month</span></h3>

                        <h6 className="text-primary text-uppercase">
                          Sole Trader
                        </h6>
                        <p className="description mt-3">
                          The Sole Trader plan includes a business account
                          and everything you need to get your first customers
                        </p>
                        <ul>
                          <li>Transaction account</li>
                          <li>Digital business card</li>
                          <li>Automatic bank reconciliation</li>
                          <li>Free tax return filing</li>
                        </ul>
                        <Button
                          className="mt-4"
                          color="primary"
                          onClick={e => selectMicroBusiness()}
                        >
                          Subscribe
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <h3 className="display-4">$49<span style={{ fontSize: '16px' }}>per month</span></h3>

                        <h6 className="text-success text-uppercase">
                          Sole Trader GST
                        </h6>
                        <p className="description mt-3">
                          The Sole Trader GST plan is for businesses that also
                          need to register for GST<br /><br />
                        </p>
                        <ul>
                          <li>Transaction account</li>
                          <li>Digital business card</li>
                          <li>Automatic bank reconciliation</li>
                          <li>Free tax return filing</li>
                          <li>Free GST &amp; BAS filing</li>
                        </ul>
                        <br />
                        <br />
                        <Button
                          className="mt-4"
                          color="success"
                          onClick={e => selectScaleUp()}
                        >
                          Subscribe
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <h3 className="display-4">$79<span style={{ fontSize: '16px' }}>per month</span></h3>
                        <h6 className="text-warning text-uppercase">
                          Company Accounting
                        </h6>
                        <p className="description mt-3">
                          With the Company Accounting plan, accountedfor helps you
                          transition from sole trader to company director
                        </p>
                        <ul>
                          <li>Transaction account</li>
                          <li>Digital business card</li>
                          <li>Automatic bank reconciliation</li>
                          <li>Free tax return filings</li>
                          <li>Free BAS filings</li>
                          <li>Automatic payroll, tax and super</li>
                          <li>24x7 Dedicated Tax Advice consultant</li>
                        </ul>
                        <Button
                          className="mt-4"
                          color="warning"
                          onClick={e => selectLiftOff()}
                        >
                          Subscribe
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section section-lg">
          <Container>
            <Row className="row-grid justify-content-center">
              <Col className="text-center" lg="8">
                <h2 className="display-3">
                  ...and Full Self-Driving Mode
                  <span className="text-muted">
                    (coming soon)
                  </span>
                </h2>
                <p className="lead">
                  accountedfor looks at your business inflows and outflows, and applies algorithmic learning to supercharge your success.
                </p>
                <h4 className="display-4 mb-5 mt-5">
                  What are you waiting for?
                </h4>
                <div className="btn-wrapper">
                  <Button
                    className="mb-3 mb-sm-0"
                    color="primary"
                    href="#pricing"
                  >
                    Get Started Now!
                  </Button>
                </div>

              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <Footer />
    </>
  );
}

export default Landing;