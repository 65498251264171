import { Auth, Hub } from "aws-amplify";

let signUpConfirmed = false;

const postConfirmation = async () => {
  // Create user record association with confirmed email
  const authorization = await getAuthorization();

  const result = await fetch(
    `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/confirm-signup`,
    {
      headers: {
        Authorization: authorization,
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        email: getSessionEmail(),
      }),
    }
  );
  await result.json();

  /*
   *  Note: The result here is unused (but required to be called)
   *  to guarantee that the fetch call is finished (for enough for
   *  the server to confirm the client sign up) before the non-async
   *  redirect is fired, which would otherwise terminate in-flight
   *  async operations i.e. the fetch request.
   *
   *  See https://stackoverflow.com/q/48487535 for more information
   */
  // Note this is moved in light of Authorities
  //window.location.href = '/dashboard';
};

const presentAuthorities = async () => {
  const authorization = await getAuthorization();

  const response = await fetch(
    `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/agent-authority`,
    {
      headers: {
        Authorization: authorization,
        "Content-Type": "application/json",
      },
      method: "GET",
    }
  );
  if (response.ok) {
    const authoritiesJSON = await response.json();

    if (authoritiesJSON.authorities.length === 0) {
      /*
       * If there are no authorities, we need to set the principal to self
       *  and then go straight to the dashboard
       */
      const principalLegalEntity = {
        legal_entity_uuid: authoritiesJSON.customer?.legal_entity_uuid,
        legal_entity_name: authoritiesJSON.customer?.legal_entity_name,
        customer_uuid: authoritiesJSON.customer?.customer_uuid,
      };
      localStorage.setItem(
        "principalLegalEntity",
        JSON.stringify(principalLegalEntity)
      );

      // Go straight to the dashboard as the Customer does not have any Authorities
      window.location.href = "/dashboard";
    } else {
      window.location.href = "/authorities";
    }
  }
};

Hub.listen("auth", async ({ payload }) => {
  const { event } = payload;

  if (event === "signIn") {
    if (signUpConfirmed) {
      await postConfirmation();
    }

    await presentAuthorities();
  } else if (event === "autoSignIn") {
    // Redirect to dashboard upon successful sign up & auto sign in
    if (signUpConfirmed) {
      await postConfirmation();
    }

    await presentAuthorities();
  } else if (event === "confirmSignUp") {
    // TODO: investigate if there's a better way to do this
    signUpConfirmed = true;
  } else if (event === "autoSignIn_failure") {
    // redirect to sign in page <- NOTE: https://github.com/aws-amplify/amplify-js/issues/10225
    window.location.href = "/sign-in";
  }
});

let email;

export const getAuthorization = async () => {
  let session;

  try {
    session = await Auth.currentSession();
  } catch (e) {
    /* If this is thrown, it's almost certainly the (unsolved) "No current user" bug
     * which appears to be an expired/invalid refresh token (access and refresh tokens are managed by AWS Cognito behind the scenes)
     *      - Here is a thread about this: https://github.com/aws-amplify/amplify-js/issues/2702
     *      - One solution appears to be to extend the session duration longer than acceptable ATO limits
     *      - The other is to migrate away from AWS Cognito in favour of self-managed sessions
     *      - Finally we could just adopt (enforce) Passkeys
     */

    // but for now, just require re-login

    console.log(e);
    window.location.href = "/sign-in";
  }

  const expired =
    Math.floor(Date.now() / 1000) > session.accessToken.payload.exp;

  if (!expired) {
    email = session.idToken.payload.email;
    return session.accessToken.jwtToken;
  } else {
    window.location.href = "/sign-in";
  }
};

export const getSessionEmail = () => {
  return email;
};
