import { getAuthorization } from "webauthn/session";

import { getPasskeyJWTAsync } from "../../../webauthn/securityStepUp";

export const getTransactions = async () => {
  const authorization = await getAuthorization();

  try {
    const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/banking/weel/transactions`,
      {
        headers: {
          Authorization: authorization,
        },
      }
    );

    if (response.ok) {
      const json = await response.json();
      return {
        hasWeelAccount: json.hasWeelAccount,
        transactions: json.transactions,
        pendingPayments: json.pending_payments,
      };
    } else {
      return {};
    }
  } catch (e) {
    alert(`An unknown error occurred. (Server said: ${e})`);
  }
};

export const getBusinessProfile = async () => {
  const authorization = await getAuthorization();

  try {
    const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/business-profile`,
      {
        headers: {
          Authorization: authorization,
        },
      }
    );

    if (response.ok) {
      const json = await response.json();
      return json;
    } else {
      return {};
    }
  } catch (e) {
    alert(`An unknown error occurred. (Server said: ${e})`);
  }
};

export const getBudgetList = async () => {
  const authorization = await getAuthorization();

  try {
    const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/banking/budget-list`,
      {
        headers: {
          Authorization: authorization,
        },
      }
    );

    if (response.ok) {
      const json = await response.json();
      return json.budget_list;
    } else {
      return [];
    }
  } catch (e) {
    alert(`An unknown error occurred. (Server said: ${e})`);
  }
};

export const makePayment = async (payment) => {
  const principalLegalEntity = JSON.parse(
    localStorage.getItem("principalLegalEntity")
  );

  if (!principalLegalEntity.legal_entity_uuid) {
    alert("You must use Passkey to make payments from this account");
    return;
  }

  const response = await fetch(
    `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/banking/pay`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
        "x-principal-legal-entity-uuid": principalLegalEntity.legal_entity_uuid,
      },
      method: "post",
      body: JSON.stringify(payment),
    }
  );

  if (response.ok) {
    const json = await response.json();

    if (json.status === "success") {
      alert("Successfully processed payment!");

      return json;
    } else {
      alert(`Error: ${json.message}`);

      return json;
    }
  } else {
    alert("Could not make payment");
  }
};

export const getScheduledPayments = async () => {
  const authorization = await getAuthorization();

  try {
    const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/banking/scheduled-payment`,
      {
        headers: {
          Authorization: authorization,
        },
      }
    );

    if (response.ok) {
      const json = await response.json();
      return json.scheduled_payments;
    } else {
      return [];
    }
  } catch (e) {
    alert(`An unknown error occurred. (Server said: ${e})`);
  }
};
