/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

class SimpleFooter extends React.Component {
  render() {
    return (
      <>
        <footer className=" footer">
          <Container>
            <Row className=" row-grid align-items-center mb-5">
              <Col lg="6">
                <h3 className=" text-primary font-weight-light mb-2">
                  Business tax on autopilot
                </h3>
                <h4 className=" mb-0 font-weight-light">
                  We've got your new business accountedfor.
                </h4>
              </Col>

            </Row>
            <hr />
            <Row className=" align-items-center justify-content-md-between">
              <Col md="12">
                <div className="text-muted text-left" style={{ fontSize: '12px' }}>
                  accountedfor is owned and operated by A4 Technology Pty Ltd (ABN 49 672 208 472).
                  The accountedfor service is a hybrid tax advisory and business registration app provided to help businesses get started trading in Australia and meet their ongoing tax compliance obligations.
                  accountedfor provides a platform in partnership with Thornton &amp; Associates Pty Ltd - Paperless Digital Accountants. Thornton &amp; Associates Pty Ltd (ABN 28 631 107 714) provides business advisory, tax agent and ASIC agent services on the accountedfor Software Platform.
                  Thornton &amp; Associates Pty Ltd is a registered Tax Agent (Registration Number 26010937) and a registered ASIC Agent (Number 44654). <a href="/terms">View Terms</a>
                  <br />
                </div>
              </Col>
            </Row>
            <Row className=" align-items-center justify-content-md-between">
              <Col md="6">
                <div className=" copyright">
                  © {new Date().getFullYear()}{" "}
                  <a
                    href="https://www.accountedfor.com.au"
                    target="_blank"
                  >
                    www.accountedfor.com.au
                  </a>
                  .
                </div>
              </Col>
              <Col md="6">
                <Nav className="text-right nav-footer justify-content-end" style={{ fontSize: '12px' }}>
                  Business or Tax Enquiries?<br />Call us on 1300 201 329
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default SimpleFooter;
