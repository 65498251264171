import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Container,
    Col,
    Card,
    CardTitle,
    CardBody,
    CardFooter,
    Button,
    Form,
    FormGroup,
    FormFeedback,
    Label,
    Input,
    Spinner,
} from 'reactstrap';
import { FaArrowRight } from 'react-icons/fa';

import Select, { components } from 'react-select';
import countryList from 'react-select-country-list';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';

import FlowNavbar from 'components/Navbars/FlowNavbar';
import SimpleFooter from 'components/Footers/SimpleFooter.js';
import ProgressBar from '../../../components/progressBar';


/*
 *  Required to disable Safari autofill
 */
const AutofillDisabledInput = ({ children, ...props }) => (
    <components.Input {...props} name="search" />
);

const ABNApplication = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [submissionAttempted, setSubmissionAttempted] = useState(false);
    const [error, setError] = useState(false);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const abnOnly = urlSearchParams.get('abnOnly') || true;
    localStorage.setItem('abnOnly', abnOnly);

    useEffect(() => {
        // XXX: this is really a bug not a fix...
        localStorage.removeItem('businessNameApplicationId');
    });

    // Personal details
    const [firstName, setFirstName] = useState(localStorage.getItem('firstName'));
    const [middleName, setMiddleName] = useState(localStorage.getItem('middleName'));
    const [lastName, setLastName] = useState(localStorage.getItem('lastName'));
    const [email, setEmail] = useState(localStorage.getItem('email'));
    const [phone, setPhone] = useState(localStorage.getItem('phone'));

    const [birthDateDay, setBirthDateDay] = useState('');
    const [birthDateMonth, setBirthDateMonth] = useState(0);
    const [birthDateYear, setBirthDateYear] = useState('');

    const [birthState, setBirthState] = useState('NSW');
    const [birthCity, setBirthCity] = useState('');
    const [birthCountry, setBirthCountry] = useState({ "value": "AU", "label": "Australia" });

    // Business location
    const [autocompletedAddress, setAutocompletedAddress] = useState(null);
    const [geocodedAddress, setGeocodedAddress] = useState(null);

    const [businessActivitiesMatchesHomeAddress, setBusinessActivitiesMatchesHomeAddress] = useState(true);
    const [serviceAddressMatchesHomeAddress, setServiceAddressMatchesHomeAddress] = useState(true);

    // Declarations and Terms
    const [declaresApplicant, setDeclaresApplicant] = useState(false);
    const [notDisqualified, setNotDisqualified] = useState(false);
    const [noBusinessNameCrimes, setNoBusinessNameCrimes] = useState(false);
    const [electronicLodgementCompliant, setElectronicLodgementCompliant] = useState(false);
    const [declaresCompleteAndAccurate, setDeclaresCompleteAndAccurate] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);

    const formInvalid = !businessActivitiesMatchesHomeAddress
        || !serviceAddressMatchesHomeAddress
        || !declaresApplicant
        || !notDisqualified
        || !noBusinessNameCrimes
        || !electronicLodgementCompliant
        || !declaresCompleteAndAccurate
        || !termsAccepted;

    const handleAutocompletedAddressChange = async (address) => {
        setAutocompletedAddress(address);
        if (address) {
            const result = await geocodeByPlaceId(address.value.place_id);
            setGeocodedAddress(result);
        }
    };

    const submitForm = async () => {
        setSubmissionAttempted(true);

        if (!formInvalid) {

            setLoading(true);

            // Business Name plan
            localStorage.setItem('planId', 5);
            const registrationPeriod = localStorage.getItem('registrationPeriod');

            //localStorage.setItem('registrationPeriod', registrationPeriod);

            if (registrationPeriod !== 0) {
                const options = {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        term: registrationPeriod,
                        first_name: firstName,
                        middle_name: middleName,
                        last_name: lastName,
                        email: email,
                        phone: phone,
                        birth_date: `${birthDateYear}-${parseInt(birthDateMonth) + 1}-${birthDateDay}`,
                        birth_country: birthCountry,
                        birth_state: birthState,
                        birth_city: birthCity,
                        home_address: {
                            autocomplete_result: autocompletedAddress,
                            geocoded_result: geocodedAddress,
                        },
                        name_search_result_id: localStorage.getItem('nameSearchResultId'),
                        abn: localStorage.getItem('abn'),
                        abn_search_id: localStorage.getItem('abnSearchId'),
                    }),
                };
                const result = await fetch(`${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/business-name/application`, options);
                const resultJSON = await result.json();

                if (resultJSON.status === 'ok') {
                    localStorage.setItem('businessNameApplicationId', resultJSON.application_id);
                    //history.push('/lodge/business-name-registration/review'); // XXX: fix this
                } else {
                    setError(true);
                }

            }

            //history.push('/lodge/business-name-application/review');

            // Test straight to payment screen
            history.push('/checkout');

            setLoading(false);
        }
    };

    return (
        <>
            <FlowNavbar />
            <Container>
                <br />
                <ProgressBar title="New Business Name Registration" step={2} stepCount={3} stepName="Owner Deatils" />
                <br />
                <Form onSubmit={(e) => { e.preventDefault(); submitForm() }}>
                    <Card className="text-left shadow border-1 mb-5">
                        <CardTitle className="pt-3 pl-3">
                            Business Name Registration Details
                        </CardTitle>
                        <CardBody className="p-4">
                            <h3>Personal Details</h3>
                            <br />
                            <br />
                            <FormGroup tag="fieldset" row>

                                <Label sm={4} className="text-left">
                                    Full name
                                </Label>
                                <Col xs={4} sm={2}>
                                    <Input
                                        type="text"
                                        placeholder="First name"
                                        value={firstName}
                                        onChange={(e) => { localStorage.setItem('firstName', e.target.value); setFirstName(e.target.value) }}
                                        valid={firstName !== ''}
                                        invalid={submissionAttempted && !firstName}
                                    />
                                </Col>
                                <Col xs={4} sm={2}>
                                    <Input
                                        type="text"
                                        placeholder="Middle name"
                                        value={middleName}
                                        onChange={(e) => { localStorage.setItem('middleName', e.target.value); setMiddleName(e.target.value) }}
                                        valid={middleName !== ''}
                                        invalid={submissionAttempted && !middleName}
                                    />
                                </Col>
                                <Col xs={4} sm={2}>
                                    <Input
                                        type="text"
                                        placeholder="Last name"
                                        value={lastName}
                                        onChange={(e) => { localStorage.setItem('lastName', e.target.value); setLastName(e.target.value) }}
                                        valid={lastName !== ''}
                                        invalid={submissionAttempted && !lastName}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup tag="fieldset" row>
                                <Label sm={4} className="text-left">
                                    Email
                                </Label>
                                <Col sm={6}>
                                    <Input
                                        type="text"
                                        placeholder="you@yourbusiness.com.au"
                                        value={email}
                                        onChange={(e) => { localStorage.setItem('email', e.target.value); setEmail(e.target.value) }}
                                        valid={email !== ''}
                                        invalid={submissionAttempted && !email}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup tag="fieldset" row>
                                <Label sm={4} className="text-left">
                                    Phone number
                                </Label>
                                <Col sm={6}>
                                    <Input
                                        type="text"
                                        placeholder="04xx xxx xxx"
                                        value={phone}
                                        valid={phone !== ''}
                                        onChange={(e) => { localStorage.setItem('phone', e.target.value); setPhone(e.target.value) }}
                                        invalid={submissionAttempted && !phone}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup tag="fieldset" row>
                                <Label sm={4} className="text-left">
                                    Date of birth
                                </Label>
                                <Col sm={2}>
                                    <Input
                                        type="text"
                                        placeholder="Day"
                                        value={birthDateDay}
                                        onChange={(e) => setBirthDateDay(e.target.value)}
                                        invalid={submissionAttempted && birthDateDay === ''}
                                    />

                                </Col>
                                <Col sm={2}>
                                    <Input
                                        type="select"
                                        onChange={(e) => setBirthDateMonth(e.target.value)}
                                        value={birthDateMonth}
                                    >
                                        <option value="0">January</option>
                                        <option value="1">February</option>
                                        <option value="2">March</option>
                                        <option value="3">April</option>
                                        <option value="4">May</option>
                                        <option value="5">June</option>
                                        <option value="6">July</option>
                                        <option value="7">August</option>
                                        <option value="8">September</option>
                                        <option value="9">October</option>
                                        <option value="10">November</option>
                                        <option value="11">December</option>

                                    </Input>
                                </Col>
                                <Col sm={2}>
                                    <Input
                                        type="text"
                                        placeholder="Year"
                                        value={birthDateYear}
                                        onChange={(e) => setBirthDateYear(e.target.value)}
                                        invalid={submissionAttempted && birthDateYear === ''}
                                    />
                                </Col>
                            </FormGroup>


                            <FormGroup tag="fieldset" row>
                                <Label sm={4} className="text-left">
                                    Country of Birth
                                </Label>
                                <Col sm={6}>
                                    <Select
                                        components={{ Input: AutofillDisabledInput }}
                                        options={countryList().getData()}
                                        value={birthCountry}
                                        onChange={value => setBirthCountry(value)}
                                    />
                                </Col>
                            </FormGroup>
                            {birthCountry.value === 'AU'
                                ? <>
                                    <FormGroup tag="fieldset" row>
                                        <Label sm={4} className="text-left">
                                            State of Birth
                                        </Label>
                                        <Col sm={6}>
                                            <Input
                                                type="select"
                                                onChange={(e) => setBirthState(e.target.value)}
                                                value={birthState}
                                            >
                                                <option value="NSW">New South Wales</option>
                                                <option value="ACT">Australian Capital Territory</option>
                                                <option value="NT">Northern Territory</option>
                                                <option value="QLD">Queensland</option>
                                                <option value="SA">South Australia</option>
                                                <option value="TAS">Tasmania</option>
                                                <option value="VIC">Victoria</option>
                                                <option value="WA">Western Australia</option>
                                            </Input>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup tag="fieldset" row>
                                        <Label sm={4} className="text-left">
                                            City/Town of Birth
                                        </Label>
                                        <Col sm={6}>
                                            <Input
                                                type="text"
                                                placeholder="City/Town of Birth"
                                                value={birthCity}
                                                valid={birthCity !== ''}
                                                onChange={(e) => setBirthCity(e.target.value)}
                                                invalid={submissionAttempted && birthCity === ''}
                                            />
                                        </Col>
                                    </FormGroup>
                                </>
                                : undefined}


                            <hr />
                            <h3>Business Location</h3>
                            <br />
                            <br />
                            <FormGroup tag="fieldset" row>
                                <Label sm={4} className="text-left">
                                    Home address
                                </Label>
                                <Col sm={6}>
                                    <GooglePlacesAutocomplete
                                        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                                        autocompletionRequest={{
                                            componentRestrictions: {
                                                country: ['au'],
                                            },
                                            fields: ['address_components'],
                                            types: ['address'],
                                        }}
                                        selectProps={{
                                            components: { Input: AutofillDisabledInput },
                                            isClearable: true,
                                            value: autocompletedAddress,
                                            onChange: handleAutocompletedAddressChange,
                                            placeholder: 'Start typing address',

                                        }}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup tag="fieldset" row>
                                <Label sm={4} className="text-left">
                                    Is your home address where you will you mainly conduct business?
                                </Label>
                                <Col sm={6}>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="radio"
                                                name="businessActivitiesMatchesHomeAddress"
                                                checked={businessActivitiesMatchesHomeAddress}
                                                onChange={() => setBusinessActivitiesMatchesHomeAddress(true)}
                                            />
                                            Yes
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="radio"
                                                name="businessActivitiesMatchesHomeAddress"
                                                checked={!businessActivitiesMatchesHomeAddress}
                                                onChange={() => setBusinessActivitiesMatchesHomeAddress(false)}
                                                invalid={!businessActivitiesMatchesHomeAddress}
                                            />
                                            No
                                            <FormFeedback>To select a different business address please contact support on Messenger</FormFeedback>
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </FormGroup>
                            <FormGroup tag="fieldset" row>
                                <Label sm={4} className="text-left">
                                    Is your home address your address for service of documents?
                                </Label>
                                <Col sm={6}>

                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="radio"
                                                name="serviceAddressMatchesHomeAddress"
                                                checked={serviceAddressMatchesHomeAddress}
                                                onChange={() => setServiceAddressMatchesHomeAddress(true)}
                                            />
                                            Yes
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="radio"
                                                name="serviceAddressMatchesHomeAddress"
                                                checked={!serviceAddressMatchesHomeAddress}
                                                onChange={() => setServiceAddressMatchesHomeAddress(false)}
                                                invalid={!serviceAddressMatchesHomeAddress}
                                            />
                                            No
                                            <FormFeedback>To select a different service address please contact support on Messenger</FormFeedback>
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </FormGroup>
                            <hr />
                            <h3>Declarations and Terms Acceptance</h3>
                            <br />
                            <br />
                            <FormGroup tag="fieldset" row>
                                <Label sm={4} className="text-left">
                                    Declaration
                                </Label>
                                <Col sm={6}>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                checked={declaresApplicant}
                                                onChange={() => setDeclaresApplicant(!declaresApplicant)}
                                                invalid={submissionAttempted && !declaresApplicant}
                                            />
                                            I am the applicant (the proposed business name holder)
                                            <FormFeedback>You must be the applicant to use this form</FormFeedback>
                                        </Label>
                                    </FormGroup>
                                    <br />
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                checked={notDisqualified}
                                                onChange={() => setNotDisqualified(!notDisqualified)}
                                                invalid={submissionAttempted && !notDisqualified}
                                            />
                                            I am not disqualified from managing corporations under section 205B(1) of the <i>Corporations Act 2001</i>
                                            <FormFeedback>You must not be disqualified from managing corporations to use this form</FormFeedback>
                                        </Label>
                                    </FormGroup>
                                    <br />
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                checked={noBusinessNameCrimes}
                                                onChange={() => setNoBusinessNameCrimes(!noBusinessNameCrimes)}
                                                invalid={submissionAttempted && !noBusinessNameCrimes}
                                            />
                                            Within the last 5 years I have not been:
                                            <ul>
                                                <li>convicted of, or</li>
                                                <li>released from prison after being convicted of, and serving a term of imprisonment for, and of the criminal offences referred to in subsections 32(1)(c) or 32(1)(d) of the <i>Business Names Registration Act 2011</i></li>
                                            </ul>
                                            <FormFeedback>You must not have been convicted of or served a term of imprisonment under any of the subsections in the Act to use this form</FormFeedback>
                                        </Label>
                                    </FormGroup>
                                    <br />
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                checked={electronicLodgementCompliant}
                                                onChange={() => setElectronicLodgementCompliant(!electronicLodgementCompliant)}
                                                invalid={submissionAttempted && !electronicLodgementCompliant}
                                            />
                                            This application is submitted under, and is compliant with, the terms and conditions of the Australian Securities and Investments Commission (ASIC) Electronic Lodgement Protocol.
                                            <FormFeedback>You must warrant that this application is submitted and compliant with the ASIC Electronic Lodgement Protocol</FormFeedback>
                                        </Label>
                                    </FormGroup>
                                    <br />
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                checked={declaresCompleteAndAccurate}
                                                onChange={() => setDeclaresCompleteAndAccurate(!declaresCompleteAndAccurate)}
                                                invalid={submissionAttempted && !declaresCompleteAndAccurate}
                                            />
                                            To the best of my knowledge, the information supplied in this transaction is complete and accurate (it is an offence to provide false or misleading information to ASIC)
                                            <FormFeedback>You must warrant that the information supplied is complete and accurate</FormFeedback>
                                        </Label>
                                    </FormGroup>
                                    <br />
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                checked={noBusinessNameCrimes}
                                                onChange={() => setNoBusinessNameCrimes(!noBusinessNameCrimes)}
                                                invalid={submissionAttempted && !noBusinessNameCrimes}
                                            />
                                            Within the last 5 years I have not been:
                                            <ul>
                                                <li>convicted of, or</li>
                                                <li>released from prison after being convicted of, and serving a term of imprisonment for, and of the criminal offences referred to in subsections 32(1)(c) or 32(1)(d) of the <i>Business Names Registration Act 2011</i></li>
                                            </ul>
                                            <FormFeedback>You must not have been convicted of or served a term of imprisonment under any of the subsections in the Act to use this form</FormFeedback>
                                        </Label>
                                    </FormGroup>
                                    <br />
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                checked={electronicLodgementCompliant}
                                                onChange={() => setElectronicLodgementCompliant(!electronicLodgementCompliant)}
                                                invalid={submissionAttempted && !electronicLodgementCompliant}
                                            />
                                            This application is submitted under, and is compliant with, the terms and conditions of the Australian Securities and Investments Commission (ASIC) <a href="https://asic.gov.au/online-services/electronic-lodgement-protocol/">Electronic Lodgement Protocol</a>.
                                            <FormFeedback>You must warrant that this application is submitted and compliant with the ASIC Electronic Lodgement Protocol</FormFeedback>
                                        </Label>
                                    </FormGroup>
                                    <br />
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                checked={declaresCompleteAndAccurate}
                                                onChange={() => setDeclaresCompleteAndAccurate(!declaresCompleteAndAccurate)}
                                                invalid={submissionAttempted && !declaresCompleteAndAccurate}
                                            />
                                            To the best of my knowledge, the information supplied in this transaction is complete and accurate (it is an offence to provide false or misleading information to ASIC)
                                            <FormFeedback>You must warrant that the information supplied is complete and accurate</FormFeedback>
                                        </Label>
                                    </FormGroup>
                                    <br />
                                    <Label>ASIC may contact the applicant or the lodging party (if any) if there are any questions regarding this application for registration of a business name</Label>
                                </Col>
                            </FormGroup>
                            <br />
                            <FormGroup tag="fieldset" row>
                                <Label sm={4} className="text-left">
                                    Terms of Service<br /><a href="/terms" className="text-muted">See Terms of Service</a>
                                </Label>
                                <Col sm={6}>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="termsAccepted"
                                                checked={termsAccepted}
                                                onChange={() => setTermsAccepted(!termsAccepted)}
                                                invalid={submissionAttempted && !termsAccepted}
                                            />
                                            I accept the Terms of Service
                                            <FormFeedback>You must accept the terms of service to use this form</FormFeedback>
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </FormGroup>
                        </CardBody>
                        <CardFooter className="text-right">
                            {error ? 'An error ocurred, please contact support via Messenger ' : ''}
                            {submissionAttempted && formInvalid ? ' Please correct errors above ' : ''}
                            <Button
                                type="submit"
                                color="success"
                                disabled={loading}
                            >
                                {loading
                                    ? <Spinner animation="border" variant="light" size="sm" />
                                    : null
                                } Continue <FaArrowRight />
                            </Button>
                        </CardFooter>
                    </Card>
                </Form>
            </Container>
            <SimpleFooter />
        </>
    );
};

export default ABNApplication;