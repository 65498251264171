import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Modal,
  ModalHeader,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { getBudgetList, makePayment } from "./actions";

const formatCurrency = (currency) => {
  return `$${parseFloat(currency).toFixed(2).toLocaleString()}`;
};

const PaymentModal = (props) => {
  const {
    isOpen,
    setIsOpen,
    triggerRefresh,
    businessProfile,
    originBankAccountUUID,
  } = props;

  const [loading, setLoading] = useState(false);

  const [budgetList, setBudgetList] = useState([]);
  const [budgetId, setBudgetId] = useState();

  const [bsb, setBSB] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");

  const validateBSB = (newBSB) => {
    if (newBSB.length <= 6) {
      setBSB(newBSB.replace(/\D/, ""));
    }
  };

  const validateAccountNumber = (newAccountNumber) => {
    setAccountNumber(newAccountNumber.replace(/\D/, ""));
  };

  const validatePayment = async () => {
    if (description.length > 18) {
      alert("Description must be less than 18 characters in length");
      return;
    }

    if (bsb.length !== 6) {
      alert("BSB must be 6 digits");
      return;
    }

    if (accountNumber === "") {
      alert("Account number is required");
      return;
    }

    if (amount === "") {
      alert("Amount is required");
      return;
    }

    setLoading(true);
    const payment = {
      origin_bank_account_uuid: originBankAccountUUID,
      bsb: bsb,
      account_number: accountNumber,
      amount: amount,
      description: description,
      weel_budget_id: budgetId,
    };
    const result = await makePayment(payment);

    if (result.status === "success") {
      //alert("Payment made successfully");
      await triggerRefresh();
      setIsOpen(false);

      setBSB("");
      setAccountNumber("");
      setAmount("");
      setDescription("");
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      const result = await getBudgetList();
      setBudgetList(result);
      if (result) {
        setBudgetId(result[0].id);
      }
    })();
  }, [setBudgetList]);

  return (
    <Modal
      className="modal-dialog-centered"
      size="sm"
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
    >
      <ModalHeader
        className="modal-header bg-secondary"
        toggle={() => setIsOpen(false)}
      >
        Make Payment
      </ModalHeader>
      <div className="modal-body p-0">
        <Card className="bg-secondary border-0">
          <CardHeader className="bg-transparent pb-5">
            <div className="text-muted text-center mt-2 mb-3">
              <small>Payment From Balance</small>
            </div>
            <div className="btn-wrapper text-center">
              Balance:{" "}
              {businessProfile
                ? formatCurrency(businessProfile.total_balance)
                : ""}
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>Receiving Bank Details</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-bank"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="BSB"
                    type="text"
                    value={bsb}
                    onChange={(e) => validateBSB(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-badge" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Account Number"
                    type="text"
                    value={accountNumber}
                    onChange={(e) => validateAccountNumber(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <hr />
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-coins" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Amount"
                    type="text"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-pencil" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Description"
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">
                <Button
                  className="my-4"
                  color="primary"
                  type="button"
                  onClick={() => validatePayment()}
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <span className="btn-inner--icon mr-1">
                        <i className="fas fa-circle-notch fa-spin" />
                      </span>
                      <span className="btn-inner--text">Make Payment</span>
                    </>
                  ) : (
                    <>
                      <span className="btn-inner--text">Make Payment</span>
                    </>
                  )}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
};

export { PaymentModal };
