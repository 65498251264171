import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  Badge,
} from "reactstrap";
import { getAuthorization } from "webauthn/session";

const CompanyDashboard = (props) => {
  const history = useHistory();
  const [company, setCompany] = useState();
  const [membersMap, setMembersMap] = useState();

  const [allotment, setAllotment] = useState();

  const [documentDownloadable, setDocumentDownloadable] = useState();
  const [documentDownloadLink, setDocumentDownloadLink] = useState();

  const [membersList, setMembersList] = useState([]);

  const consolidateAllotment = (memberAllotment, map) => {
    // Dedupe alloments (share classes) by member - XXX LOL
    let deduplicatedMemberList = [];
    memberAllotment.filter(function (elem1) {
      if (
        !deduplicatedMemberList.find(
          (elem2) => elem1.joint_member_uuids === elem2.joint_member_uuids
        )
      ) {
        deduplicatedMemberList.push(elem1);
      }
    });

    let markedUpMembersList = [];

    deduplicatedMemberList.map((deduplicatedMember) => {
      let jointMemberList = [];
      deduplicatedMember.joint_member_uuids.split(",").map((member_uuid) => {
        const member = map[member_uuid];

        const shareholderName = member.organisation_name
          ? member.organisation_name
          : `${member.first_name} ${member.last_name}`;

        jointMemberList.push({
          ceased: map[member_uuid].ceased,
          name: shareholderName,
          director: map[member_uuid].director,
          secretary: map[member_uuid].secretary,
        });
      });

      markedUpMembersList.push(jointMemberList);
    });

    // this will ONLY check the "first" joint member and sort it.
    // XXX: what to do if an officeholder is a joint member - add a test about this (?)
    markedUpMembersList = markedUpMembersList.sort((markedUpMemberA) =>
      markedUpMemberA[0]?.secretary ? -1 : 1
    );
    markedUpMembersList = markedUpMembersList.sort((markedUpMemberA) =>
      markedUpMemberA[0]?.director ? -1 : 1
    );

    return markedUpMembersList;
  };

  useEffect(() => {
    (async () => {
      if (!props.businessProfile?.is_company) {
        history.push("/dashboard");
      }
      const companyUUID = props.businessProfile.company_registration?.uuid;
      if (companyUUID) {
        const response = await fetch(
          `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/company/${companyUUID}`,
          {
            headers: {
              Authorization: await getAuthorization(),
            },
          }
        );
        const json = await response.json();
        // XXX: fix this...

        const map = json.company.members.reduce((map, object) => {
          map[object.uuid] = object;
          return map;
        }, {});
        setCompany(json.company);
        setMembersMap(map);

        console.log(membersMap);

        // XXX: MEMBER_ALLOTMENTS DIFFERENT TO ALLOTMENT (current "user")
        console.log(json.company.member_allotments);
        const consolidatedAllotment = consolidateAllotment(
          json.company.member_allotments,
          map
        );

        //const markedUpMembersList = markUpAllotment(consolidatedAllotment);
        setMembersList(consolidatedAllotment);

        const allotmentResponse = await fetch(
          `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/company/${companyUUID}/get-allotment`,
          {
            headers: {
              Authorization: await getAuthorization(),
            },
          }
        );

        if (allotmentResponse.ok) {
          const allotmentJSON = await allotmentResponse.json();
        }
      }
    })();
  }, [setCompany, setMembersMap, setAllotment, setMembersList]);

  const memberAddress =
    props.businessProfile?.company_registration?.member_address;

  const manualLine1 =
    props.businessProfile?.company_registration?.member_manual_address?.line1;
  const manualLine2 =
    props.businessProfile?.company_registration?.member_manual_address?.line2;
  const manualStreet =
    props.businessProfile?.company_registration?.member_manual_address?.street;
  const manualLocality =
    props.businessProfile?.company_registration?.member_manual_address
      ?.locality;
  const manualState =
    props.businessProfile?.company_registration?.member_manual_address?.state;
  const manualPostcode =
    props.businessProfile?.company_registration?.member_manual_address
      ?.postcode;
  const manualCountry =
    props.businessProfile?.company_registration?.member_manual_address?.country;

  const download = async (s3ObjectKey, responseContentType) => {
    const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/document-download`,
      {
        method: "post",
        headers: {
          Authorization: await getAuthorization(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          s3_object_key: s3ObjectKey,
          response_content_type: responseContentType,
        }),
      }
    );
    const json = await response.json();

    // XXX: Hack
    setDocumentDownloadable(s3ObjectKey);
    setDocumentDownloadLink(json.presigned_url);
  };

  return (
    <>
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row className="mt-5">
          <Col className="mb-5 mb-xl-0" xl="7">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Company Details</h3>
                  </div>
                  <div className="align-items-right">
                    <Badge color="success">Connected to ASIC</Badge>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Item</th>
                    <th scope="col" className="text-right">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Company Name:</th>
                    <td className="text-right">
                      {props.businessProfile?.company_registration
                        ?.legal_name ||
                        props.businessProfile?.company_registration
                          ?.proposed_name}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">ACN:</th>
                    <td className="text-right">
                      {props.businessProfile?.company_registration?.acn ||
                        "Pending"}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Certificate of Incorporation:</th>
                    <td className="text-right">
                      {documentDownloadable ? (
                        <a href={documentDownloadLink} target="_blank">
                          Download
                        </a>
                      ) : (
                        <Button
                          color="success"
                          onClick={() => {
                            if (
                              props.businessProfile?.company_registration
                                ?.certificate_object_key
                            ) {
                              download(
                                props.businessProfile?.company_registration
                                  ?.certificate_object_key,
                                "application/pdf"
                              );
                            } else {
                              alert("Certificate not yet available");
                            }
                          }}
                          size="sm"
                        >
                          View Certificate
                        </Button>
                      )}
                    </td>
                  </tr>
                  {/*<tr>
                    <th scope="row">Total Shares:</th>
                    <td className="text-right">
                      {company?.share_structure[0]?.total_shares}{" "}
                      {company?.share_structure[0]?.share_class_code}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Your Name:</th>
                    <td className="text-right">
                      {
                        props.businessProfile?.company_registration
                          ?.member_first_name
                      }{" "}
                      {
                        props.businessProfile?.company_registration
                          ?.member_last_name
                      }
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Your Address:</th>
                    <td className="text-right">
                      {memberAddress
                        ? memberAddress[0].formatted_address
                        : `${manualLine1 ? `${manualLine1},` : ""} ${
                            manualLine2 ? `${manualLine2},` : ""
                          } ${manualStreet ? `${manualStreet},` : ""} ${
                            manualLocality ? `${manualLocality},` : ""
                          } ${manualState ? `${manualState},` : ""} ${
                            manualPostcode ? `${manualPostcode},` : ""
                          } ${manualCountry ? `${manualCountry}` : ""}`}
                    </td>
                        </tr>*/}
                  <tr>
                    <th scope="row">Your Holding:</th>
                    <td className="text-right">
                      <Button
                        color="success"
                        onClick={() =>
                          alert("Next investment round not open yet")
                        }
                        size="sm"
                      >
                        Apply for Shares
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Shareholders Agreement:</th>
                    <td className="text-right">
                      <Button
                        color="success"
                        onClick={() => alert("Coming Soon!")}
                        size="sm"
                      >
                        View Agreement
                      </Button>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Share Certificate:</th>
                    <td className="text-right">
                      <Button
                        color="success"
                        onClick={() => alert("Coming Soon!")}
                        size="sm"
                      >
                        View Certificate
                      </Button>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Card>
          </Col>
          <Col xl="5">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">Members List</h3>
                  </div>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Member</th>
                    {props.businessProfile?.company_registration?.acn ===
                    "614558646" ? (
                      ""
                    ) : (
                      <th scope="col">Holds Shares</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {membersMap &&
                    membersList?.map((jointMembersList, index) => {
                      return jointMembersList.find((member) => member.ceased) ? undefined : (
                        <tr key={index}>
                          <th scope="row">
                            {jointMembersList
                              .map((jointMember) => jointMember.name)
                              .join(", ")}
                            {jointMembersList.length === 1 &&
                            jointMembersList[0].director ? (
                              <>
                                <Badge color="success" pill>
                                  Director
                                </Badge>
                              </>
                            ) : undefined}
                            {jointMembersList.length === 1 &&
                            jointMembersList[0].secretary ? (
                              <>
                                <Badge color="info" pill>
                                  Secretary
                                </Badge>
                              </>
                            ) : undefined}
                          </th>
                          {props.businessProfile?.company_registration?.acn ===
                          "614558646" ? (
                            ""
                          ) : (
                            <td>✅</td>
                          )}
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CompanyDashboard;
