import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";
import {
  Button,
  Card,
  CardHeader,
  CardFooter,
  Container,
  Row,
  Col,
  Badge,
  Table,
} from "reactstrap";
import { getPasskeyJWTAsync } from "../../../webauthn/securityStepUp";

import { getScheduledPayments, getTransactions } from "./actions";
import { PaymentModal } from "./paymentModal";

const Banking = (props) => {
  const principalLegalEntity = JSON.parse(
    localStorage.getItem("principalLegalEntity")
  );

  const params = useParams();

  const [transferModalIsOpen, setTransferModalIsOpen] = useState(false);

  const [scheduledPayments, setScheduledPayments] = useState([]);

  const [transactions, setTransactions] = useState([]);
  const [pendingPayments, setPendingPayments] = useState([]);

  const getTransactions = async () => {
    if (principalLegalEntity?.legal_entity_uuid) {
      const response = await fetch(
        `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/payment-account/${params.uuid}/transaction`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
            "x-principal-legal-entity-uuid":
              principalLegalEntity.legal_entity_uuid,
          },
        }
      );
      const json = await response.json();

      setTransactions(json.transactions);
    }
  };

  useEffect(() => {
    (async () => {
      //const result = await getTransactions();
      if (principalLegalEntity?.legal_entity_uuid) {
        const response = await fetch(
          `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/payment-account/${params.uuid}/transaction`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
              "x-principal-legal-entity-uuid":
                principalLegalEntity.legal_entity_uuid,
            },
          }
        );
        const json = await response.json();

        //return json.accounts;
        setTransactions(json.transactions);
      }

      //setTransactions(result.transactions);
      //setPendingPayments(result.pendingPayments);

      const scheduledPaymentsResult = await getScheduledPayments();
      setScheduledPayments(scheduledPaymentsResult);
    })();
  }, [params.uuid, setTransactions]);

  //const triggerRefresh = async () => {}
  const triggerRefresh = async () => {
    const result = await getTransactions();

    //setTransactions(result.transactions);
    //setPendingPayments(result.pendingPayments);
  };

  return (
    <>
      <PaymentModal
        isOpen={transferModalIsOpen}
        setIsOpen={setTransferModalIsOpen}
        triggerRefresh={triggerRefresh}
        originBankAccountUUID={params.uuid}
        businessProfile={props.businessProfile}
      />
      {/* Page content */}
      <Container className="mt--5" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Scheduled Payments</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    {/*<Button
                      color="success"
                      onClick={() => setTransferModalIsOpen(true)}
                      size="sm"
                    >
                      New Scheduled Payment
                    </Button>
                    */}
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Description</th>
                    <th scope="col">Payee</th>
                    <th scope="col">Amount</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {scheduledPayments.map((payment) => {
                    return (
                      <tr key={payment.id}>
                        <th scope="row">
                          {DateTime.fromISO(payment.next_date).toLocaleString(
                            DateTime.DATE_MED
                          )}
                        </th>
                        <td>{payment.description}</td>
                        <td>
                          {payment.destination_bsb} |{" "}
                          {payment.destination_account_number}
                        </td>
                        <td>${parseFloat(payment.amount).toFixed(2)}</td>
                        <td></td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <CardFooter className="py-4"></CardFooter>
            </Card>
            <br />
            <br />
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Transactions</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="success"
                      onClick={() => setTransferModalIsOpen(true)}
                      size="sm"
                    >
                      Transfer Funds
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Description</th>
                    <th scope="col">Status</th>
                    <th scope="col" className="text-right">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {pendingPayments.map((pendingPayment) => {
                    return (
                      <tr key={pendingPayment.id}>
                        <th scope="row">
                          {DateTime.fromISO(
                            pendingPayment.created
                          ).toLocaleString(DateTime.DATE_MED)}
                        </th>
                        <td>{pendingPayment.reference}</td>
                        <td>
                          <Badge color="" className="badge-dot mr-4">
                            <i className="bg-info" />
                            pending
                          </Badge>
                        </td>
                        <td className="text-right">
                          -
                          {parseFloat(
                            parseFloat(pendingPayment.amount).toLocaleString()
                          ).toFixed(2)}
                        </td>
                      </tr>
                    );
                  })}
                  {transactions?.map((transaction) => {
                    return (
                      <tr key={transaction.id}>
                        <th scope="row">
                          {DateTime.fromISO(
                            transaction.cleared_date || transaction.created_at
                          ).toLocaleString(DateTime.DATE_MED)}
                        </th>
                        <td>
                          {transaction.description ||
                            transaction.data?.line_items?.[0]?.description}
                        </td>
                        <td>
                          <Badge color="" className="badge-dot mr-4">
                            {transaction.pending ? (
                              <>
                                <i className="bg-info" />
                                pending
                              </>
                            ) : (
                              <>
                                <i className="bg-success" />
                                cleared
                              </>
                            )}
                          </Badge>
                        </td>
                        <td className="text-right">
                          {new Intl.NumberFormat("en-AU", {
                            style: "currency",
                            currency: "AUD",
                          }).format(transaction.amount)}{" "}
                          {transaction.currency}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <CardFooter className="py-4"></CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Banking;
