import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardHeader, CardBody, CardFooter, Form, FormGroup, FormFeedback, Label, Input, Spinner, Button, Container, Row, Col } from 'reactstrap';
import { FaArrowRight } from 'react-icons/fa';
import Select from 'react-select';
import countryList from 'react-select-country-list';

import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { AutofillDisabledInput } from 'views/components/autofillDisabledInput';

import FlowNavbar from 'components/Navbars/FlowNavbar';
import ProgressBar from '../../../components/progressBar';
import SimpleFooter from 'components/Footers/SimpleFooter.js';


const CompanyMembers = (props) => {
    const history = useHistory();

    const stepNumber = 2;

    const [loading, setLoading] = useState(false);
    const [submissionAttempted, setSubmissionAttempted] = useState(false);

    const [soleDirectorShareholderStructure, setSoleDirectorShareholderStructure] = useState(true);

    const [memberCount, setMemberCount] = useState('1');

    const [members, setMembers] = useState([
        {
            director: true,
            secretary: false,
            shareholder: true,

            firstName: localStorage.getItem('member1.firstName'),
            middleName: localStorage.getItem('member1.firstName'),
            lastName: localStorage.getItem('member1.firstName'),

            autocompletedAddress: null,
            geocodedAddress: null,

            birthDateDay: '',
            birthDateMonth: 0,
            birthDateYear: '',

            birthCity: '',
            birthState: 'NSW',
            birthCountry: { 'value': 'AU', 'label': 'Australia' },

        }
    ]);

    const [firstName, setFirstName] = useState(localStorage.getItem('firstName'));
    const [middleName, setMiddleName] = useState(localStorage.getItem('middleName'));
    const [lastName, setLastName] = useState(localStorage.getItem('lastName'));
    const [email, setEmail] = useState(localStorage.getItem('email'));
    const [phone, setPhone] = useState(localStorage.getItem('phone'));

    const [birthDateDay, setBirthDateDay] = useState('');
    const [birthDateMonth, setBirthDateMonth] = useState(0);
    const [birthDateYear, setBirthDateYear] = useState('');

    const [birthState, setBirthState] = useState('NSW');
    const [birthCity, setBirthCity] = useState('');
    const [birthCountry, setBirthCountry] = useState({ "value": "AU", "label": "Australia" });

    const [autocompletedAddress, setAutocompletedAddress] = useState(null);
    const [geocodedAddress, setGeocodedAddress] = useState(null);
    const handleAutocompletedAddressChange = async (address) => {
        setAutocompletedAddress(address);
        if (address) {
            const result = await geocodeByPlaceId(address.value.place_id);
            setGeocodedAddress(result);
        }
    };

    const submitForm = async () => {

        const result = await fetch(`${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/company-application/members`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                company_uuid: localStorage.getItem('companyUUID'),
                members: [
                    {
                        first_name: firstName,
                        middle_name: middleName,
                        last_name: lastName,
                        email: email,
                        phone: phone,
                        residential_address: geocodedAddress,
                        birth_date: `${birthDateYear}-${parseInt(birthDateMonth) + 1}-${birthDateDay}`,
                        birth_country: birthCountry,
                        birth_state: birthState,
                        birth_city: birthCity,
                    }
                ]
            })
        });
        const json = await result.json();
        localStorage.setItem('soleDirectorShareholderMemberUUID', json.member_uuid);

        window.location = '/lodge/company-registration/registered-office';
    };

    return (
        <>
            <FlowNavbar />
            <Container>
                <br />
                <ProgressBar title={`${localStorage.getItem('companyName')} PTY LTD`} step={stepNumber} stepCount={5} stepName="Member Deatils" />
                <br />

                <div>
                    <Form onSubmit={async (e) => { e.preventDefault(); await submitForm() }}>

                        <Card className="text-left shadow border-1 mb-5">
                            <CardHeader>Step {stepNumber}. Member Details</CardHeader>
                            <CardBody>

                                <h3>Members List</h3>
                                How many members will the Company have at incorporation<br />
                                You must provide identification for each company member.

                                <br />
                                <br />
                                <br />

                                <FormGroup tag="fieldset" row>
                                    <Label sm={3} className="text-right">
                                        Company Structure
                                    </Label>
                                    <Col sm={6}>

                                        <FormGroup check>
                                            <Label check>
                                                <Input
                                                    type="radio"
                                                    name="activelyOccupy"
                                                    checked={soleDirectorShareholderStructure}
                                                    onChange={() => setSoleDirectorShareholderStructure(true)}
                                                />
                                                1 Member (Sole Director Shareholder Company)
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Label check>
                                                <Input
                                                    type="radio"
                                                    name="activelyOccupy"
                                                    checked={!soleDirectorShareholderStructure}
                                                    onChange={() => setSoleDirectorShareholderStructure(false)}
                                                />
                                                More than one member
                                            </Label>
                                        </FormGroup>
                                    </Col>
                                </FormGroup>




                                {!soleDirectorShareholderStructure
                                    ? <>
                                        <FormGroup tag="fieldset" row>
                                            <Label as="legend" sm={3} className="text-right">
                                                Members
                                            </Label>
                                            <Col sm={6}>
                                                <Input
                                                    type="select"
                                                    onChange={(e) => setMemberCount(e.target.value)}
                                                    value={memberCount}
                                                >
                                                    <option value="2">2 Members</option>
                                                    <option value="3">3 Members</option>
                                                    <option value="4">4 Members</option>
                                                    <option value="5">5 Members</option>
                                                    <option value="moreThan5">More than 5 Members</option>
                                                </Input>
                                            </Col>
                                        </FormGroup>
                                        <br />
                                        <h4 className="text-center">Coming soon, please contact us!</h4>
                                        <p className="text-center">To preview the multi-person incorporation experience, please get in touch!</p>
                                        <div className="text-center">
                                            <Button
                                                type="button"
                                                color="success"
                                                onClick={() => window.location.href = 'tel:+611300201329'}
                                            >
                                                <i className="fa fa-phone mr-2" /> Call us now
                                            </Button>
                                        </div>
                                    </>
                                    : undefined}

                                <br />
                                <br />

                                {soleDirectorShareholderStructure && members.map((member, memberIndex) => <>
                                    <h3>First Member</h3>
                                    Select this members role in the company, and proivde their identification details.

                                    <br />
                                    <br />
                                    <br />

                                    <h5>Individual Details</h5>
                                    Provide identifying details of this member

                                    <FormGroup tag="fieldset" row>

                                        <Label sm={4} className="text-left">
                                            Full name
                                        </Label>
                                        <Col xs={4} sm={2}>
                                            <Input
                                                type="text"
                                                placeholder="First name"
                                                value={firstName}
                                                onChange={(e) => { localStorage.setItem(`firstName`, e.target.value); setFirstName(e.target.value) }}
                                                valid={firstName !== ''}
                                                invalid={submissionAttempted && !firstName}
                                            />
                                        </Col>
                                        <Col xs={4} sm={2}>
                                            <Input
                                                type="text"
                                                placeholder="Middle name"
                                                value={middleName}
                                                onChange={(e) => { localStorage.setItem('middleName', e.target.value); setMiddleName(e.target.value) }}
                                                valid={middleName !== ''}
                                                invalid={submissionAttempted && !middleName}
                                            />
                                        </Col>
                                        <Col xs={4} sm={2}>
                                            <Input
                                                type="text"
                                                placeholder="Last name"
                                                value={lastName}
                                                onChange={(e) => { localStorage.setItem('lastName', e.target.value); setLastName(e.target.value) }}
                                                valid={lastName !== ''}
                                                invalid={submissionAttempted && !lastName}
                                            />
                                        </Col>
                                    </FormGroup>

                                    <FormGroup tag="fieldset" row>
                                        <Label sm={4} className="text-left">
                                            Email
                                        </Label>
                                        <Col sm={6}>
                                            <Input
                                                type="text"
                                                placeholder="you@yourbusiness.com.au"
                                                value={email}
                                                onChange={(e) => { localStorage.setItem('email', e.target.value); setEmail(e.target.value) }}
                                                valid={email !== ''}
                                                invalid={submissionAttempted && !email}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup tag="fieldset" row>
                                        <Label sm={4} className="text-left">
                                            Phone number
                                        </Label>
                                        <Col sm={6}>
                                            <Input
                                                type="text"
                                                placeholder="04xx xxx xxx"
                                                value={phone}
                                                valid={phone !== ''}
                                                onChange={(e) => { localStorage.setItem('phone', e.target.value); setPhone(e.target.value) }}
                                                invalid={submissionAttempted && !phone}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup tag="fieldset" row>
                                        <Label sm={4} className="text-left">
                                            Residential address
                                        </Label>
                                        <Col sm={6}>
                                            <GooglePlacesAutocomplete
                                                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                                                autocompletionRequest={{
                                                    componentRestrictions: {
                                                        country: ['au'],
                                                    },
                                                    types: ['address'],
                                                }}
                                                selectProps={{
                                                    components: { Input: AutofillDisabledInput },
                                                    isClearable: true,
                                                    value: autocompletedAddress,
                                                    onChange: handleAutocompletedAddressChange,
                                                    placeholder: 'Start typing address',

                                                }}
                                            />
                                        </Col>
                                    </FormGroup>

                                    <FormGroup tag="fieldset" row>
                                        <Label sm={4} className="text-left">
                                            Date of birth
                                        </Label>
                                        <Col sm={2}>
                                            <Input
                                                type="text"
                                                placeholder="Day"
                                                value={birthDateDay}
                                                onChange={(e) => setBirthDateDay(e.target.value)}
                                                invalid={submissionAttempted && birthDateDay === ''}
                                            />
                                        </Col>
                                        <Col sm={2}>
                                            <Input
                                                type="select"
                                                onChange={(e) => setBirthDateMonth(e.target.value)}
                                                value={birthDateMonth}
                                            >
                                                <option value="0">January</option>
                                                <option value="1">February</option>
                                                <option value="2">March</option>
                                                <option value="3">April</option>
                                                <option value="4">May</option>
                                                <option value="5">June</option>
                                                <option value="6">July</option>
                                                <option value="7">August</option>
                                                <option value="8">September</option>
                                                <option value="9">October</option>
                                                <option value="10">November</option>
                                                <option value="11">December</option>

                                            </Input>
                                        </Col>
                                        <Col sm={2}>
                                            <Input
                                                type="text"
                                                placeholder="Year"
                                                value={birthDateYear}
                                                onChange={(e) => setBirthDateYear(e.target.value)}
                                                invalid={submissionAttempted && birthDateYear === ''}
                                            />
                                        </Col>
                                    </FormGroup>

                                    <br />

                                    <FormGroup tag="fieldset" row>
                                        <Label sm={4} className="text-left">
                                            Country of Birth
                                        </Label>
                                        <Col sm={6}>
                                            <Select
                                                components={{ Input: AutofillDisabledInput }}
                                                options={countryList().getData()}
                                                value={birthCountry}
                                                onChange={value => setBirthCountry(value)}
                                            />
                                        </Col>
                                    </FormGroup>
                                    {birthCountry.value === 'AU'
                                        ? <>
                                            <FormGroup tag="fieldset" row>
                                                <Label sm={4} className="text-left">
                                                    State of Birth
                                                </Label>
                                                <Col sm={6}>
                                                    <Input
                                                        type="select"
                                                        onChange={(e) => setBirthState(e.target.value)}
                                                        value={birthState}
                                                    >
                                                        <option value="NSW">New South Wales</option>
                                                        <option value="ACT">Australian Capital Territory</option>
                                                        <option value="NT">Northern Territory</option>
                                                        <option value="QLD">Queensland</option>
                                                        <option value="SA">South Australia</option>
                                                        <option value="TAS">Tasmania</option>
                                                        <option value="VIC">Victoria</option>
                                                        <option value="WA">Western Australia</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup tag="fieldset" row>
                                                <Label sm={4} className="text-left">
                                                    City/Town of Birth
                                                </Label>
                                                <Col sm={6}>
                                                    <Input
                                                        type="text"
                                                        placeholder="City/Town of Birth"
                                                        value={birthCity}
                                                        valid={birthCity !== ''}
                                                        onChange={(e) => setBirthCity(e.target.value)}
                                                        invalid={submissionAttempted && birthCity === ''}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </>
                                        : undefined}
                                </>)}


                            </CardBody>
                            <CardFooter className="text-right">
                                <Button
                                    type="submit"
                                    color="success"
                                    disabled={loading || !soleDirectorShareholderStructure}
                                >
                                    {loading
                                        ? <Spinner animation="border" variant="light" size="sm" />
                                        : null
                                    } Continue <FaArrowRight />
                                </Button>
                            </CardFooter>
                        </Card>

                    </Form>
                </div>
            </Container>
            <SimpleFooter />
        </>
    );
}

export default CompanyMembers;