import React from "react";
import { Container } from "reactstrap";

import FlowNavbar from "components/Navbars/FlowNavbar";
import SimpleFooter from "components/Footers/SimpleFooter.js";

const Terms = () => {
  return (
    <>
      <FlowNavbar />
      <Container>
        <br />
        <h1>Account Deletion</h1>
        <br />
        <p>
          In this document, accountedfor, we, us and our refers to A4 Technology
          Pty Ltd (ABN: 49 672 208 472).
        </p>
        <p>
          accountedfor is a taxation, finance and accounting platform that hosts
          among other things, business records and records relating to you and
          your business, including your accountedfor account.
        </p>
        <p>
          You may have a limited right to request deletion of your account at
          accountedfor.
        </p>
        <p>
          However, accountedfor has obligations of data retention, and
          prohibitions and restraints on the deletion or destruction of data
          records.
        </p>
        <p>
          Additionally, as accountedfor files some documents on your behalf to
          various governments and third parties, deletion of such data is not
          possible in certain cases.
        </p>
        <p>
          If you would like to request deletion of your account, you may contact{" "}
          <strong>support@accountedfor.com.au</strong> and outline your request
          by making reference to certain features or descriptions of the account
          or data you would like to be deleted.
        </p>
        <p>
          accountedfor may require that you authenticate your request for
          account deletion and endeavours to return your correspondence with a
          decision as to the destruction or non-destruction of the described
          records in accordance with law.
        </p>
        <br />
        <br />
        <br />
        <br />
      </Container>
      <SimpleFooter />
    </>
  );
};

export default Terms;
