import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import { getAuthorization } from "webauthn/session";
import { getPasskeyJWTAsync } from "../../../webauthn/securityStepUp";

const EditCustomer = (props) => {
  const principalLegalEntity = JSON.parse(
    localStorage.getItem("principalLegalEntity")
  );

  const history = useHistory();
  const params = useParams();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [notes, setNotes] = useState("");

  useEffect(() => {
    (async () => {
      if (principalLegalEntity?.agent_legal_entity?.customer_uuid) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/counterparty/${params.uuid}`,
            {
              headers: {
                Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
                "x-principal-legal-entity-uuid":
                  principalLegalEntity?.legal_entity_uuid,
              },
            }
          );

          if (response.ok) {
            const json = await response.json();
            setFirstName(json.counterparty.first_name);
            setLastName(json.counterparty.last_name);
            setEmail(json.counterparty.email);
            setPhone(json.counterparty.phone);
            setBusinessName(json.counterparty.business_name);
            setNotes(json.counterparty.notes);
          }
        } catch (e) {
          alert(`An unknown error occurred. (Server said: ${e})`);
        }
      } else {
        try {
          const authorization = await getAuthorization();
          const response = await fetch(
            `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/contact/${params.uuid}`,
            {
              headers: {
                Authorization: authorization,
              },
            }
          );

          const json = await response.json();
          setFirstName(json.contact.first_name);
          setLastName(json.contact.last_name);
          setEmail(json.contact.email);
          setPhone(json.contact.phone);
          setBusinessName(json.contact.business_name);
          setNotes(json.contact.notes);
        } catch (e) {
          alert(`An unknown error occurred. (Server said: ${e})`);
        }
      }
    })();
  }, [params]);

  const updateCustomer = async () => {
    if (principalLegalEntity?.agent_legal_entity?.customer_uuid) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/counterparty/${params.uuid}`,
          {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
              "x-principal-legal-entity-uuid":
                principalLegalEntity?.legal_entity_uuid,
            },
            body: JSON.stringify({
              first_name: firstName,
              last_name: lastName,
              email: email,
              phone: phone,
              business_name: businessName,
              notes: notes,
            }),
          }
        );

        if (response.ok) {
          history.push("/dashboard/customers");
        }
      } catch (e) {
        alert(`An unknown error occurred. (Server said: ${e})`);
      }
    } else {
      try {
        const authorization = await getAuthorization();
        const response = await fetch(
          `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/contact/${params.uuid}/update`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: authorization,
            },
            body: JSON.stringify({
              first_name: firstName,
              last_name: lastName,
              email: email,
              phone: phone,
              business_name: businessName,
              notes: notes,
            }),
          }
        );

        if (response.ok) {
          history.push("/dashboard/customers");
        }
      } catch (e) {
        alert(`An unknown error occurred. (Server said: ${e})`);
      }
    }
  };

  const deleteCustomer = async () => {
    const result = window.confirm(
      "Are you sure you want to delete this customer record?"
    );

    if (result) {
      if (principalLegalEntity?.agent_legal_entity?.customer_uuid) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/counterparty/${params.uuid}`,
            {
              method: "delete",
              headers: {
                Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
                "x-principal-legal-entity-uuid":
                  principalLegalEntity?.legal_entity_uuid,
              },
            }
          );

          if (response.ok) {
            history.push("/dashboard/customers");
          }
        } catch (e) {
          alert(`An unknown error occurred. (Server said: ${e})`);
        }
      } else {
        try {
          const authorization = await getAuthorization();
          const response = await fetch(
            `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/contact/${params.uuid}`,
            {
              method: "DELETE",
              headers: {
                Authorization: authorization,
              },
            }
          );

          if (response.ok) {
            history.push("/dashboard/customers");
          }
        } catch (e) {
          alert(`An unknown error occurred. (Server said: ${e})`);
        }
      }
    }
  };

  return (
    <>
      {/* Page content */}
      <Container className="mt--5" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Edit Customer</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    User information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            First name
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            id="input-first-name"
                            placeholder="First name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Last name
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            id="input-last-name"
                            placeholder="Last name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-phone"
                          >
                            Phone
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder="Phone"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Business name (optional)
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-business-name"
                          >
                            Business Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-business-name"
                            value={businessName}
                            onChange={(e) => setBusinessName(e.target.value)}
                            placeholder="Business Name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">Notes</h6>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <label>Notes</label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Customer notes"
                        rows="4"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        type="textarea"
                      />
                    </FormGroup>
                  </div>
                </Form>
              </CardBody>
              <CardFooter>
                <Row className="align-items-center">
                  <Col xs="6">
                    <Button
                      color="danger"
                      onClick={() => deleteCustomer()}
                      size="sm"
                    >
                      Delete Customer
                    </Button>
                  </Col>
                  <Col className="text-right" xs="6">
                    <Button
                      color="success"
                      onClick={() => updateCustomer()}
                      size="sm"
                    >
                      Save Customer
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditCustomer;
