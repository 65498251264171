import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Container,
    Col,
    Card,
    CardTitle,
    CardBody,
    CardText,
    CardSubtitle,
    Button,
    Badge,
    Form,
    FormGroup,
    Label,
    Input,
    Spinner,
} from 'reactstrap';
import { FaArrowRight, FaCheck, FaTimes } from 'react-icons/fa';

import FlowNavbar from 'components/Navbars/FlowNavbar';
import SimpleFooter from 'components/Footers/SimpleFooter.js';
import ProgressBar from '../../../components/progressBar';


// XXX: Lol - David ABN: 82 552 569 055

const BNRegistrationDetails = (props) => {
    const history = useHistory();


    const [businessName, setBusinessName] = useState('');
    const [businessNameSearchAttempted, setBusinessNameSearchAttempted] = useState(false);
    const [businessNameLoading, setBusinessNameLoading] = useState(false);
    const [businessNameAvailable, setBusinessNameAvailable] = useState(false);

    const [registrationPeriod, setRegistrationPeriod] = useState(1);

    const [abn, setABN] = useState(localStorage.getItem('unverifiedABN'));
    const [abnError, setABNError] = useState(false);
    const [abnLoading, setABNLoading] = useState(false);
    const [abrEntity, setABREntity] = useState({});


    const searchName = async () => {
        setBusinessNameSearchAttempted(true);
        setBusinessNameLoading(true);

        const result = await fetch(`${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/name-search?name=${businessName}`);
        const resultJSON = await result.json();

        if (resultJSON.result.code !== 'Unavailable') {
            localStorage.setItem('nameSearchResultId', resultJSON.nameSearchResultId);
            setBusinessNameAvailable(true);
        } else {
            setBusinessNameAvailable(false);
        }

        setBusinessNameLoading(false);
    };

    const searchABN = async () => {
        setABNLoading(true);

        const abnSearchString = abn.replace(/\s/g, '');

        const result = await fetch(`${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/abn-lookup/${abnSearchString}`);
        const resultJSON = await result.json();
        if (resultJSON.status !== 'ok') {
            alert('ABN invalid, please try again');
            setABNError(true);
        } else {
            setABNError(false);

            localStorage.setItem('abn', resultJSON.result.abn);
            localStorage.setItem('abnSearchId', resultJSON.abnSearchId);

            setABREntity(resultJSON.result);
        }
        setABNLoading(false);
    }

    const submitForm = async () => {

        if (!businessNameAvailable) {
            alert('Please search for an available business name before continuing');
        } else {
            localStorage.setItem('registrationPeriod', registrationPeriod);
            history.push('/lodge/business-name-application/owner-details');
        }

    };

    return (<>
        <FlowNavbar />
        <Container>
            <br />
            <ProgressBar title="New Business Name Registration" step={1} stepCount={3} stepName="Registration Deatils" />
            <br />


            <Form onSubmit={(e) => { e.preventDefault(); submitForm() }}>

                <Card className="text-left shadow border-1 mb-5">
                    <CardBody>


                        <h3>Business Name</h3>

                        <br />
                        <br />


                        <FormGroup tag="fieldset" row>
                            <Label sm={4} className="text-left">
                                Business Name Search
                            </Label>
                            <Col sm={5}>
                                <Input
                                    type="text"
                                    placeholder="Type to search"
                                    onChange={e => setBusinessName(e.target.value)}
                                />
                                <br />
                                <Button
                                    type="submit"
                                    color="success"
                                    onClick={_ => searchName()}
                                    disabled={businessNameLoading}
                                >
                                    {businessNameLoading
                                        ? <Spinner animation="border" variant="light" size="sm" />
                                        : null
                                    } Search
                                </Button>
                                <br />
                                <br />
                            </Col>
                            <Col sm={2}>
                                {businessNameSearchAttempted && !businessNameLoading
                                    ? businessNameAvailable
                                        ? <h3><Badge color="success">Available <FaCheck /></Badge></h3>
                                        : <h3><Badge color="danger">Unavailable <FaTimes /></Badge></h3>
                                    : null
                                }
                            </Col>
                        </FormGroup>
                        <FormGroup tag="fieldset" row>
                            <Label sm={4} className="text-left">
                                Registration Period
                            </Label>
                            <Col sm={6}>
                                <FormGroup check>
                                    <Label check>
                                        <Input
                                            type="radio"
                                            name="businessNameregistrationPeriod"
                                            checked={registrationPeriod === 1}
                                            onChange={() => setRegistrationPeriod(1)}
                                        />
                                        1 Year Registration - $65.00
                                    </Label>
                                </FormGroup>
                                <FormGroup check>
                                    <Label check>
                                        <Input
                                            type="radio"
                                            name="businessNameregistrationPeriod"
                                            checked={registrationPeriod === 3}
                                            onChange={() => setRegistrationPeriod(3)}
                                        />
                                        3 Years Registration - $180.00
                                    </Label>
                                </FormGroup>


                            </Col>
                        </FormGroup>
                        <hr />
                        <h3>Australian Business Number</h3>
                        <br />
                        <br />
                        <fieldset>
                            <FormGroup tag="fieldset" row>
                                <Label sm={4} className="text-left">
                                    Search for your ABN
                                </Label>
                                <Col sm={6}>
                                    <Input
                                        type="text"
                                        placeholder="Australian Business Number"
                                        value={abn}
                                        valid={abn !== ''}
                                        invalid={abnError}
                                        onChange={(e) => { localStorage.setItem('unverifiedABN', e.target.value) ; setABN(e.target.value) }}
                                    />
                                    <br />
                                    <Button
                                        type="submit"
                                        color="primary"
                                        onClick={_ => searchABN()}
                                        disabled={abnLoading}
                                    >
                                        {abnLoading
                                            ? <Spinner animation="border" variant="light" size="sm" />
                                            : null
                                        } Check ABN
                                    </Button>
                                    <br />
                                    <br />
                                    {abrEntity.entityName
                                        ? <Card>
                                            <CardBody>
                                                <CardTitle>{abrEntity.entityName}</CardTitle>
                                                <CardSubtitle className="mb-2 text-muted">{abrEntity.entityType.name}</CardSubtitle>
                                                <CardText>
                                                    ABN Status: {abrEntity.entityStatus === 'Active' ? <Badge pill color="success">
                                                        Active
                                                    </Badge> : undefined}
                                                    <hr />
                                                    <p>The above ABN was found for your business, and is eligible to register a name.</p>
                                                    <div className="text-right">
                                                        <Button
                                                            type="submit"
                                                            color="success"
                                                        >
                                                            Continue <FaArrowRight />
                                                        </Button>
                                                    </div>
                                                </CardText>
                                            </CardBody>
                                        </Card>
                                        : <>
                                            <p>Can't find your ABN? Call us on <a href="tel:+611300201329">1300 201 329</a></p>
                                            <p>Need to register an ABN? <a href="/lodge/abn-application">Register an ABN here</a></p>
                                        </>
                                    }
                                </Col>
                            </FormGroup>
                        </fieldset>
                    </CardBody>
                </Card>
            </Form>
            <br /><br /><br /><br />
        </Container>
        <SimpleFooter />
    </>
    );
}

export default BNRegistrationDetails;