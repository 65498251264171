import React, { useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Progress,
} from 'reactstrap';

import FlowNavbar from 'components/Navbars/FlowNavbar';
import SimpleFooter from 'components/Footers/SimpleFooter.js';


const SelectPlan = (props) => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const abnOnly = urlSearchParams.get('abnOnly') ? urlSearchParams.get('abnOnly') === 'true' : localStorage.getItem('abnOnly') === 'true';

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const submitForm = () => {
    props.history.push('/checkout');
  }

  const selectABNOnly = () => {
    localStorage.setItem('planId', 4);
    submitForm();
  };

  const selectMicroBusiness = () => {
    localStorage.setItem('planId', 1);

    // For now, redirect to Stripe subscription page
    window.location.href = `${process.env.REACT_APP_STRIPE_PAYMENT_LINK_MICROBUSINESS}?prefilled_email=${localStorage.getItem('email')}`;
  };

  const selectScaleUp = () => {
    localStorage.setItem('planId', 2);

    // For now, redirect to Stripe subscription page    
    window.location.href = `${process.env.REACT_APP_STRIPE_PAYMENT_LINK_SCALEUP}?prefilled_email=${localStorage.getItem('email')}`;
  };

  const selectLiftOff = () => {
    localStorage.setItem('planId', 3);

    // For now, redirect to Stripe subscription page
    window.location.href = `${process.env.REACT_APP_STRIPE_PAYMENT_LINK_LIFTOFF}?prefilled_email=${localStorage.getItem('email')}`;
  };

  useEffect(() => {
    // Record conversion in Google Ads
    window.gtag('event', 'conversion', { 'send_to': 'AW-312837668/OexRCPGxzaADEKSMlpUB' }); // Begin checkout conversion action
  });

  return (
    <>
      <FlowNavbar />
      <Container>
        <br />
        <h1>New ABN Application</h1>
        <div className="progress-wrapper">
          <div className="progress-info">
            <div className="progress-label">
              <span>Step 4 of 5: Select Plan</span>
            </div>
            <div className="progress-percentage">
              <span>80%</span>
            </div>
          </div>
          <Progress max="100" value="80" />
        </div>
        <br />
        <Container>
          <Row className="text-center justify-content-center">
            <Col lg="10">
              <h2 className="display-3">Start Your Business Right</h2>
              <p className="lead mb-md">
                Combine your ABN with a Business Transaction Account and get free business tax returns.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg="12">
              <Row className="row-grid">
                {abnOnly
                  ? <Col lg="4">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <h3 className="display-4">$15<span style={{ fontSize: '16px' }}><br /></span></h3>

                        <h6 className="text-primary text-uppercase">
                          ABN Only

                        </h6>
                        <p className="description mt-3">
                          Selecting this option means you will have to record your transactions and file your taxes manually
                        </p>
                        <ul>
                          <li>Manual bank reconciliation</li>
                          <li>Manual business tax return</li>
                          <li>Manual BAS filing</li>
                        </ul>
                        <Button
                          className="mt-4"
                          color="success"
                          onClick={e => selectABNOnly()}
                        >
                          Select
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                  :
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <h3 className="display-4">$29<span style={{ fontSize: '16px' }}>per month</span></h3>

                        <h6 className="text-primary text-uppercase">
                          Sole Trader
                        </h6>
                        <p className="description mt-3">
                          The Sole Trader plan includes a business account
                          and everything you need to get your first customers
                        </p>
                        <ul>
                          <li><span className="text-success">Free ABN</span></li>
                          <li><span className="text-success">Free</span> Transaction account</li>
                          <li><span className="text-success">Free</span> Digital business card</li>
                          <li>Automatic bank reconciliation</li>
                          <li><span className="text-success">Free</span> Annual tax return filing</li>
                        </ul>
                        <Button
                          className="mt-4"
                          color="success"
                          onClick={e => selectMicroBusiness()}
                        >
                          Select
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                }
                {abnOnly
                  ? <Col lg="4">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <h3 className="display-4">$29<span style={{ fontSize: '16px' }}>per month</span></h3>

                        <h6 className="text-primary text-uppercase">
                          Sole Trader
                        </h6>
                        <p className="description mt-3">
                          The Sole Trader plan includes a business account
                          and everything you need to get your first customers
                        </p>
                        <ul>
                          <li><span className="text-success">Free ABN</span></li>
                          <li><span className="text-success">Free</span> Transaction account</li>
                          <li><span className="text-success">Free</span> Digital business card</li>
                          <li>Automatic bank reconciliation</li>
                          <li><span className="text-success">Free</span> Annual tax return filing</li>
                        </ul>
                        <Button
                          className="mt-4"
                          color="success"
                          onClick={e => selectMicroBusiness()}
                        >
                          Select
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                  :
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <h3 className="display-4">$49<span style={{ fontSize: '16px' }}>per month</span></h3>
                        <h6 className="text-success text-uppercase">
                          Sole Trader GST
                        </h6>
                        <p className="description mt-3">
                          The Sole Trader GST plan is for businesses that also
                          need to register for GST
                        </p>
                        <ul>
                          <li><span className="text-success">Free ABN</span></li>
                          <li><span className="text-success">Free</span> Transaction account</li>
                          <li><span className="text-success">Free</span> Digital business card</li>
                          <li>Automatic bank reconciliation</li>
                          <li><span className="text-success">Free</span> Annual tax return filing</li>
                          <li><span className="text-success">Free</span> Free GST registration &amp; BAS filing</li>
                        </ul>
                        <Button
                          className="mt-4"
                          color="success"
                          onClick={e => selectScaleUp()}
                        >
                          Select
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                }
                {abnOnly
                  ? <Col lg="4">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <h3 className="display-4">$49<span style={{ fontSize: '16px' }}>per month</span></h3>
                        <h6 className="text-success text-uppercase">
                          Sole Trader GST
                        </h6>
                        <p className="description mt-3">
                          The Sole Trader GST plan is for businesses that also
                          need to register for GST
                        </p>
                        <ul>
                          <li><span className="text-success">Free ABN</span></li>
                          <li><span className="text-success">Free</span> Transaction account</li>
                          <li><span className="text-success">Free</span> Digital business card</li>
                          <li>Automatic bank reconciliation</li>
                          <li><span className="text-success">Free</span> Annual tax return filing</li>
                          <li><span className="text-success">Free</span> Free GST registration &amp; BAS filing</li>
                        </ul>
                        <Button
                          className="mt-4"
                          color="success"
                          onClick={e => selectScaleUp()}
                        >
                          Select
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                  :
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <h3 className="display-4">$79<span style={{ fontSize: '16px' }}>per month</span></h3>
                        <h6 className="text-warning text-uppercase">
                          Company Accounting
                        </h6>
                        <p className="description mt-3">
                          With the Company Accounting plan, accountedfor helps you
                          transition from sole trader to company director
                        </p>
                        <ul>
                          <li><span className="text-success">Free ABN</span></li>
                          <li><span className="text-success">Free</span> Transaction account</li>
                          <li><span className="text-success">Free</span> Digital business card</li>
                          <li>Automatic bank reconciliation</li>
                          <li><span className="text-success">Free</span> Annual tax return filing</li>
                          <li><span className="text-success">Free</span> Quarterly BAS filing</li>
                          <li>Automatic payroll, tax and super</li>
                        </ul>
                        <br />
                        <Button
                          className="mt-4"
                          color="success"
                          onClick={e => selectLiftOff()}
                        >
                          Select
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                }
              </Row>
            </Col>
          </Row>
        </Container>
      </Container>
      <br />
      <br />
      <SimpleFooter />
    </>
  );
};

export default SelectPlan;