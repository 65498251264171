import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Container } from "reactstrap";

import { getAuthorization, getSessionEmail } from "webauthn/session";
import { getPasskeyJWTAsync } from "../webauthn/securityStepUp";

// core components
import MerchantNavbar from "components/Navbars/MerchantNavbar";
import AdminFooter from "components/Footers/AdminFooter";
import Sidebar from "components/Sidebar/Sidebar";
import Header from "components/Headers/Header";

import routes from "merchantLayoutRoutes";

const MerchantLayout = (props) => {
  const principalLegalEntity = JSON.parse(
    localStorage.getItem("principalLegalEntity")
  );

  const [profile, setProfile] = useState({});
  const [features, setFeatures] = useState({});

  useEffect(() => {
    (async () => {
      const authorization = await getAuthorization();

      // HACK: Create user record association with confirmed email. This shouldn't need to be called every time, but it does
      // because some (what appears to be phones) will end the call early before redirecting to the Dashboard after signup confirmation.
      // So we need to re-confirm it every time they hit the Dashboard.
      //
      // TODO: fix this (replace AWS Cognito...!!!)
      const confirmSignupResponse = await fetch(
        `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/confirm-signup`,
        {
          headers: {
            Authorization: authorization,
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            email: getSessionEmail(),
          }),
        }
      );
      await confirmSignupResponse.json();

      const featureFlagsResponse = await fetch(
        `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/feature-flags`,
        {
          headers: {
            Authorization: await getAuthorization(),
          },
        }
      );
      const featureFlagsJSON = await featureFlagsResponse.json();
      const featureFlags = featureFlagsJSON.features;

      setFeatures(featureFlags);

      if (
        featureFlags.showLegalEntityDropdownSelector.enabled &&
        principalLegalEntity.agent_legal_entity?.customer_uuid
      ) {
        // XXX: while this is being tested (i.e. feature flagged) we are risk accepting expired `passkeyJWTs` until it disrupts production testing
        const response = await fetch(
          `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/business-profile`,
          {
            headers: {
              Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
              "x-principal-legal-entity-uuid":
                principalLegalEntity.legal_entity_uuid,
            },
          }
        );

        const json = await response.json();
        setProfile(json);
      } else {
        const response = await fetch(
          `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/business-profile`,
          {
            headers: {
              Authorization: authorization,
            },
          }
        );

        const json = await response.json();
        setProfile(json);
      }

      await fetch(
        `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/abn-application/link`,
        {
          headers: {
            Authorization: authorization,
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            abn_application_uuid: localStorage.getItem("abnApplicationId"),
          }),
        }
      );

      const companyLinkResult = await fetch(
        `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/company-application/link`,
        {
          headers: {
            Authorization: authorization,
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            company_uuid: localStorage.getItem("companyUUID"),
          }),
        }
      );

      if (companyLinkResult.ok) {
        localStorage.removeItem("companyUUID");
      }
    })();
  }, [setProfile]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/dashboard") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={() => prop.component(profile, features)}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <Sidebar
        {...props}
        businessProfile={profile}
        features={features}
        profile={profile}
        routes={routes}
        logo={{
          innerLink: "/dashboard/index",
          imgSrc: undefined,
          imgAlt: "...",
        }}
      />
      <div className="main-content">
        <MerchantNavbar
          {...props}
          features={features}
          businessProfile={profile}
        />
        <Header businessProfile={profile} />
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/dashboard/index" />
        </Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default MerchantLayout;
