import CustomerLiveInvoice from 'platform/customer/invoice/LiveInvoice';


const routes = [
    {
        path: "/invoice/:uuid",
        name: "Quote",
        icon: "ni ni-tv-2 text-primary",
        component: () => <CustomerLiveInvoice />,
        layout: "/customer",
    },
];

export default routes;