import React from 'react';
import { Page, Image, Text, View, Document, StyleSheet } from '@react-pdf/renderer';


const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
    },
    pageWrapper: {
        flexDirection: 'column',
        width: '100%'
    },
    headerSection: {
        flexDirection: 'row',
        //flex: 1
        marginBottom: 50
    },
    taxInvoiceSection: {
        margin: 10,
        padding: 10,
        flexGrow: 100,
    },
    lineItemsSection: {
        flex: 2, padding: 15
    },
    businessSectionWrapper: {
        fontSize: 14,
        marginTop: 50,
        marginLeft: 10,
        marginRight: 10,
        marginBottom: 10,
        padding: 10,
        flexGrow: 1,
    },
    sectionGroup: {
        marginTop: 40,
    },
    businessSection: {
        flexDirection: 'row',
    },
    businessSectionLeft: {
        flexGrow: 5,
    },
    businessSectionRight: {
        flexGrow: 1,
    },
    textRight: {
        textAlign: 'right',
    },
    footer: {
        flex: 1,
        padding: 15
    },
});

const InvoicePreview = (props) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.pageWrapper}>
                <View style={styles.headerSection}>
                    <View style={styles.taxInvoiceSection}>
                        {props.data.logo ? <Image src={props.data.logo} style={{ maxWidth: 125, maxHeight: 125, marginLeft: 20, marginTop: 20 }} /> : undefined}
                        {props.data.logo
                            ? <Text style={{ marginTop: 20, marginLeft: 20, fontSize: 20 }}>TAX INVOICE</Text>
                            : <Text style={{ marginTop: 100, marginLeft: 40, fontSize: 20 }}>TAX INVOICE</Text>
                        }
                        {props.data.logo
                            ? <Text style={{ marginLeft: 20, marginTop: 10, fontSize: 12 }}>To: {} {props.data.firstName} {props.data.lastName}</Text>
                            : <Text style={{ marginLeft: 40, marginTop: 10, fontSize: 12 }}>To: {props.data.firstName} {props.data.lastName}</Text>
                        }
                    </View>
                    <View style={styles.businessSectionWrapper}>
                        <Text style={{ fontSize: 16 }}>{props.data.businessName}</Text>
                        <Text>{props.data.businessAddressLine1}</Text>
                        <Text>{props.data.businessAddressLine2}</Text>
                        <View style={styles.sectionGroup}>
                            <View style={styles.businessSection}>
                                <View style={styles.businessSectionLeft}>
                                    <Text>ABN:</Text>
                                </View>
                                <View style={styles.businessSectionRight}>
                                    <Text style={styles.textRight}>   {props.data.abn}</Text>
                                </View>
                            </View>
                            <View style={styles.businessSection}>
                                <View style={styles.businessSectionLeft}>
                                    <Text>Invoice Number:</Text>
                                </View>
                                <View style={styles.businessSectionRight}>
                                    <Text style={{ textAlign: 'right', /*border: '1px solid red'*/ }}>{props.data.invoiceNumber}</Text>
                                </View>
                            </View>
                            <View style={styles.businessSection}>
                                <View style={styles.businessSectionLeft}>
                                    <Text>Invoice Date:</Text>
                                </View>
                                <View style={styles.businessSectionRight}>
                                    <Text>{props.data.invoiceDate}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>{/* end header section*/}
                <View style={styles.lineItemsSection}>
                    <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                        <Text style={{ flex: 3, fontSize: 14 }}>Description</Text>
                        <Text style={{ flex: 1, textAlign: 'right', paddingRight: 20, fontSize: 14 }}>Quantity</Text>
                        <Text style={{ flex: 1, textAlign: 'right', fontSize: 14 }}>Unit Price</Text>
                        <Text style={{ flex: 1, textAlign: 'right', fontSize: 14 }}>GST</Text>
                        <Text style={{ flex: 1, textAlign: 'right', fontSize: 14 }}>Amount</Text>
                    </View>
                    <View style={{ borderTop: '1px solid #000000', height: 2, width: '100%', marginTop: 5, marginBottom: 10 }}>
                    </View>
                    <View>
                        {props.data.lineItems.map((item, index) => {
                            return (
                                <View key={index} style={{ flexDirection: 'row', marginBottom: 5, /*border: '1 solid red'*/ }}>
                                    <Text style={{ flex: 3, fontSize: 14 }}>{item.description}</Text>
                                    <Text style={{ flex: 1, textAlign: 'right', paddingRight: 20, fontSize: 14 }}>{item.quantity}</Text>
                                    <Text style={{ flex: 1, textAlign: 'right', fontSize: 14 }}>{item.unit_price}</Text>
                                    <Text style={{ flex: 1, textAlign: 'right', fontSize: 14 }}>{item.tax_rate ? parseFloat(item.tax_rate).toFixed(0) : '0'}%</Text>
                                    <Text style={{ flex: 1, textAlign: 'right', fontSize: 14 }}>${(item.quantity * item.unit_price).toFixed(2)}</Text>
                                </View>
                            )
                        })}
                    </View>
                    <View style={{ flexDirection: 'row', height: 100 }}>
                    </View>
                    <View style={{ borderTop: '1px solid #000', height: 2, width: '100%', marginTop: 5, marginBottom: 10 }}>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={{ flex: 3, fontSize: 14 }}></Text>
                        <Text style={{ flex: 1, textAlign: 'right', paddingRight: 20, fontSize: 14 }}></Text>
                        <Text style={{ flex: 1, textAlign: 'right', fontSize: 14 }}></Text>
                        <Text style={{ flex: 1, textAlign: 'right', fontSize: 14 }}>Subtotal</Text>
                        <Text style={{ flex: 1, textAlign: 'right', fontSize: 14 }}>{props.data.subtotal}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 5 }}>
                        <Text style={{ flex: 3, fontSize: 14 }}></Text>
                        <Text style={{ flex: 1, textAlign: 'right', paddingRight: 20, fontSize: 14 }}></Text>
                        <Text style={{ flex: 1, textAlign: 'right', fontSize: 14 }}></Text>
                        <Text style={{ flex: 1, textAlign: 'right', fontSize: 14 }}>Total GST</Text>
                        <Text style={{ flex: 1, textAlign: 'right', fontSize: 14 }}>{props.data.totalGST}</Text>
                    </View>
                    <View style={{ flexDirection: 'row', marginTop: 5 }}>
                        <Text style={{ flex: 3, fontSize: 14 }}></Text>
                        <Text style={{ flex: 1, textAlign: 'right', paddingRight: 20, fontSize: 14 }}></Text>
                        <Text style={{ flex: 1, textAlign: 'right', fontSize: 14 }}></Text>
                        <Text style={{ flex: 1, textAlign: 'right', fontSize: 14, borderTop: '1px solid #000', marginTop: 5, paddingTop: 5 }}>Total Due</Text>
                        <Text style={{ flex: 1, textAlign: 'right', fontSize: 14, borderTop: '1px solid #000', marginTop: 5, paddingTop: 5 }}>{props.data.totalDue}</Text>
                    </View>
                </View>{/* end line items section */}
                <View>
                    <Text style={{ fontSize: 14 }}>{props.data.notes}</Text>
                </View>
                <View style={styles.footer}>
                    <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                        <Text style={{ flex: 3, fontSize: 14 }}>Payment advice</Text>
                    </View>
                    <View style={{ borderTop: '1px solid #000000', height: 2, width: '100%', marginTop: 5, marginBottom: 10 }}>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={{ flex: 3, fontSize: 14 }}>Account Name</Text>
                        <Text style={{ flex: 1, textAlign: 'right', fontSize: 14 }}>{props.data.accountName}</Text>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={{ flex: 3, fontSize: 14 }}>BSB</Text>
                        <Text style={{ flex: 1, textAlign: 'right', fontSize: 14 }}>{props.data.accountBSB}</Text>
                    </View>
                    <View style={{ flexDirection: 'row' }}>
                        <Text style={{ flex: 3, fontSize: 14 }}>Account Number</Text>
                        <Text style={{ flex: 1, textAlign: 'right', fontSize: 14 }}>{props.data.accountNumber}</Text>
                    </View>
                    {
                        props.data.paymentDueDate
                            ? <View style={{ flexDirection: 'row', marginTop: 10 }}>
                                <Text style={{ flex: 3, fontSize: 14 }}>Payment Due Date</Text>
                                <Text style={{ flex: 1, textAlign: 'right', fontSize: 14 }}>{props.data.paymentDueDate}</Text>
                            </View>
                            : undefined
                    }
                </View>{/* end footer section */}
            </View>
        </Page>
    </Document>
);

export { InvoicePreview };