import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Container,
    Col,
    Card,
    CardTitle,
    CardBody,
    CardFooter,
    Button,
    Badge,
    Form,
    FormGroup,
    FormFeedback,
    Label,
    Input,
    Spinner,
    Progress,
} from 'reactstrap';
import { FaArrowRight, FaCheck, FaTimes } from 'react-icons/fa';

import Select from 'react-select';
import countryList from 'react-select-country-list';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { AutofillDisabledInput } from 'views/components/autofillDisabledInput';

import FlowNavbar from 'components/Navbars/FlowNavbar';
import SimpleFooter from 'components/Footers/SimpleFooter.js';


const ABNApplication = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);

    const [submissionAttempted, setSubmissionAttempted] = useState(false);
    const [error, setError] = useState(false);

    const urlSearchParams = new URLSearchParams(window.location.search);
    const abnOnly = urlSearchParams.get('abnOnly') || true;
    localStorage.setItem('abnOnly', abnOnly);

    useEffect(() => {
        // XXX: this is really a bug not a fix...
        localStorage.removeItem('businessNameApplicationId');
    });

    // ABN Entitlement
    const [entitlementActivityLocation, setEntitlementActivityLocation] = useState('Australia');
    const [entitlementReason, setEntitlementReason] = useState('startingBusiness');

    // Personal details
    const [firstName, setFirstName] = useState(localStorage.getItem('firstName'));
    const [middleName, setMiddleName] = useState(localStorage.getItem('middleName'));
    const [lastName, setLastName] = useState(localStorage.getItem('lastName'));
    const [email, setEmail] = useState(localStorage.getItem('email'));
    const [phone, setPhone] = useState(localStorage.getItem('phone'));

    const [birthDateDay, setBirthDateDay] = useState('');
    const [birthDateMonth, setBirthDateMonth] = useState(0);
    const [birthDateYear, setBirthDateYear] = useState('');

    const [taxFileNumber, setTaxFileNumber] = useState('');

    const [australianTaxResident, setAustralianTaxResident] = useState(false);

    // Optional business name
    const [businessName, setBusinessName] = useState('');
    const [businessNameSearchAttempted, setBusinessNameSearchAttempted] = useState(false);
    const [businessNameLoading, setBusinessNameLoading] = useState(false);
    const [businessNameAvailable, setBusinessNameAvailable] = useState(false);

    const [registrationPeriod, setRegistrationPeriod] = useState(0);

    const [birthState, setBirthState] = useState('NSW');
    const [birthCity, setBirthCity] = useState('');
    const [birthCountry, setBirthCountry] = useState({ "value": "AU", "label": "Australia" });

    // Business location
    const [autocompletedAddress, setAutocompletedAddress] = useState(null);
    const [geocodedAddress, setGeocodedAddress] = useState(null);

    const [businessActivitiesMatchesHomeAddress, setBusinessActivitiesMatchesHomeAddress] = useState(true);
    const [serviceAddressMatchesHomeAddress, setServiceAddressMatchesHomeAddress] = useState(true);

    // Business activity details
    const now = new Date();
    const [businessActiveDateDay, setBusinessActiveDateDay] = useState(now.getDate().toString());
    const [businessActiveDateMonth, setBusinessActiveDateMonth] = useState(now.getMonth());
    const [businessActiveDateYear, setBusinessActiveDateYear] = useState(now.getFullYear().toString());
    const [mainBusinessActivity, setMainBusinessActivity] = useState('');

    // Declarations and Terms
    const [notDisqualified, setNotDisqualified] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);

    const formInvalid = entitlementActivityLocation !== 'Australia'
        || !australianTaxResident
        || !taxFileNumber
        || (registrationPeriod !== 0 && !businessNameAvailable)
        || !businessActivitiesMatchesHomeAddress
        || !serviceAddressMatchesHomeAddress
        || !notDisqualified
        || !termsAccepted;

    const handleAutocompletedAddressChange = async (address) => {
        setAutocompletedAddress(address);
        if (address) {
            const result = await geocodeByPlaceId(address.value.place_id);
            setGeocodedAddress(result);
        }
    };

    const searchName = async () => {

        if (businessName === '') {
            alert('You must enter a business name to search. Registering a business name is optional.');
            return;
        }

        setBusinessNameSearchAttempted(true);
        setBusinessNameLoading(true);

        // Record this as a conversion in Google Ads
        window.gtag('event', 'conversion', { 'send_to': 'AW-312837668/pXbCCKCz3JsDEKSMlpUB' }); // 'Search Name' conversion action

        const result = await fetch(`${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/name-search?name=${encodeURIComponent(businessName)}&email=${email}&phone=${phone}`);
        const resultJSON = await result.json();

        if (resultJSON.status === 'failed') {
            setBusinessNameSearchAttempted(false);
            alert('There was an error checking for that name, please try again');
        } else if (resultJSON.result.code !== 'Unavailable') {
            localStorage.setItem('nameSearchResultId', resultJSON.nameSearchResultId);
            setBusinessNameAvailable(true);
            setRegistrationPeriod(1);
        } else {
            setBusinessNameAvailable(false);
        }

        setBusinessNameLoading(false);
    };

    const submitForm = async () => {
        setSubmissionAttempted(true);

        if (!formInvalid) {

            setLoading(true);

            const options = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    entitlement_reason: entitlementReason,
                    first_name: firstName,
                    middle_name: middleName,
                    last_name: lastName,
                    email: email,
                    phone: phone,
                    birth_date: `${birthDateYear}-${parseInt(birthDateMonth) + 1}-${birthDateDay}`,
                    tax_file_number: taxFileNumber,
                    home_address: {
                        autocomplete_result: autocompletedAddress,
                        geocoded_result: geocodedAddress,
                    },
                    business_active_date: `${businessActiveDateYear}-${parseInt(businessActiveDateMonth) + 1}-${businessActiveDateDay}`,
                    main_business_activity: mainBusinessActivity,
                }),
            };
            const result = await fetch(`${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/abn-application`, options);
            const resultJSON = await result.json();

            if (resultJSON.status === 'ok') {
                const abnApplicationId = resultJSON.application_id;
                localStorage.setItem('abnApplicationId', abnApplicationId);

                localStorage.setItem('registrationPeriod', registrationPeriod);

                if (registrationPeriod !== 0) {
                    const options = {
                        method: 'post',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            term: registrationPeriod,
                            first_name: firstName,
                            middle_name: middleName,
                            last_name: lastName,
                            email: email,
                            phone: phone,
                            birth_date: `${birthDateYear}-${parseInt(birthDateMonth) + 1}-${birthDateDay}`,
                            birth_country: birthCountry,
                            birth_state: birthState,
                            birth_city: birthCity,
                            home_address: {
                                autocomplete_result: autocompletedAddress,
                                geocoded_result: geocodedAddress,
                            },
                            abn_application_id: abnApplicationId,
                            name_search_result_id: localStorage.getItem('nameSearchResultId'),
                        }),
                    };
                    const result = await fetch(`${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/business-name/application`, options);
                    const resultJSON = await result.json();

                    if (resultJSON.status === 'ok') {
                        localStorage.setItem('businessNameApplicationId', resultJSON.application_id);
                        //history.push('/lodge/business-name-registration/review'); // XXX: fix this
                    } else {
                        setError(true);
                    }

                }

                history.push('/lodge/abn-application/review');
            } else {
                setError(true);
            }

            setLoading(false);
        }
    };

    return (
        <>
            <FlowNavbar />
            <Container>
                <br />
                <h1>New ABN Application</h1>
                <div className="progress-wrapper">
                    <div className="progress-info">
                        <div className="progress-label">
                            <span>Step 2 of 5: Registration details</span>
                        </div>
                        <div className="progress-percentage">
                            <span>40%</span>
                        </div>
                    </div>
                    <Progress max="100" value="40" />
                </div>
                <br />
                <Form onSubmit={(e) => { e.preventDefault(); submitForm() }}>
                    <Card className="text-left shadow border-1 mb-5">
                        <CardTitle className="pt-3 pl-3">
                            ABN Registration Details
                        </CardTitle>
                        <CardBody className="p-4">
                            <h3>ABN Entitlement</h3>
                            <br />
                            <br />
                            <FormGroup tag="fieldset" row>
                                <Label sm={4} className="text-left">
                                    Where will your business activities be carried out
                                </Label>
                                <Col sm={6}>

                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="radio"
                                                name="entitlementActivityLocation"
                                                checked={entitlementActivityLocation === 'Australia'}
                                                onChange={() => setEntitlementActivityLocation('Australia')}
                                            />
                                            Australia
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="radio"
                                                name="entitlementActivityLocation"
                                                checked={entitlementActivityLocation === 'Overseas'}
                                                onChange={() => setEntitlementActivityLocation('Overseas')}
                                                invalid={entitlementActivityLocation === 'Overseas'}
                                            />
                                            Overseas
                                            <FormFeedback>Currently only Australian businesses are eligble</FormFeedback>
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </FormGroup>
                            <br />
                            <FormGroup tag="fieldset" row>
                                <Label as="legend" sm={4} className="text-left">
                                    Why do you need an ABN
                                </Label>
                                <Col sm={6}>
                                    <Input
                                        type="select"
                                        onChange={(e) => setEntitlementReason(e.target.value)}
                                        value={entitlementReason}
                                    >
                                        <option value="startingBusiness">Starting or running a business</option>
                                        <option value="rentingResidentialProperty">Renting or leasing out a residential property on a regular or continuous basis</option>
                                        <option value="interestInProperty">A licence, lease or other grant of an interest in property on a regular or continuous basis</option>
                                        <option value="oneOffNonCommercialTransactions">One-off commercial transactions not done in the course of a business for a profit or gain</option>
                                        <option value="labourerApprenticeTradeAssistant">Labourer, Apprentice or Trade Assistant</option>
                                    </Input>
                                </Col>
                            </FormGroup>
                            <br />
                            <hr />
                            <h3>Personal Details</h3>
                            <br />
                            <br />
                            <FormGroup tag="fieldset" row>

                                <Label sm={4} className="text-left">
                                    Full name
                                </Label>
                                <Col xs={4} sm={2}>
                                    <Input
                                        type="text"
                                        placeholder="First name"
                                        value={firstName}
                                        onChange={(e) => { localStorage.setItem('firstName', e.target.value); setFirstName(e.target.value) }}
                                        valid={firstName !== ''}
                                        invalid={submissionAttempted && !firstName}
                                    />
                                </Col>
                                <Col xs={4} sm={2}>
                                    <Input
                                        type="text"
                                        placeholder="Middle name"
                                        value={middleName}
                                        onChange={(e) => { localStorage.setItem('middleName', e.target.value); setMiddleName(e.target.value) }}
                                        valid={middleName !== ''}
                                        invalid={submissionAttempted && !middleName}
                                    />
                                </Col>
                                <Col xs={4} sm={2}>
                                    <Input
                                        type="text"
                                        placeholder="Last name"
                                        value={lastName}
                                        onChange={(e) => { localStorage.setItem('lastName', e.target.value); setLastName(e.target.value) }}
                                        valid={lastName !== ''}
                                        invalid={submissionAttempted && !lastName}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup tag="fieldset" row>
                                <Label sm={4} className="text-left">
                                    Email
                                </Label>
                                <Col sm={6}>
                                    <Input
                                        type="text"
                                        placeholder="you@yourbusiness.com.au"
                                        value={email}
                                        onChange={(e) => { localStorage.setItem('email', e.target.value); setEmail(e.target.value)}}
                                        valid={email !== ''}
                                        invalid={submissionAttempted && !email}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup tag="fieldset" row>
                                <Label sm={4} className="text-left">
                                    Phone number
                                </Label>
                                <Col sm={6}>
                                    <Input
                                        type="text"
                                        placeholder="04xx xxx xxx"
                                        value={phone}
                                        valid={phone !== ''}
                                        onChange={(e) => { localStorage.setItem('phone', e.target.value);setPhone(e.target.value)}}
                                        invalid={submissionAttempted && !phone}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup tag="fieldset" row>
                                <Label sm={4} className="text-left">
                                    Date of birth
                                </Label>
                                <Col sm={2}>
                                    <Input
                                        type="text"
                                        placeholder="Day"
                                        value={birthDateDay}
                                        onChange={(e) => setBirthDateDay(e.target.value)}
                                        invalid={submissionAttempted && birthDateDay === ''}
                                    />

                                </Col>
                                <Col sm={2}>
                                    <Input
                                        type="select"
                                        onChange={(e) => setBirthDateMonth(e.target.value)}
                                        value={birthDateMonth}
                                    >
                                        <option value="0">January</option>
                                        <option value="1">February</option>
                                        <option value="2">March</option>
                                        <option value="3">April</option>
                                        <option value="4">May</option>
                                        <option value="5">June</option>
                                        <option value="6">July</option>
                                        <option value="7">August</option>
                                        <option value="8">September</option>
                                        <option value="9">October</option>
                                        <option value="10">November</option>
                                        <option value="11">December</option>

                                    </Input>
                                </Col>
                                <Col sm={2}>
                                    <Input
                                        type="text"
                                        placeholder="Year"
                                        value={birthDateYear}
                                        onChange={(e) => setBirthDateYear(e.target.value)}
                                        invalid={submissionAttempted && birthDateYear === ''}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup tag="fieldset" row>
                                <Label sm={4} className="text-left">
                                    Tax File Number (TFN)
                                </Label>
                                <Col sm={6}>
                                    <Input
                                        type="text"
                                        placeholder="TFN"
                                        value={taxFileNumber}
                                        onChange={(e) => setTaxFileNumber(e.target.value)}
                                        valid={taxFileNumber !== ''}
                                        invalid={submissionAttempted && taxFileNumber === ''}
                                    />
                                    <FormFeedback>You must provide your Tax File Number to proceed</FormFeedback>
                                </Col>
                            </FormGroup>

                            <FormGroup tag="fieldset" row>
                                <Label sm={4} className="text-left">
                                    Declaration
                                </Label>
                                <Col sm={6}>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                checked={australianTaxResident}
                                                onChange={() => setAustralianTaxResident(!australianTaxResident)}
                                                invalid={submissionAttempted && !australianTaxResident}
                                                name="australianTaxResident"
                                                id="australianTaxResident"
                                            />
                                            I am an Australian resident for tax purposes
                                            <FormFeedback>You must be an Australian resident for tax purposes to use this form</FormFeedback>
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </FormGroup>
                            <hr />
                            <h3>Business Name</h3>
                            <br />
                            <br />
                            <FormGroup tag="fieldset" row>
                                <Label sm={4} className="text-left">
                                    Business Name Search
                                </Label>
                                <Col sm={5}>
                                    <Input
                                        type="text"
                                        placeholder="Type to search"
                                        onChange={e => setBusinessName(e.target.value)}
                                    />
                                    <br />
                                    <Button
                                        type="submit"
                                        color="success"
                                        onClick={(e) => { e.preventDefault() ; searchName() }}
                                        disabled={businessNameLoading}
                                    >
                                        {businessNameLoading
                                            ? <Spinner animation="border" variant="light" size="sm" />
                                            : null
                                        } Search
                                    </Button>
                                    <br />
                                    <br />
                                    <p className="text-muted">
                                        If you conduct business under a name other
                                        than {firstName && lastName ? <><strong>{firstName} {lastName}</strong></> : 'your name'} it is a
                                        requirement to register a business name.
                                    </p>
                                </Col>
                                <Col sm={2}>
                                    {businessNameSearchAttempted && !businessNameLoading
                                        ? businessNameAvailable
                                            ? <h3><Badge color="success">Available <FaCheck /></Badge></h3>
                                            : <h3><Badge color="danger">Unavailable <FaTimes /></Badge></h3>
                                        : null
                                    }
                                </Col>
                            </FormGroup>
                            <FormGroup tag="fieldset" row>
                                <Label sm={4} className="text-left">
                                    Registration Period
                                </Label>
                                <Col sm={6}>

                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="radio"
                                                name="businessNameregistrationPeriod"
                                                checked={registrationPeriod === 0}
                                                onChange={() => setRegistrationPeriod(0)}
                                            />
                                            Don't register a business name
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="radio"
                                                name="businessNameregistrationPeriod"
                                                checked={registrationPeriod === 1}
                                                onChange={() => setRegistrationPeriod(1)}
                                            />
                                            1 Year Registration - $65.00
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="radio"
                                                name="businessNameregistrationPeriod"
                                                checked={registrationPeriod === 3}
                                                onChange={() => setRegistrationPeriod(3)}
                                            />
                                            3 Years Registration - $180.00
                                        </Label>
                                    </FormGroup>


                                </Col>
                            </FormGroup>
                            {registrationPeriod !== 0
                                ? <>
                                    <FormGroup tag="fieldset" row>
                                        <Label sm={4} className="text-left">
                                            Country of Birth
                                        </Label>
                                        <Col sm={6}>
                                            <Select
                                                components={{ Input: AutofillDisabledInput }}
                                                options={countryList().getData()}
                                                value={birthCountry}
                                                onChange={value => setBirthCountry(value)}
                                            />
                                        </Col>
                                    </FormGroup>
                                    {birthCountry.value === 'AU'
                                        ? <>
                                            <FormGroup tag="fieldset" row>
                                                <Label sm={4} className="text-left">
                                                    State of Birth
                                                </Label>
                                                <Col sm={6}>
                                                    <Input
                                                        type="select"
                                                        onChange={(e) => setBirthState(e.target.value)}
                                                        value={birthState}
                                                    >
                                                        <option value="NSW">New South Wales</option>
                                                        <option value="ACT">Australian Capital Territory</option>
                                                        <option value="NT">Northern Territory</option>
                                                        <option value="QLD">Queensland</option>
                                                        <option value="SA">South Australia</option>
                                                        <option value="TAS">Tasmania</option>
                                                        <option value="VIC">Victoria</option>
                                                        <option value="WA">Western Australia</option>
                                                    </Input>
                                                </Col>
                                            </FormGroup>
                                            <FormGroup tag="fieldset" row>
                                                <Label sm={4} className="text-left">
                                                    City/Town of Birth
                                                </Label>
                                                <Col sm={6}>
                                                    <Input
                                                        type="text"
                                                        placeholder="City/Town of Birth"
                                                        value={birthCity}
                                                        valid={birthCity !== ''}
                                                        onChange={(e) => setBirthCity(e.target.value)}
                                                        invalid={submissionAttempted && birthCity === ''}
                                                    />
                                                </Col>
                                            </FormGroup>
                                        </>
                                        : undefined}

                                </>
                                : undefined
                            }
                            <hr />
                            <h3>Business Location</h3>
                            <br />
                            <br />
                            <FormGroup tag="fieldset" row>
                                <Label sm={4} className="text-left">
                                    Home address
                                </Label>
                                <Col sm={6}>
                                    <GooglePlacesAutocomplete
                                        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                                        autocompletionRequest={{
                                            componentRestrictions: {
                                                country: ['au'],
                                            },
                                            types: ['address'],
                                        }}
                                        selectProps={{
                                            components: { Input: AutofillDisabledInput },
                                            isClearable: true,
                                            value: autocompletedAddress,
                                            onChange: handleAutocompletedAddressChange,
                                            placeholder: 'Start typing address',

                                        }}
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup tag="fieldset" row>
                                <Label sm={4} className="text-left">
                                    Is your home address where you will you mainly conduct business?
                                </Label>
                                <Col sm={6}>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="radio"
                                                name="businessActivitiesMatchesHomeAddress"
                                                checked={businessActivitiesMatchesHomeAddress}
                                                onChange={() => setBusinessActivitiesMatchesHomeAddress(true)}
                                            />
                                            Yes
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="radio"
                                                name="businessActivitiesMatchesHomeAddress"
                                                checked={!businessActivitiesMatchesHomeAddress}
                                                onChange={() => setBusinessActivitiesMatchesHomeAddress(false)}
                                                invalid={!businessActivitiesMatchesHomeAddress}
                                            />
                                            No
                                            <FormFeedback>To select a different business address please contact support on Messenger</FormFeedback>
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </FormGroup>
                            <FormGroup tag="fieldset" row>
                                <Label sm={4} className="text-left">
                                    Is your home address your address for service of documents?
                                </Label>
                                <Col sm={6}>

                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="radio"
                                                name="serviceAddressMatchesHomeAddress"
                                                checked={serviceAddressMatchesHomeAddress}
                                                onChange={() => setServiceAddressMatchesHomeAddress(true)}
                                            />
                                            Yes
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="radio"
                                                name="serviceAddressMatchesHomeAddress"
                                                checked={!serviceAddressMatchesHomeAddress}
                                                onChange={() => setServiceAddressMatchesHomeAddress(false)}
                                                invalid={!serviceAddressMatchesHomeAddress}
                                            />
                                            No
                                            <FormFeedback>To select a different service address please contact support on Messenger</FormFeedback>
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </FormGroup>
                            <hr />
                            <h3>Business Activity Details</h3>
                            <br />
                            <br />
                            <FormGroup tag="fieldset" row>
                                <Label sm={4} className="text-left">
                                    Business Active Date
                                </Label>
                                <Col sm={2}>
                                    <Input
                                        type="text"
                                        placeholder="Day"
                                        value={businessActiveDateDay}
                                        onChange={(e) => setBusinessActiveDateDay(e.target.value)}
                                    />
                                </Col>
                                <Col sm={2}>
                                    <Input
                                        type="select"
                                        onChange={(e) => setBusinessActiveDateMonth(e.target.value)}
                                        value={businessActiveDateMonth}
                                    >
                                        <option value="0">January</option>
                                        <option value="1">February</option>
                                        <option value="2">March</option>
                                        <option value="3">April</option>
                                        <option value="4">May</option>
                                        <option value="5">June</option>
                                        <option value="6">July</option>
                                        <option value="7">August</option>
                                        <option value="8">September</option>
                                        <option value="9">October</option>
                                        <option value="10">November</option>
                                        <option value="11">December</option>

                                    </Input>
                                </Col>
                                <Col sm={2}>
                                    <Input
                                        type="text"
                                        placeholder="Year"
                                        value={businessActiveDateYear}
                                        onChange={(e) => setBusinessActiveDateYear(e.target.value)}
                                    />
                                </Col>
                            </FormGroup>

                            <FormGroup tag="fieldset" row>
                                <Label sm={4} className="text-left">
                                    Main Business Activity
                                </Label>
                                <Col sm={6}>
                                    <Input
                                        type="text"
                                        placeholder="Describe your business"
                                        value={mainBusinessActivity}
                                        onChange={(e) => setMainBusinessActivity(e.target.value)}
                                        valid={mainBusinessActivity !== ''}
                                        invalid={submissionAttempted && mainBusinessActivity === ''}
                                    />
                                </Col>
                            </FormGroup>
                            <hr />
                            <h3>Declarations and Terms Acceptance</h3>
                            <br />
                            <br />
                            <FormGroup tag="fieldset" row>
                                <Label sm={4} className="text-left">
                                    Declaration
                                </Label>
                                <Col sm={6}>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="notDisqualified"
                                                checked={notDisqualified}
                                                onChange={() => setNotDisqualified(!notDisqualified)}
                                                invalid={submissionAttempted && !notDisqualified}
                                            />
                                            I am not disqualified or otherwise ineligible to apply for an ABN
                                            <FormFeedback>You must not be disqualified or otherwise ineligible to apply for an ABN to use this form</FormFeedback>
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </FormGroup>
                            <FormGroup tag="fieldset" row>
                                <Label sm={4} className="text-left">
                                    Terms of Service<br /><a href="/terms" className="text-muted">See Terms of Service</a>
                                </Label>
                                <Col sm={6}>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                name="termsAccepted"
                                                checked={termsAccepted}
                                                onChange={() => setTermsAccepted(!termsAccepted)}
                                                invalid={submissionAttempted && !termsAccepted}
                                            />
                                            I accept the Terms of Service
                                            <FormFeedback>You must accept the terms of service to use this form</FormFeedback>
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </FormGroup>
                        </CardBody>
                        <CardFooter className="text-right">
                            {error ? 'An error ocurred, please contact support via Messenger ' : ''}
                            {submissionAttempted && formInvalid ? ' Please correct errors above ' : ''}
                            <Button
                                type="submit"
                                color="success"
                                disabled={loading}
                            >
                                {loading
                                    ? <Spinner animation="border" variant="light" size="sm" />
                                    : null
                                } Continue <FaArrowRight />
                            </Button>
                        </CardFooter>
                    </Card>
                </Form>
            </Container>
            <SimpleFooter />
        </>
    );
};

export default ABNApplication;