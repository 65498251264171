import { Amplify, Auth } from 'aws-amplify';
import {
  encode as b64URLArrayBufferEncode,
  decode as b64URLArrayBufferDecode,
} from '@qix/base64url-arraybuffer';

import { getRandomString } from './utils';

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  },
});


export const webAuthnRegister = async ({ displayName, name, username }) => {
  const registrationPayload = {
    username,
    name,
    displayName,
  }

  const registrationResponse = await fetch(`${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/webauthn/register`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(registrationPayload),
  });
  const registrationJSON = await registrationResponse.json();

  if (registrationJSON.status === 'failed') {
    return registrationJSON;
  }

  const challenge = registrationJSON.credRequest.challenge;

  const publicKeyCredentialCreationOptions = {
    ...registrationJSON.credRequest,
    challenge: b64URLArrayBufferDecode(registrationJSON.credRequest.challenge),
    user: {
      ...registrationJSON.credRequest.user,
      id: b64URLArrayBufferDecode(registrationJSON.credRequest.user.id),
    },
  };

  let credentials;
  try {
    credentials = await navigator.credentials.create({ publicKey: publicKeyCredentialCreationOptions });
  } catch (e) {

    if (e.name === 'NotAllowedError') {
      /*
       * This is usually triggered:
       *  - when the user cancels the operation
       *  - the operation times out
       *  - the browser is not focused
       *  - etc. it's a bit of a catch all...
       */
      return {
        'status': 'failed',
        'message': 'Passkey creation was cancelled',
      }
    }

    return {
      'status': 'failed',
      'message': `Unable to create passkey ${username}: ${e}`,
    }
  }

  const jsonEncodedCredential = {
    ...credentials,
    rawId: b64URLArrayBufferEncode(credentials.rawId),
    response: {
      attestationObject: b64URLArrayBufferEncode(credentials.response.attestationObject),
      clientDataJSON: b64URLArrayBufferEncode(credentials.response.clientDataJSON),
    },
  };

  /*
   * Challenge Response
   */
  const challengeResponseResponse = await fetch(`${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/webauthn/response?challenge=${challenge}&username=${encodeURIComponent(username)}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(jsonEncodedCredential),
  });
  const challengeResponseJSON = await challengeResponseResponse.json();
  const credential = challengeResponseJSON.credential;

  // Add new passkey user to AWS Cognito
  await Auth.signUp({
    username,
    password: getRandomString(30),
    attributes: {
      'custom:publicKeyCred': btoa(JSON.stringify({
        id: credential.credId,
        publicKey: credential.publicKey,
      }))
    },
    autoSignIn: {
      enabled: true
    },
  });

  const result = challengeResponseJSON.status === 'ok'
    ? {
      'status': 'ok',
      'message': `User ${username} was enrolled successfully`,
    } : {
      'status': 'failed',
      'message': `Unable to enrol username ${username}: ${challengeResponseJSON.message}`,
    };

  return result;
}
