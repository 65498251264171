import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardFooter,
    Button,
    Progress,
    FormGroup,
    Input,
    Label,
    Spinner,
    FormFeedback,
    Tooltip,
} from 'reactstrap';
import { FaArrowRight } from 'react-icons/fa';

import { webAuthnRegister } from '../../../webauthn';

import FlowNavbar from 'components/Navbars/FlowNavbar';
import SimpleFooter from 'components/Footers/SimpleFooter.js';


const validAWSCognitoUsername = (username) => {
    /*
     * Validates according to this specification:
     * https://docs.aws.amazon.com/cognito-user-identity-pools/latest/APIReference/API_AdminCreateUser.html#API_AdminCreateUser_RequestSyntax
     * 
     * Update: no it doesnn't because as always, this is stupid! (Read, not cross browser and/or doesn't work! See https://stackoverflow.com/a/44179509/119765)
     * 
     * Read here to see what we went with: https://stackoverflow.com/a/9204568
     */


    if (username.length < 1 || username.length > 128) {
        alert('here');
        return false;
    }

    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(username)) {
        return true;
    } else {
        return false;
    }
}

const RegisterPasskey = () => {
    const history = useHistory();

    const [reported, setReported] = useState(false);

    // Stripe URL parameter for one-off checkouts
    const paymentIntent = new URLSearchParams(window.location.search).get('payment_intent');

    // Stripe URL parameter for subscriptions created
    const sessionId = new URLSearchParams(window.location.search).get('session_id');

    useEffect(() => {
        (async () => {

            if (!reported) {
                const options = {
                    method: 'post',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        payment_intent: paymentIntent,
                        session_id: sessionId,
                    }),
                };
                const result = await fetch(`${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/report-conversion-value`, options);
                const resultJSON = await result.json();

                if (paymentIntent) {
                    window.gtag('event', 'conversion', {
                        'send_to': 'AW-312837668/UbbFCJ7hpqEDEKSMlpUB',
                        'value': resultJSON.amount,
                        'currency': 'AUD',
                        'transaction_id': paymentIntent,
                    });
                } else if (sessionId) {
                    window.gtag('event', 'conversion', {
                        'send_to': 'AW-312837668/GcXFCILfx7sDEKSMlpUB',
                        'value': resultJSON.amount,
                        'currency': 'AUD',
                        'transaction_id': sessionId,
                    });
                }
                setReported(true);
            }
        })();
    }, [reported, setReported]);

    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [email, setEmail] = useState(localStorage.getItem('email'));

    const [tooltipOpen, setTooltipOpen] = useState(false);

    const registerUser = async () => {
        if (email === '') {
            setErrorMessage('Email is required');
        } else if (!validAWSCognitoUsername(email)) {
            setErrorMessage('Email address must be valid');
        } else {
            setLoading(true);
            try {
                const lowerCaseEmail = email.toLowerCase(); // Required for Passkey interoperability
                const result = await webAuthnRegister({ displayName: lowerCaseEmail, name: lowerCaseEmail, username: lowerCaseEmail });

                if (result.status === 'failed') {
                    setErrorMessage(result.message);
                } else {
                    setLoading(false);

                    // Required to prep verify page
                    localStorage.setItem('unverififedEmail', email);

                    // Redirect user to verification page
                    history.push('/verify');
                }

            } catch (e) {
                setErrorMessage(e.message);
            }
            setLoading(false);
        }
    }


    return (
        <>
            <FlowNavbar />
            <Container>
                <br />
                <h1>New Business Application</h1>
                <div className="progress-wrapper">
                    <div className="progress-info">
                        <div className="progress-label">
                            <span>Step 6 of 6: Register Passkey</span>
                        </div>
                        <div className="progress-percentage">
                            <span>100%</span>
                        </div>
                    </div>
                    <Progress max="100" value="100" />
                </div>
                <br />
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="12">
                            <Row className="row-grid">
                                <Col lg="12">
                                    <Card className="shadow border-0">
                                        <CardBody className="text-center py-5">
                                            <h3>Register an Account</h3>
                                            <br />
                                            <p className=" mb-md">
                                                To view your new business, register an account by creating a Passkey. <a href="#" id="passkeyHelp">What is a Passkey?</a>
                                                <Tooltip placement="top" isOpen={tooltipOpen} toggle={() => setTooltipOpen(!tooltipOpen)} target="passkeyHelp">
                                                    Passkeys are an innovative new technology to replace account passwords, and help reduce financial fraud.
                                                </Tooltip>
                                            </p>
                                            <FormGroup tag="fieldset" row>
                                                <Label sm={4} className="text-right">
                                                    Email
                                                </Label>
                                                <Col sm={6}>
                                                    <Input
                                                        type="text"
                                                        placeholder="you@yourbusiness.com.au"
                                                        value={email}
                                                        onChange={(e) => { localStorage.setItem('email', e.target.value); setEmail(e.target.value) }}
                                                        invalid={errorMessage ? true : false}
                                                    />
                                                    <FormFeedback>
                                                        {errorMessage === ''
                                                            ? null
                                                            : <p className="text-left text-danger">{errorMessage}</p>
                                                        }
                                                    </FormFeedback>
                                                </Col>
                                            </FormGroup>
                                            <br />
                                            <br />
                                        </CardBody>
                                        <CardFooter className="text-right">
                                            <Button
                                                type="submit"
                                                color="success"
                                                disabled={false}
                                                onClick={() => registerUser()}
                                            >
                                                {loading
                                                    ? <Spinner animation="border" variant="light" size="sm" />
                                                    : null
                                                } Create Passkey <FaArrowRight />
                                            </Button>
                                        </CardFooter>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <br />
            <br />
            <SimpleFooter />
        </>
    );
};

export default RegisterPasskey;