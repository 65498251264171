import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import { getPasskeyJWTAsync } from "../../../webauthn/securityStepUp";
import { getAuthorization } from "webauthn/session";

const AddCustomer = (props) => {
  const principalLegalEntity = JSON.parse(
    localStorage.getItem("principalLegalEntity")
  );

  const history = useHistory();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [businessName, setBusinessname] = useState("");
  const [notes, setNotes] = useState("");

  const [abrCustomer, setABRCustomer] = useState([]);

  const createCustomer = async () => {
    if (principalLegalEntity?.agent_legal_entity?.customer_uuid) {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/counterparty`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
              "x-principal-legal-entity-uuid":
                principalLegalEntity.legal_entity_uuid,
            },
            body: JSON.stringify({
              first_name: firstName,
              last_name: lastName,
              email: email,
              phone: phone,
              business_name: businessName,
              notes: notes,
            }),
          }
        );

        if (response.ok) {
          history.push("/dashboard/customers");
        } else if (response.status === 403) {
          history.push("/sign-in");
        }
      } catch (e) {
        alert(`An unknown error occurred. (Server said: ${e})`);
      }
    } else {
      try {
        const authorization = await getAuthorization();

        const response = await fetch(
          `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/contact/create`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: authorization,
            },
            body: JSON.stringify({
              owner_legal_entity_uuid:
                props.businessProfile.principal_legal_entity?.legal_entity_uuid,
              first_name: firstName,
              last_name: lastName,
              email: email,
              phone: phone,
              business_name: businessName,
              notes: notes,
            }),
          }
        );

        if (response.ok) {
          history.push("/dashboard/customers");
        }
      } catch (e) {
        alert(`An unknown error occurred. (Server said: ${e})`);
      }
    }
  };

  const loadABNLookupResults = async (inputValue, callback) => {
    //alert('abc')

    //alert(abrCustomer.value)
    //console.log(abrCustomer[0].value);
    console.log("wtf");
    //if (abrCustomer[0].value) {
    //  console.log('returning empty')
    //  callback([])
    // }

    console.log("called");
    const authorization = await getAuthorization();

    const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/counterparty/search`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: authorization,
        },
        body: JSON.stringify({
          name: "bunnings group",
        }),
      }
    );

    //console.log(response);

    if (response.ok) {
      const json = await response.json();
      //console.log(json);

      const listResult = json.results.map((result) => {
        return { label: result.name, value: result.abn };
      });

      callback(listResult);
    }

    //callback([{ label: "Abc Pty Ltd", value: "aaaaabbbb1111" }]);
  };

  const triggerABRPullByABN = async (newCustomer) => {
    /*if (abrCustomer[0]?.value) {
      alert("boom");
    }*/
    if (!abrCustomer[0]?.value && newCustomer) {
      //alert("boom");

      // pull the ABR entity here & now. Log it to the authenticated user

      const abnLol = abrCustomer[0]?.value;

      const authorization = await getAuthorization();

      const response = await fetch(
        `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/counterparty/abn-search`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: authorization,
          },
          body: JSON.stringify({
            abn: abnLol,
          }),
        }
      );

      if (response.ok) {
        const json = await response.json();
        console.log(json);
      }
    }
  };

  return (
    <>
      {/* Page content */}
      <Container className="mt--5" fluid>
        <Row>
          <Col className="mb-5 mb-xl-0" xl="12">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Add Customer</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form>
                  <h6 className="heading-small text-muted mb-4">
                    Customer information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            First name
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                            id="input-first-name"
                            placeholder="First name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Last name
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                            id="input-last-name"
                            placeholder="Last name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email"
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-phone"
                          >
                            Phone
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-phone"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            placeholder="Phone"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Business name (optional)
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-business-name"
                          >
                            Business Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-business-name"
                            value={businessName}
                            onChange={(e) => setBusinessname(e.target.value)}
                            placeholder="Business Name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">Notes</h6>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <label>Notes</label>
                      <Input
                        className="form-control-alternative"
                        placeholder="Customer notes"
                        rows="4"
                        value={notes}
                        onChange={(e) => setNotes(e.target.value)}
                        type="textarea"
                      />
                    </FormGroup>
                  </div>
                </Form>
              </CardBody>
              <CardFooter>
                <Row className="align-items-center">
                  <Col xs="8"></Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="success"
                      onClick={() => createCustomer()}
                      size="sm"
                    >
                      Save Customer
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddCustomer;
