import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from 'reactstrap';

import { Amplify, Auth } from 'aws-amplify';

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  },
});

const Register = () => {
  const history = useHistory();

  /*
   *  State variables
   */
  const [email, setEmail] = useState(localStorage.getItem('lastAttemptedEmail'));
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  /*
   *  Trigger password reset verification email
   */
  const resetPassword = async (email) => {
    setLoading(true);

    try {
      await Auth.forgotPassword(email);
      history.push('/reset-password-verify');

      // The AWS Amplify Hub will take over from here if successful
    } catch (e) {
      console.log(e.message)
      setErrorMessage(`Error: ${e.message}`)

      setLoading(false);
    }
  }
  return (
    <>
      <DemoNavbar />
      <main>
        <section className="section section-shaped section-lg">
          <div className="shape shape-style-1 bg-gradient-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="pt-lg-7">
            <Row className="justify-content-center">
              <Col lg="5">
                <Card className="bg-secondary shadow border-0">
                  <CardHeader className="bg-white">
                    <div className="text-muted text-center mb-3">
                      <small>Reset your password by verifying your email address</small>
                    </div>
                    <div className="text-center">

                      <i className="fas fa-lock fa-3x" />
                    </div>
                  </CardHeader>
                  <CardBody className="px-lg-5 pb-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Please note, you will be required to recieve a verification code</small>
                    </div>
                    {errorMessage === ''
                      ? null
                      : <p className="text-center text-danger">{errorMessage}</p>
                    }
                    <Form role="form">
                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email"
                            type="email"
                            onChange={e => { localStorage.setItem('lastAttemptedEmail', e.target.value); setEmail(e.target.value) }}
                            value={email}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                      </FormGroup>
                      <div className="text-center">
                        <Button
                          className="mt-4"
                          color="primary"
                          type="button"
                          onClick={() => resetPassword(email)}
                          disabled={loading ? true : false}
                        >
                          {loading
                            ? <>
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-circle-notch fa-spin" />
                              </span>
                              <span className="btn-inner--text">
                                Magic At Work
                              </span>
                            </>
                            : <>
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-lock" />
                              </span>
                              <span className="btn-inner--text">
                                Send Email
                              </span>
                            </>
                          }
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </main >
      <SimpleFooter />
    </>
  );
}

export default Register;
