import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Container,
    Row,
    Col,
    Card,
    Button,
} from 'reactstrap';
import { FaArrowRight } from 'react-icons/fa';

// core components
import FlowNavbar from 'components/Navbars/FlowNavbar';
import SimpleFooter from 'components/Footers/SimpleFooter.js';


const StructureSelection = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    });

    return (
        <>
            <FlowNavbar />

            <Container className="container-lg">
                <Row>
                    <Col className="mb-5 mb-md-0" md="6">
                        <Card className="card-lift--hover shadow border-0 p-4">
                            <Link to="/lodge/abn-application">
                                <h3>Select ABN</h3>
                                <p>
                                    <div className="text-center">
                                        <div style={{ fontSize: '100px' }}><i className="ni ni-circle-08" /></div>
                                        <h2>
                                            Register ABN
                                        </h2>
                                        <h3 className="text-muted">
                                            &nbsp;
                                        </h3>

                                    </div>

                                    <ul>
                                        <li>Suitable for Sole Traders</li>
                                        <li>Ideal to start up a new business</li>
                                        <li>Begin sending invoices immediatetly</li>
                                        <li>Lowest cost</li>
                                        <li>Easier to manage</li>
                                    </ul>

                                    <br />


                                </p>
                                <p className="text-right">
                                    <Button
                                        type="submit"
                                        color="success"
                                    >
                                        Select ABN <FaArrowRight />
                                    </Button>
                                </p>
                            </Link>
                        </Card>
                    </Col>
                    <Col className="mb-5 mb-lg-0" md="6">
                        <Card className="card-lift--hover shadow border-0 p-4">
                            <Link to="/lodge/company-registration/members">

                                <h3>Select Company</h3>
                                <p>
                                    <div className="text-center">
                                        <div style={{ fontSize: '100px' }}><i className="ni ni-building" /></div>
                                        <h2>
                                            Register Company - $795
                                        </h2>
                                        <h3 className="text-muted">
                                            &nbsp;
                                        </h3>
                                    </div>

                                    <ul>
                                        <li>Suitable for multiple business partners</li>
                                        <li>Limited liability</li>
                                        <li>Usually required for larger or established businesses</li>
                                        <li>Higher cost</li>
                                        <li>More complex to manage</li>
                                    </ul>

                                    <br />


                                </p>
                                <p className="text-right">
                                    <Button
                                        type="submit"
                                        color="success"
                                    >
                                        Select Company <FaArrowRight />
                                    </Button>
                                </p>
                            </Link>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <br /><br />


            <SimpleFooter />
        </>
    );
};

export default StructureSelection;