import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import { Amplify } from "aws-amplify";

import { webAuthnRegister } from "../../webauthn";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
    userPoolWebClientId:
      process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  },
});

const Register = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const username = urlParams.get("username");

  const history = useHistory();

  /*
   *  State variables
   */
  const [email, setEmail] = useState(username ?? "");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  /*
   *  Registration action
   */
  const registerUser = async ({ email }) => {
    if (email === "") {
      setErrorMessage("Email is required");
    } else {
      setLoading(true);

      try {
        const lowerCaseEmail = email.toLowerCase(); // Required for Passkey interoperability
        const result = await webAuthnRegister({
          displayName: lowerCaseEmail,
          name: lowerCaseEmail,
          username: lowerCaseEmail,
        });
        if (result.status === "failed") {
          setErrorMessage(result.message);
        } else {
          setLoading(false);

          // Required to prep verify page
          localStorage.setItem("unverififedEmail", email);

          // Redirect user to verification page
          history.push("/verify");
        }
      } catch (e) {
        setErrorMessage(e.message);
      }

      setLoading(false);
    }
  };
  return (
    <>
      <DemoNavbar />
      <main>
        <section className="section section-shaped section-lg">
          <div className="shape shape-style-1 bg-gradient-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="pt-lg-7">
            <Row className="justify-content-center">
              <Col lg="5">
                <Card className="bg-secondary shadow border-0">
                  <CardHeader className="bg-white">
                    <div className="text-muted text-center mb-3">
                      <small>Register for accountedfor using Passkey</small>
                    </div>
                    <div className="text-center">
                      <i className="fas fa-lock fa-3x" />
                    </div>
                  </CardHeader>
                  <CardBody className="px-lg-5 pb-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Please note, no password is required</small>
                    </div>
                    {errorMessage === "" ? null : (
                      <p className="text-center text-danger">{errorMessage}</p>
                    )}
                    <Form
                      role="form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        registerUser({
                          email: email,
                        });
                      }}
                    >
                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email"
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup></FormGroup>
                      <div className="text-center">
                        <Button
                          className="mt-4"
                          color="primary"
                          type="button"
                          onClick={() =>
                            registerUser({
                              email: email,
                            })
                          }
                          disabled={loading ? true : false}
                        >
                          {loading ? (
                            <>
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-circle-notch fa-spin" />
                              </span>
                              <span className="btn-inner--text">
                                Magic At Work
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-lock" />
                              </span>
                              <span className="btn-inner--text">
                                Create account
                              </span>
                            </>
                          )}
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <SimpleFooter />
    </>
  );
};

export default Register;
