import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
import { getPasskeyJWTAsync } from "../../../webauthn/securityStepUp";
import { getAuthorization } from "webauthn/session";

const ListDocuments = () => {
  const principalLegalEntity = JSON.parse(
    localStorage.getItem("principalLegalEntity")
  );

  const [documents, setDocuments] = useState();

  const [documentDownloadable, setDocumentDownloadable] = useState();
  const [documentDownloadLink, setDocumentDownloadLink] = useState();

  useEffect(() => {
    (async () => {
      if (principalLegalEntity?.agent_legal_entity?.customer_uuid) {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/document`,
            {
              headers: {
                Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
                "x-principal-legal-entity-uuid":
                  principalLegalEntity?.legal_entity_uuid,
              },
            }
          );

          if (response.ok) {
            const json = await response.json();
            setDocuments(
              json.documents.filter((item) => item.category_type === "document")
            );
          }
        } catch (e) {
          alert(`An unknown error occurred. (Server said: ${e})`);
        }
      } else {
        const response = await fetch(
          `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/document`,
          {
            headers: {
              Authorization: await getAuthorization(),
            },
          }
        );
        const json = await response.json();
        setDocuments(
          json.documents.filter((item) => item.category_type === "document")
        );
      }
    })();
  }, [setDocuments]);

  const download = async (s3ObjectKey) => {
    const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/document-download`,
      {
        method: "post",
        headers: {
          Authorization: await getAuthorization(),
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          s3_object_key: s3ObjectKey,
        }),
      }
    );
    const json = await response.json();

    // XXX: Hack
    setDocumentDownloadable(s3ObjectKey);
    setDocumentDownloadLink(json.presigned_url);
  };

  return (
    <>
      {/* Page content */}
      <Container className="mt--5" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Documents</h3>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">Type</th>
                    <th scope="col">Notes</th>
                    <th scope="col">Created Date</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {documents?.map((document, index) => {
                    return (
                      <tr key={index}>
                        <th scope="row">{document.description}</th>
                        <td>{document.category_description}</td>
                        <td>
                          {DateTime.fromISO(document.created_at).toLocaleString(
                            DateTime.DATE_FULL
                          )}
                        </td>
                        <td className="text-center" width="200px">
                          <Button
                            color="success"
                            onClick={() => download(document.object_key)}
                            size="sm"
                            style={{
                              display:
                                documentDownloadable === document.object_key
                                  ? "none"
                                  : "block",
                            }}
                          >
                            Get Download Link
                          </Button>
                          <a
                            href={documentDownloadLink}
                            download
                            target="blank"
                            style={{
                              display:
                                documentDownloadable === document.object_key
                                  ? "block"
                                  : "none",
                            }}
                          >
                            Download File
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <CardFooter className="py-4"></CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ListDocuments;
