import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { DateTime } from 'luxon';
import {
    Card,
    CardHeader,
    CardFooter,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    Pagination,
    PaginationItem,
    PaginationLink,
    Table,
    Container,
    Row,
    Col,
    Button,
    Badge,
} from 'reactstrap';
import { getAuthorization } from 'webauthn/session';

const Schedule = (props) => {
    const history = useHistory();

    const [invoices, setInvoices] = useState([]);

    useEffect(() => {
        (async () => {
            const authorization = await getAuthorization();
            const response = await fetch(`${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/invoice`, {
                headers: {
                    Authorization: authorization,
                },
            })
            const json = await response.json()
            //setInvoices(json.invoices)
        })()
    }, [setInvoices])

    const mapInvoiceStatus = (status) => {
        const invoiceStatusMap = {
            'DRAFT': 'Draft',
            'SENT': 'Sent',
        };

        return invoiceStatusMap[status];
    }

    const mapInvoiceStatusIcon = (status) => {
        const invoiceStatusIconMap = {
            'DRAFT': 'bg-info',
            'SENT': 'bg-success',
        };

        return invoiceStatusIconMap[status];
    }

    return (
        <>
            {/* Page content */}
            <Container className="mt--5" fluid>
                {/* Table */}
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <CardHeader className="border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">Scheduled Appointments</h3>
                                    </Col>
                                    <Col className="text-right" xs="4">
                                        <Button
                                            color="success"
                                            onClick={() => history.push('/dashboard/scheduling/add')}
                                            size="sm"
                                        >
                                            Add Appointment
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <Table className="align-items-center table-flush" responsive>
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">Customer</th>
                                        <th scope="col">Appointment Time</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Confirmation Status</th>
                                        <th scope="col">Created Date</th>
                                        <th scope="col" />
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoices.length === 0
                                        ? <tr>
                                            <td colspan="6">
                                                <p className="text-center">
                                                    No appointments scheduled yet, select Add Appointment to schedule an appointment.
                                                </p>
                                            </td>
                                        </tr>
                                        : undefined}
                                    {invoices.map((invoice, index) => {
                                        return (
                                            <tr key={index}>
                                                <th scope="row">
                                                    {invoice.status === 'DRAFT'
                                                        ? <a href={`/dashboard/live-invoice/${invoice.uuid}`}>{invoice.invoice_serial}</a>
                                                        : invoice.invoice_serial}
                                                </th>
                                                <td>
                                                    ${parseFloat(invoice.invoice_value).toFixed(2)}
                                                </td>
                                                <td>
                                                    {invoice.business_name ? invoice.business_name : `${invoice.first_name} ${invoice.last_name}`}
                                                </td>
                                                <td>{DateTime.fromISO(invoice.created_at).toLocaleString(DateTime.DATETIME_MED)}</td>
                                                <td>
                                                    <Badge color="" className="badge-dot">
                                                        <i className={mapInvoiceStatusIcon(invoice.status)} />
                                                        {mapInvoiceStatus(invoice.status)}
                                                    </Badge>
                                                </td>
                                                <td className="text-right">
                                                    {invoice.status === 'DRAFT'
                                                        ? <UncontrolledDropdown>
                                                            <DropdownToggle
                                                                className="btn-icon-only text-light"
                                                                role="button"
                                                                size="sm"
                                                                color=""
                                                                onClick={(e) => e.preventDefault()}
                                                            >
                                                                <i className="fas fa-ellipsis-v" />
                                                            </DropdownToggle>
                                                            <DropdownMenu className="dropdown-menu-arrow" right>
                                                                <DropdownItem
                                                                    onClick={(e) => window.location = `/dashboard/live-invoice/${invoice.uuid}/edit`}
                                                                >
                                                                    Edit Invoice
                                                                </DropdownItem>
                                                            </DropdownMenu>
                                                        </UncontrolledDropdown>
                                                        : undefined}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </Table>
                            <CardFooter className="py-4">
                                <nav aria-label="...">
                                    <Pagination
                                        className="pagination justify-content-end mb-0"
                                        listClassName="justify-content-end mb-0"
                                    >
                                        <PaginationItem className="disabled">
                                            <PaginationLink
                                                href="#pablo"
                                                onClick={(e) => e.preventDefault()}
                                                tabIndex="-1"
                                            >
                                                <i className="fas fa-angle-left" />
                                                <span className="sr-only">Previous</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem className="active">
                                            <PaginationLink
                                                href="#pablo"
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                1
                                            </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem>
                                            <PaginationLink
                                                href="#pablo"
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                2 <span className="sr-only">(current)</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem>
                                            <PaginationLink
                                                href="#pablo"
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                3
                                            </PaginationLink>
                                        </PaginationItem>
                                        <PaginationItem>
                                            <PaginationLink
                                                href="#pablo"
                                                onClick={(e) => e.preventDefault()}
                                            >
                                                <i className="fas fa-angle-right" />
                                                <span className="sr-only">Next</span>
                                            </PaginationLink>
                                        </PaginationItem>
                                    </Pagination>
                                </nav>
                            </CardFooter>
                        </Card>
                    </div>
                </Row>

            </Container>
        </>
    );
};

export default Schedule;