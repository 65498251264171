import { jwtDecode } from "jwt-decode";
import {
  encode as b64URLArrayBufferEncode,
  decode as b64URLArrayBufferDecode,
} from "@qix/base64url-arraybuffer";

import { getSessionEmail } from "webauthn/session";

export const passkeyAssertion = async ({ email }) => {
  const loginPayload = {
    email,
  };

  const loginResponse = await fetch(
    `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/assert-request`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginPayload),
    }
  );
  const loginJSON = await loginResponse.json();

  if (loginJSON.status === "failed") {
    return loginJSON;
  }

  const challenge = loginJSON.assertRequest.challenge;

  let credential;

  if (loginJSON.assertRequest.allowCredentials[0].id) {
    const publicKey = {
      challenge: b64URLArrayBufferDecode(loginJSON.assertRequest.challenge),
      timeout: 1800000,
      rpId: process.env.REACT_APP_WEBAUTHN_RP_ID,
      allowCredentials: [
        ...loginJSON.assertRequest.allowCredentials.map((credential) => {
          return {
            id: b64URLArrayBufferDecode(credential.id),
            type: "public-key",
          };
        }),
      ],
    };

    credential = await navigator.credentials.get({ publicKey });
  } else {
    const publicKey = {
      challenge: b64URLArrayBufferDecode(loginJSON.assertRequest.challenge),
      timeout: 1800000,
      rpId: process.env.REACT_APP_WEBAUTHN_RP_ID,
    };

    credential = await navigator.credentials.get({ publicKey });
  }

  const jsonEncodedCredential = {
    rawId: b64URLArrayBufferEncode(credential.rawId),
    response: {
      clientDataJSON: b64URLArrayBufferEncode(
        credential.response.clientDataJSON
      ),
      authenticatorData: b64URLArrayBufferEncode(
        credential.response.authenticatorData
      ),
      signature: b64URLArrayBufferEncode(credential.response.signature),
      userHandle: b64URLArrayBufferEncode(credential.response.userHandle),
    },
  };

  /*
   * Challenge Response
   */
  const challengeResponseResponse = await fetch(
    `${
      process.env.REACT_APP_ACCOUNTEDFOR_API_URL
    }/passkey/assert-response?challenge=${challenge}&email=${encodeURIComponent(
      email
    )}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(jsonEncodedCredential),
    }
  );
  const challengeResponseJSON = await challengeResponseResponse.json();

  // Note: This JWT asserts a particular Customer has used a Passkey (with AWS Cognito, we can't actually guarantee this in every case)
  localStorage.setItem("passkeyJWT", challengeResponseJSON.passkeyJWT);

  const result =
    challengeResponseJSON.status === "ok"
      ? {
          status: "ok",
          message: `User ${email} was logged in successfully`,
        }
      : {
          status: "failed",
          message: `Unable to log in ${email}: ${challengeResponseJSON.message}`,
        };

  return result;
};

export const getPasskeyJWTAsync = async () => {
  const email = getSessionEmail();

  try {
    const jwt = jwtDecode(localStorage.getItem("passkeyJWT"));
    const currentTimestamp = Math.floor(Date.now() / 1000);
    const expired = currentTimestamp > jwt.exp;

    if (!expired) {
      return localStorage.getItem("passkeyJWT");
    } else if (email) {
      // Display webauthn prompt to user
      const response = await passkeyAssertion({ email });

      if (response.status === "ok") {
        return localStorage.getItem("passkeyJWT");
      } else {
        window.location.href = "/sign-in";
      }
    } else {
      window.location.href = "/sign-in";
    }
  } catch (e) {
    console.log(e);

    // Display webauthn prompt to user
    const response = await passkeyAssertion({ email });

    if (response.status === "ok") {
      return localStorage.getItem("passkeyJWT");
    } else {
      window.location.href = "/sign-in";
    }
  }
};
