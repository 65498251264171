import React from 'react';
import {
    Button,
    Card,
    CardBody,
    Modal,
    ModalHeader,
} from 'reactstrap';


const KYCModal = (props) => {
    const { isOpen, setIsOpen } = props;

    return (
        <Modal
            className="modal-dialog-centered"
            size="md"
            isOpen={isOpen}
            toggle={() => false}
        >
            <ModalHeader className="modal-header bg-secondary" toggle={() => setIsOpen(false)} close={<></>}>Open a Weel Bank Account</ModalHeader>
            <div className="modal-body p-0">
                <Card className="bg-secondary border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center">
                            Before you can start using accountedfor.com.au you will need to connect a Weel bank account.
                            <br />
                            <br />
                            Contact support to open and connect your Weel account. Please note that you will be required to
                            prove your identity to open a Weel account.
                        </div>
                        <br />
                        <div className="text-center">
                            <Button
                                className="my-4"
                                color="primary"
                                type="button"
                                onClick={() => window.location.href = 'tel:+611300201329'}
                            >
                                <i className="fa fa-phone mr-2" />Contact Support
                            </Button>
                            <div className="text-center">
                                <a href="/dashboard">Return to dashboard</a>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </Modal>
    );
}

export { KYCModal };