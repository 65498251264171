import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import routes from 'customerLayoutRoutes';


const CustomerLayout = () => {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/customer") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={() => prop.component()}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content" style={{ backgroundColor: '#eef2f6', overflow: 'hidden' }}>
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/customer/index" />
        </Switch>
      </div>
    </>
  );
};

export default CustomerLayout;