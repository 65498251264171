import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { DateTime } from 'luxon';
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col,
    Modal,
    Spinner,
} from 'reactstrap';
import AsyncSelect from 'react-select/async';
import { DatePicker } from 'reactstrap-date-picker';
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';

import {
    emptyLiveInvoiceUUID,
} from '../../../platform/customer/invoice/taxDocument';

import {
    getMerchantsCustomerList,
    createDraft,
    updateDraft,
    send,
    getBusinessProfile,
    getMerchantLogo,
    loadAsyncSelectMerchantsCustomerList,
    lineItemDescriptionChange,
    lineItemQuantityChange,
    lineItemUnitPriceChange,
    lineItemGSTChange,
    getInvoiceByUUID,
} from '../Invoice/invoice';

const LiveInvoiceDetail = () => {
    const history = useHistory();
    const params = useParams();

    const [testLoading, setTestLoading] = useState(false);
    const [saveDraftLoading, setSaveDraftLoading] = useState(false);
    const [sendQuoteLoading, setSendQuoteLoading] = useState(false);

    const [previewPDFModalIsOpen, setPreviewPDFModalIsOpen] = useState(false);
    const [previewEmailModalIsOpen, setPreviewEmailModalIsOpen] = useState(false);
    const [previewSMSModalIsOpen, setPreviewSMSModalIsOpen] = useState(false);

    const [profile, setProfile] = useState({});
    const [bankAccount, setBankAccount] = useState({});

    const [merchantLogo, setMerchantLogo] = useState();

    const [customerContacts, setCustomerContacts] = useState([]);

    const [selectedCustomer, setSelectedCustomer] = useState([]);
    const customerContactUUID = selectedCustomer[0]?.value;

    const [invoiceDate, setInvoiceDate] = useState((new Date()).toISOString());
    const [appointmentTime, setAppointmentTime] = useState('10:00');

    const [invoiceNumber, setInvoiceNumber] = useState('QUO-1038');
    const [invoiceLineItems, setInvoiceLineItems] = useState([{
        description: '',
        quantity: '',
        unit_price: '',
        tax_rate: '0',
    }]);
    const [invoiceNotes, setInvoiceNotes] = useState('');

    const [externalBSB, setExternalBSB] = useState('');
    const [externalAccountNumber, setExternalAccountNumber] = useState('');

    useEffect(() => {
        (async () => {
            const profile = await getBusinessProfile()
            setProfile(profile.customer_profile)
            setBankAccount(profile.bank_account)

            const logoURL = await getMerchantLogo();
            setMerchantLogo(logoURL);

            if (params.uuid !== 'add') {
                const invoice = await getInvoiceByUUID(params.uuid)

                if (invoice.customer_contact) {
                    setSelectedCustomer([invoice.customer_contact]);
                }
                setInvoiceDate(invoice.effective_date);
                setInvoiceNumber(invoice.invoice_serial);
                const preLoadedLineItems = [
                    ...invoice.line_items,
                    {
                        description: '',
                        quantity: '',
                        unit_price: '',
                        tax_rate: '0',
                    }
                ]
                setInvoiceLineItems(preLoadedLineItems);
                setInvoiceNotes(invoice.notes);
                setExternalBSB(invoice.external_bsb);
                setExternalAccountNumber(invoice.external_account_number);
            }

        })()
    }, [params.uuid, setInvoiceNumber, setInvoiceDate, setSelectedCustomer, setInvoiceLineItems])

    const fetchCustomerContacts = async () => {
        setCustomerContacts(await getMerchantsCustomerList());
    };

    // XXX: lol - TODO: unwind from needing this
    const data = {
        logo: merchantLogo,
        firstName: selectedCustomer?.[0]?.label,
        lastName: '',
        businessName: profile.business_name || `${profile.first_name} ${profile.last_name}`,
        abn: profile.abn,
        invoiceNumber: invoiceNumber,
        invoiceDate: DateTime.fromISO(invoiceDate).toLocaleString(DateTime.DATE_SHORT),
        lineItems: invoiceLineItems.slice(0, invoiceLineItems.length - 1),
        subtotal: `${invoiceLineItems.reduce((previous, current) => {
            return previous + (current.quantity * current.unit_price);
        }, 0).toFixed(2)}`,
        totalGST: `$${invoiceLineItems.reduce((previous, current) => {
            return previous + (current.quantity * current.unit_price * (parseFloat(current.tax_rate ?? 0) === 0 ? 0 : 1 / parseFloat(current.tax_rate)));
        }, 0).toFixed(2)}`,
        totalDue: `$${invoiceLineItems.reduce((previous, current) => {
            return previous + (current.quantity * current.unit_price * (parseFloat(current.tax_rate ?? 0) === 0 ? 1 : (parseFloat(current.tax_rate) + 1) / 10));
        }, 0).toFixed(2)}`,
        notes: invoiceNotes,
        accountName: profile.business_name,
        accountBSB: bankAccount?.bsb ? bankAccount?.bsb : externalBSB,
        accountNumber: bankAccount?.account_number ? bankAccount?.account_number : externalAccountNumber,
    };

    const sendTest = async () => {
        alert('Coming soon!');
    }

    const saveDraft = async () => {
        alert('Coming soon!');
    }

    const send = async () => {
        alert('Coming soon!');
    }

    return (
        <>
            <Modal
                className="modal-quote-email"
                isOpen={previewEmailModalIsOpen}
                toggle={() => setPreviewEmailModalIsOpen()}
                size="md"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        Email Preview
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setPreviewEmailModalIsOpen(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <h4>{profile.first_name} would like to schedule an appointment</h4>
                    <br />
                    <p>Dear {data.firstName ? data.firstName : '{Customer}'},</p>
                    <p>{profile.first_name} would like to schedule your appointment.</p>
                    <p>To view your quoappointmentte online, or request changes, click the link below:</p>
                    <p class="text-center">
                        <Button
                            color="success"
                            size="md"
                            active
                            aria-pressed="true"
                            href={`${window.origin}/customer/quote/${params.uuid}`}
                            onClick={(e) => { e.preventDefault(); alert('Coming soon!') }}
                        >
                            Accept Appointment
                        </Button>
                    </p>
                    <p>If you have any enquiries please don't hesistate to get in contact.</p>
                    <p>Regards,</p>
                    <p>{data.businessName}</p>
                </div>
                <div className="modal-footer">
                    <Button
                        color="primary"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setPreviewEmailModalIsOpen(false)}
                    >
                        Close
                    </Button>
                </div>
            </Modal>
            <Modal
                className="modal-quote-sms"
                isOpen={previewSMSModalIsOpen}
                toggle={() => setPreviewSMSModalIsOpen()}
                size="md"
            >
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                        SMS Preview
                    </h5>
                    <button
                        aria-label="Close"
                        className="close"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setPreviewSMSModalIsOpen(false)}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className="modal-body">
                    <p>Hi {data.firstName ? data.firstName : '{Customer}'}, {profile.first_name ? profile.first_name : '{Business}'} would like to schedule your appointment. To view it online and accept it, click here: <a onClick={(e) => { e.preventDefault() ; alert('Coming soon!')}} href={`${window.origin}/customer/invoice/${params.uuid === 'add' ? emptyLiveInvoiceUUID : params.uuid}`} style={{ lineBreak: 'loose' }}>{`${window.origin}/customer/appointment/${params.uuid === 'add' ? emptyLiveInvoiceUUID : params.uuid}`}</a> Thanks, {data.businessName ? data.businessName : '{Business}'}. Please note: you cannot reply to this message</p>
                </div>
                <div className="modal-footer">
                    <Button
                        color="primary"
                        data-dismiss="modal"
                        type="button"
                        onClick={() => setPreviewSMSModalIsOpen(false)}
                    >
                        Close
                    </Button>
                </div>
            </Modal>
            <Container className="mt--5" fluid>
                <Row>
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="6">
                                        <h3 className="mb-0">Add Appointment</h3>
                                    </Col>
                                    <Col className="text-right" xs="6">
                                        {/*<Button
                                            color="primary"
                                            onClick={() => setPreviewPDFModalIsOpen(true)}
                                            size="sm"
                                        >
                                            Preview PDF
                                        </Button>*/}
                                        <Button
                                            color="primary"
                                            onClick={() => setPreviewEmailModalIsOpen(true)}
                                            size="sm"
                                        >
                                            Preview Email
                                        </Button>
                                        <Button
                                            color="primary"
                                            onClick={() => setPreviewSMSModalIsOpen(true)}
                                            size="sm"
                                        >
                                            Preview SMS
                                        </Button>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form>
                                    <h6 className="heading-small text-muted mb-4">
                                        Appointment information
                                    </h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        Customer
                                                    </label>
                                                    <AsyncSelect
                                                        isMulti
                                                        loadOptions={async (inputValue, callback) => await loadAsyncSelectMerchantsCustomerList(inputValue, callback)}
                                                        defaultOptions={customerContacts}
                                                        onFocus={() => fetchCustomerContacts()}
                                                        onInputChange={(newValue) => newValue}
                                                        onChange={(selectedCustomer) => setSelectedCustomer(selectedCustomer)}
                                                        isOptionDisabled={() => selectedCustomer.length > 0}
                                                        noOptionsMessage={() => 'Start typing to search customers...'}
                                                        value={selectedCustomer}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col lg="6">
                                                {/*<FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-email"
                                                    >
                                                        Quote Number
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-invoice-number"
                                                        value={invoiceNumber}
                                                        onChange={e => setInvoiceNumber(e.target.value)}
                                                        type="text"
                                                    />
                                                </FormGroup>*/}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-first-name"
                                                    >
                                                        Appointment Date
                                                    </label>
                                                    <DatePicker value={invoiceDate} onChange={(value) => setInvoiceDate(value)} />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                    >
                                                        Appointment Time
                                                    </label>
                                                    <div>
                                                        <TimePicker
                                                            disableClock={true}
                                                            onChange={setAppointmentTime}
                                                            value={appointmentTime}
                                                        />
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr className="my-4" />
                                    <h6 className="heading-small text-muted mb-4">
                                        Line Items
                                    </h6>
                                    <div className="pl-lg-4">
                                        {invoiceLineItems && invoiceLineItems.map((lineItem, index) => {
                                            return (<Row key={index}>
                                                <Col md="5">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-address"
                                                        >
                                                            Description
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            placeholder="Describe your product or service"
                                                            value={lineItem.description}
                                                            type="text"
                                                            onChange={e => lineItemDescriptionChange(invoiceLineItems, setInvoiceLineItems, index, e.target.value)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="2">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-city"
                                                        >
                                                            Quantity
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            value={lineItem.quantity}
                                                            type="text"
                                                            onChange={e => lineItemQuantityChange(invoiceLineItems, setInvoiceLineItems, index, e.target.value)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="2">
                                                    <FormGroup>
                                                        <label
                                                            className="form-control-label"
                                                            htmlFor="input-city"
                                                        >
                                                            Unit Price
                                                        </label>
                                                        <Input
                                                            className="form-control-alternative"
                                                            value={lineItem.unit_price}
                                                            type="text"
                                                            onChange={e => lineItemUnitPriceChange(invoiceLineItems, setInvoiceLineItems, index, e.target.value)}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                <Col lg="1">
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-city"
                                                    >
                                                        GST
                                                    </label>
                                                    <div className="custom-control custom-checkbox mb-3">
                                                        <input
                                                            className="custom-control-input"
                                                            id={`checkbox-${index}`}
                                                            type="checkbox"
                                                            onChange={e => lineItemGSTChange(invoiceLineItems, setInvoiceLineItems, index)}
                                                            checked={parseFloat(lineItem.tax_rate) === 10 ? true : undefined}
                                                        />
                                                        <label className="custom-control-label" htmlFor={`checkbox-${index}`}>
                                                            10%
                                                        </label>
                                                    </div>
                                                </Col>
                                                <Col lg="2" className="text-center">
                                                    <label>
                                                        <b>Subtotal</b>
                                                    </label>
                                                    <p style={{ marginTop: '8px' }} className="text-right"><b>${(invoiceLineItems[index].quantity * invoiceLineItems[index].unit_price).toFixed(2)}</b></p>
                                                </Col>
                                            </Row>);
                                        })}
                                    </div>
                                    <hr className="my-4" />
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="7">
                                            </Col>
                                            <Col lg="3" className="text-right">
                                                <h6>Subtotal</h6>
                                            </Col>
                                            <Col lg="2" className="text-right">
                                                <h6>
                                                    ${invoiceLineItems.reduce((previous, current) => {
                                                        return previous + (current.quantity * current.unit_price);
                                                    }, 0).toFixed(2)}
                                                </h6>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="7">
                                            </Col>
                                            <Col lg="3" className="text-right">
                                                <h6>GST</h6>
                                            </Col>
                                            <Col lg="2" className="text-right">
                                                <h6>
                                                    ${invoiceLineItems.reduce((previous, current) => {
                                                        return previous + (current.quantity * current.unit_price * (parseFloat(current.tax_rate ?? 0) === 0 ? 0 : 1 / parseFloat(current.tax_rate)));
                                                    }, 0).toFixed(2)}
                                                </h6>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="7">
                                            </Col>
                                            <Col lg="3" className="text-right">
                                                <h6>Total</h6>
                                            </Col>
                                            <Col lg="2" className="text-right">
                                                <h5>
                                                    ${invoiceLineItems.reduce((previous, current) => {
                                                        return previous + (current.quantity * current.unit_price * (parseFloat(current.tax_rate ?? 0) === 0 ? 1 : (parseFloat(current.tax_rate) + 1) / 10));
                                                    }, 0).toFixed(2)}
                                                </h5>
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr className="my-4" />
                                    <h6 className="heading-small text-muted mb-4">Notes</h6>
                                    <div className="pl-lg-4">
                                        <FormGroup>
                                            <label>Appointment notes</label>
                                            <Input
                                                className="form-control-alternative"
                                                rows="4"
                                                type="textarea"
                                                value={invoiceNotes}
                                                onChange={(e) => setInvoiceNotes(e.target.value)}
                                            />
                                        </FormGroup>
                                    </div>
                                    {/*
                                    <hr className="my-4" />
                                    <h6 className="heading-small text-muted mb-4">Payment Instructions</h6>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col xs="6">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-bsb"
                                                    >
                                                        BSB
                                                    </label>
                                                    <Input
                                                        className="form-control-alternative"
                                                        id="input-bsb"
                                                        value={externalBSB}
                                                        onChange={e => setExternalBSB(e.target.value)}
                                                        type="text"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col xs="6">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="input-account-number"
                                                >
                                                    Account Number
                                                </label>
                                                <Input
                                                    className="form-control-alternative"
                                                    id="input-account-number"
                                                    value={externalAccountNumber}
                                                    onChange={e => setExternalAccountNumber(e.target.value)}
                                                    type="text"
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                                */}
                                    <hr className="my-4" />
                                    <h6 className="heading-small text-muted mb-4">Delivery Options</h6>
                                    <div className="pl-lg-4">
                                        <div className="custom-control custom-checkbox mb-3">
                                            <input
                                                className="custom-control-input"
                                                id="email-notification"
                                                type="checkbox"
                                                onChange={() => { }}
                                                checked={true}
                                                disabled={true}
                                            />
                                            <label className="custom-control-label" htmlFor="email-notification">
                                                Send by Email
                                            </label>
                                        </div>
                                    </div>
                                    <div className="pl-lg-4">
                                        <div className="custom-control custom-checkbox mb-3">
                                            <input
                                                className="custom-control-input"
                                                id="email-notification"
                                                type="checkbox"
                                                onChange={() => { }}
                                                checked={true}
                                                disabled={true}
                                            />
                                            <label className="custom-control-label" htmlFor="email-notification">
                                                Send by SMS
                                            </label>
                                        </div>
                                    </div>
                                </Form>
                            </CardBody>
                            <CardFooter>
                                <Row className="align-items-center">
                                    <Col xs="4">
                                        <Button
                                            color="primary"
                                            onClick={sendTest}
                                            size="md"
                                            disabled={testLoading}
                                        >
                                            {testLoading
                                                ? <Spinner animation="border" variant="light" size="sm" />
                                                : null
                                            } Send me a Test
                                        </Button>
                                    </Col>
                                    <Col className="text-right" xs="8">
                                        <Button
                                            color="info"
                                            onClick={saveDraft}
                                            size="md"
                                            disabled={saveDraftLoading}
                                        >
                                            {saveDraftLoading
                                                ? <Spinner animation="border" variant="light" size="sm" />
                                                : null
                                            } Save as Draft
                                        </Button>
                                        <Button
                                            color="success"
                                            onClick={send}
                                            size="md"
                                            disabled={sendQuoteLoading}
                                        >
                                            {sendQuoteLoading
                                                ? <Spinner animation="border" variant="light" size="sm" />
                                                : null
                                            } Send
                                        </Button>
                                    </Col>
                                </Row>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default LiveInvoiceDetail;
