import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import { Amplify, Auth } from "aws-amplify";

import {
  encode as b64URLArrayBufferEncode,
  decode as b64URLArrayBufferDecode,
} from "@qix/base64url-arraybuffer";

import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
    userPoolWebClientId:
      process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  },
});

const Login = () => {
  /*
   *  State variables
   */
  const [email, setEmail] = useState(
    localStorage.getItem("lastAttemptedEmail")
  );

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  /*
   *  Login action
   */
  const loginUser = async ({ username }) => {
    if (username === "") {
      setErrorMessage("Email cannot be empty");
    } else {
      setLoading(true);

      try {
        const user = await Auth.signIn({
          username: email,
        });

        const challenge = user.challengeParam.challenge;
        const credentialId = user.challengeParam.credId;
        const publicKeyCred = user.challengeParam.publicKeyCred;

        const additionalPasskeys = JSON.parse(
          atob(publicKeyCred)
        ).additionalPasskeys;

        let credential;
        if (additionalPasskeys) {
          // Get cred list now
          const additionalDecodedPasskeys = additionalPasskeys.map((key) => {
            return {
              id: b64URLArrayBufferDecode(key.id),
              type: "public-key",
              transports: ["ble", "nfc", "usb", "internal"],
            };
          });

          credential = await navigator.credentials.get({
            publicKey: {
              challenge: b64URLArrayBufferDecode(challenge),
              timeout: 1800000,
              rpId: process.env.REACT_APP_WEBAUTHN_RP_ID,
              userVerification: "preferred",
              allowCredentials: [
                {
                  id: b64URLArrayBufferDecode(credentialId),
                  type: "public-key",
                  transports: ["ble", "nfc", "usb", "internal"],
                },
                ...additionalDecodedPasskeys,
              ],
            },
          });
        } else if (credentialId) {
          credential = await navigator.credentials.get({
            publicKey: {
              challenge: b64URLArrayBufferDecode(challenge),
              timeout: 1800000,
              rpId: process.env.REACT_APP_WEBAUTHN_RP_ID,
              userVerification: "preferred",
              allowCredentials: [
                {
                  id: b64URLArrayBufferDecode(credentialId),
                  type: "public-key",
                  transports: ["ble", "nfc", "usb", "internal"],
                },
              ],
            },
          });
        } else {
          credential = await navigator.credentials.get({
            publicKey: {
              challenge: b64URLArrayBufferDecode(challenge),
              timeout: 1800000,
              rpId: process.env.REACT_APP_WEBAUTHN_RP_ID,
              userVerification: "preferred",
            },
          });
        }

        const challengeAnswer = {
          response: {
            clientDataJSON: b64URLArrayBufferEncode(
              credential.response.clientDataJSON
            ),
            authenticatorData: b64URLArrayBufferEncode(
              credential.response.authenticatorData
            ),
            signature: b64URLArrayBufferEncode(credential.response.signature),
            userHandle: b64URLArrayBufferEncode(credential.response.userHandle),
          },
        };

        await Auth.sendCustomChallengeAnswer(
          user,
          JSON.stringify(challengeAnswer)
        );

        setLoading(false);
      } catch (e) {
        if (e.code === "UserNotConfirmedException") {
          setLoading(false);
          window.location.href = "/verify";
        } else if (e.code === "UserLambdaValidationException") {
          setErrorMessage(
            "Your biometrics are not yet enrolled. Please sign in with a password instead."
          );
        } else {
          setErrorMessage(`Error: ${e}`);
        }
      }

      setLoading(false);
    }
  };
  return (
    <>
      <DemoNavbar />
      <main>
        <section className="section section-shaped section-lg">
          <div className="shape shape-style-1 bg-gradient-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="pt-lg-7">
            <Row className="justify-content-center">
              <Col lg="5">
                <Card className="bg-secondary shadow border-0">
                  <CardHeader className="bg-white">
                    <div className="text-muted text-center mb-3">
                      <small>Sign in to accountedfor using Passkey</small>
                    </div>
                    <div className="text-center">
                      <i className="fas fa-lock fa-3x" />
                    </div>
                  </CardHeader>
                  <CardBody className="px-lg-5 pb-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Please note, no password is required</small>
                    </div>
                    {errorMessage === "" ? null : (
                      <p className="text-center text-danger">{errorMessage}</p>
                    )}
                    <Form
                      role="form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        loginUser({ username: email });
                      }}
                    >
                      <FormGroup className="mb-3">
                        <InputGroup className="input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email address"
                            type="email"
                            onChange={(e) => {
                              localStorage.setItem(
                                "lastAttemptedEmail",
                                e.target.value
                              );
                              setEmail(e.target.value);
                            }}
                            value={email}
                          />
                        </InputGroup>
                      </FormGroup>
                      <div className="text-center">
                        <Button
                          className="my-4"
                          color="primary"
                          type="button"
                          onClick={() => loginUser({ username: email })}
                          disabled={loading ? true : false}
                        >
                          {loading ? (
                            <>
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-circle-notch fa-spin" />
                              </span>
                              <span className="btn-inner--text">
                                Magic At Work
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-lock" />
                              </span>
                              Sign in
                            </>
                          )}
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
                <Row className="mt-3">
                  <Col className="text-left" xs="6">
                    <a className="text-light" href="/register">
                      <small>Register new account</small>
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <SimpleFooter />
    </>
  );
};

export default Login;
