import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import {
    Container,
    Col,
    Form,
    FormGroup,
    Label,
    Input,
    Card,
    CardTitle,
    CardBody,
    CardFooter,
    Button,
    Progress,
} from 'reactstrap';
import { FaArrowRight } from 'react-icons/fa';

import FlowNavbar from 'components/Navbars/FlowNavbar';
import SimpleFooter from 'components/Footers/SimpleFooter.js';


const ABNReview = (props) => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const applicationId = urlSearchParams.get('uuid') || localStorage.getItem('abnApplicationId');

    const [abnApplication, setABNApplication] = useState();

    useEffect(() => {
        window.scrollTo(0, 0);

        const fetchABNApplication = async () => {

            const result = await fetch(`${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/abn-application/${applicationId}`);
            const resultJSON = await result.json();

            setABNApplication(resultJSON.application);
        };
        fetchABNApplication();
    }, [setABNApplication, applicationId]);

    const eligibilityResonMap = {
        'startingBusiness': 'Starting or running a business',
        'rentingResidentialProperty': 'renting or leasing out a residential property on a regular or continuous basis',
        'interestInProperty': 'A licence, lease or other grant of an interest in property on a regular or continuous basis',
        'oneOffNonCommercialTransactions': 'One-off commercial transactions not done in the course of a business for a profit or gain',
        'labourerApprenticeTradeAssistant': 'Labourer, Apprentice or Trade Assistant',
    };

    const submitForm = () => {
        props.history.push('/select-plan');
    };

    return (
        <>
            <FlowNavbar />
            <Container>
                <br />
                <h1>New ABN Application</h1>
                <div className="progress-wrapper">
                    <div className="progress-info">
                        <div className="progress-label">
                            <span>Step 3 of 5: Review application</span>
                        </div>
                        <div className="progress-percentage">
                            <span>60%</span>
                        </div>
                    </div>
                    <Progress max="100" value="60" />
                </div>
                <br />
                <Form onSubmit={(e) => { e.preventDefault(); submitForm() }}>
                    <Card className="shadow border-1 mb-5">
                        <CardTitle className="pt-3 pl-3">
                            Review ABN Registration Details
                        </CardTitle>
                        <CardBody className="p-4">
                            <h3>ABN Entitlement</h3>
                            <br />
                            <br />
                            <FormGroup tag="fieldset" row>
                                <Col xs={6}>
                                    <Label className="text-left">
                                        Where will your business activities be carried out:
                                    </Label>
                                </Col>
                                <Col xs={6}>
                                    Australia
                                </Col>
                            </FormGroup>
                            <FormGroup tag="fieldset" row>
                                <Col xs={6}>
                                    <Label className="text-left">
                                        Why do you need an ABN:
                                    </Label>
                                </Col>
                                <Col xs={6}>
                                    {abnApplication ? eligibilityResonMap[abnApplication.entitlement_reason] : ''}
                                </Col>
                            </FormGroup>
                            <br />
                            <hr />
                            <h3>Personal Details</h3>
                            <br />
                            <br />
                            <FormGroup tag="fieldset" row>
                                <Col xs={6}>
                                    <Label className="text-left">
                                        Full name:
                                    </Label>
                                </Col>
                                <Col xs={6}>
                                    {abnApplication ? `${abnApplication.first_name} ${abnApplication.last_name}` : ''}
                                </Col>
                            </FormGroup>
                            <FormGroup tag="fieldset" row>
                                <Col xs={6}>
                                    <Label className="text-left">
                                        Email:
                                    </Label>
                                </Col>
                                <Col xs={6}>
                                    {abnApplication ? abnApplication.email : ''}
                                </Col>
                            </FormGroup>
                            <FormGroup tag="fieldset" row>
                                <Col xs={6}>
                                    <Label className="text-left">
                                        Phone number:
                                    </Label>
                                </Col>
                                <Col xs={6}>
                                    {abnApplication ? abnApplication.phone : ''}
                                </Col>
                            </FormGroup>
                            <FormGroup tag="fieldset" row>
                                <Col xs={6}>
                                    <Label className="text-left">
                                        Date of birth:
                                    </Label>
                                </Col>
                                <Col xs={6}>
                                    {abnApplication ? DateTime.fromISO(abnApplication.birth_date).toLocaleString() : ''}
                                </Col>
                            </FormGroup>
                            <FormGroup tag="fieldset" row>
                                <Col xs={6}>
                                    <Label className="text-left">
                                        Tax File Number (TFN):
                                    </Label>
                                </Col>
                                <Col xs={6}>
                                    {abnApplication ? abnApplication.tax_file_number : ''}
                                </Col>
                            </FormGroup>
                            <FormGroup tag="fieldset" row>
                                <Col xs={6}>
                                    <Label className="text-left">
                                        Declaration
                                    </Label>
                                </Col>
                                <Col xs={6}>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                checked={true}
                                                disabled={true}
                                                name="formHorizontalRadios"
                                                id="formHorizontalRadios1"
                                            />
                                            I am an Australian resident for tax purposes
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </FormGroup>
                            <hr />
                            <h3>Business Location</h3>
                            <br />
                            <br />
                            <FormGroup tag="fieldset" row>
                                <Col xs={6}>
                                    <Label className="text-left">
                                        Home address:
                                    </Label>
                                </Col>
                                <Col xs={6}>
                                    {abnApplication ? abnApplication.home_address_text : ''}
                                </Col>
                            </FormGroup>

                            <FormGroup tag="fieldset" row>
                                <Col xs={6}>
                                    <Label className="text-left">
                                        Is your home address where you will<br />you mainly conduct business?
                                    </Label>
                                </Col>
                                <Col xs={6}>
                                    Yes
                                </Col>
                            </FormGroup>
                            <FormGroup tag="fieldset" row>
                                <Col xs={6}>
                                    <Label className="text-left">
                                        Is your home address your address<br />for service of documents?
                                    </Label>
                                </Col>
                                <Col xs={6}>
                                    Yes
                                </Col>
                            </FormGroup>
                            <hr />
                            <h3>Business Activity Details</h3>
                            <br />
                            <br />
                            <FormGroup tag="fieldset" row>
                                <Col xs={6}>
                                    <Label className="text-left">
                                        Business Active Date:
                                    </Label>
                                </Col>
                                <Col xs={6}>
                                    {abnApplication ? DateTime.fromISO(abnApplication.business_active_date).toLocaleString() : ''}
                                </Col>

                            </FormGroup>
                            <FormGroup tag="fieldset" row>
                                <Col xs={6}>
                                    <Label className="text-left">
                                        Main Business Activity:
                                    </Label>
                                </Col>
                                <Col xs={6}>
                                    {abnApplication ? abnApplication.main_business_activity : ''}
                                </Col>
                            </FormGroup>
                            <hr />
                            <h3>Declarations and Terms Acceptance</h3>
                            <br />
                            <br />
                            <FormGroup tag="fieldset" row>
                                <Col xs={6}>
                                    <Label className="text-left">
                                        Declaration
                                    </Label>
                                </Col>
                                <Col xs={6}>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                checked={true}
                                                disabled={true}
                                            />
                                            I am not disqualified or otherwise ineligible to apply for an ABN
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </FormGroup>
                            <FormGroup tag="fieldset" row>
                                <Col xs={6}>
                                    <Label className="text-left">
                                        Terms of Service<br /><a href="/terms" className="text-muted">See Terms of Service</a>
                                    </Label>
                                </Col>
                                <Col xs={6}>
                                    <FormGroup check>
                                        <Label check>
                                            <Input
                                                type="checkbox"
                                                checked={true}
                                                disabled={true}
                                            />
                                            I accept the Terms of Service
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </FormGroup>
                        </CardBody>
                        <CardFooter className="text-right">
                            <Button
                                type="submit"
                                color="success"
                                disabled={false}
                            >
                                Continue <FaArrowRight />
                            </Button>
                        </CardFooter>
                    </Card>
                </Form>
            </Container>
            <br />
            <br />
            <SimpleFooter />
        </>
    );
};

export default ABNReview;