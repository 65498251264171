import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from 'headroom.js';
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from 'reactstrap';


/*
 *  This hack is required for the submenu dropdowns to work on mobile
 *  and is only required because the Dashboard styles have been merged
 *  with the landing page styles (i.e. this override is in the Landing page styles
 *  already but is not being applied due to apparent Dashboard style overrides)
 */
const DropdownMenuHacked = styled(DropdownMenu)`
  @media (max-width: 991.98px) {
    position: static!important;
    will-change: unset!important;
    top: 100%!important;
    left: 0!important;
    transform: unset!important;
  }
`;

const LowercaseNavbarBrand = styled(NavbarBrand)`
  text-transform: lowercase;
`

class DemoNavbar extends React.Component {
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  }
  state = {
    collapseClasses: "",
    collapseOpen: false
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out"
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: ""
    });
  };

  render() {
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <Container>
              <LowercaseNavbarBrand className="mr-lg-5" href="https://www.accountedfor.com.au">
                accountedfor.com.au
              </LowercaseNavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <a href="https://www.accountedfor.com.au">
                        accountedfor.com.au
                      </a>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <span className="nav-link-inner--text">Products</span>
                    </DropdownToggle>
                    <DropdownMenuHacked>
                      <DropdownItem to="/lodge/abn-application" tag={Link}>
                        ABN Application
                      </DropdownItem>
                      <DropdownItem to="/lodge/business-name-application" tag={Link}>
                        Business Name Registration
                      </DropdownItem>
                      <DropdownItem to="/lodge/company-registration" tag={Link}>
                        Company Registration
                      </DropdownItem>
                    </DropdownMenuHacked>
                  </UncontrolledDropdown>
                </Nav>
                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="/register"
                    >
                      Register
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="/sign-in-legacy"
                    >
                      Password Sign In
                    </NavLink>
                  </NavItem>
                  <NavItem className="align-items-lg-center ml-lg-auto">
                    <Button
                      className="btn-neutral btn-icon"
                      color="default"
                      href="/sign-in"
                    >
                      <span className="btn-inner--icon">
                        <i className="fas fa-lock" />
                      </span>
                      <span className="nav-link-inner--text ml-1">
                        Sign In
                      </span>
                    </Button>
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default DemoNavbar;