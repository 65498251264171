import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router";
import { DateTime } from "luxon";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import { DatePicker } from "reactstrap-date-picker";

import { getPasskeyJWTAsync } from "../../../webauthn/securityStepUp";

import { getInvoiceByUUID, getInvoiceAttachment } from "./invoice";

const ExpenseDetail = () => {
  const history = useHistory();
  const params = useParams();

  const [currentExpenseUUID, setCurrentExpenseUUID] = useState();

  const [loading, setLoading] = useState(false);

  const [file, setFile] = useState();
  const [temporaryFileDataURL, setTemporaryFileDataURL] = useState();

  const [invoiceLoaded, setInvoiceLoaded] = useState(false);

  // Express Expense details
  const [totalAmount, setTotalAmount] = useState();
  const [taxAmount, setTaxAmount] = useState();
  const [supplierName, setSupplierName] = useState();

  const [invoiceDate, setInvoiceDate] = useState(new Date().toISOString());
  const [invoiceNotes, setInvoiceNotes] = useState("");

  const [invoiceAttachmentPresignedURL, setInvoiceAttachmentPresignedURL] =
    useState();

  const calculateInvoiceTotal = (lineItems) =>
    lineItems.reduce((previous, current) => {
      return (
        previous +
        current.quantity *
          current.unit_price *
          (parseFloat(current.tax_rate ?? 0) === 0
            ? 1
            : (parseFloat(current.tax_rate) + 1) / 10)
      );
    }, 0);

  const calculateInvoiceTax = (lineItems) =>
    lineItems.reduce((previous, current) => {
      return (
        previous +
        (current.quantity * current.unit_price) /
          (parseFloat(current.tax_rate ?? 0) === 0
            ? 1
            : parseFloat(current.tax_rate))
      );
    }, 0);

  useEffect(() => {
    (async () => {
      if (params.uuid !== "add") {
        setCurrentExpenseUUID(params.uuid);
        const invoice = await getInvoiceByUUID(params.uuid);

        if (invoice) {
          const invoiceAttachment = await getInvoiceAttachment(
            invoice.attachment_s3_object_key
          );
          setInvoiceAttachmentPresignedURL(invoiceAttachment.presigned_url);

          setInvoiceDate(invoice.effective_date);

          setTotalAmount(calculateInvoiceTotal(invoice.line_items));
          setTaxAmount(calculateInvoiceTax(invoice.line_items));

          setInvoiceNotes(invoice.notes);
        }
      }

      setInvoiceLoaded(true);
    })();
  }, [params.uuid, setCurrentExpenseUUID, setInvoiceDate, setInvoiceLoaded]);

  const fileSelected = async (f) => {
    setFile(f);

    let reader = new FileReader();
    reader.onload = function () {
      setTemporaryFileDataURL(reader.result);
    };
    reader.readAsDataURL(f);
  };

  const saveExpense = async () => {
    const principalLegalEntity = JSON.parse(
      localStorage.getItem("principalLegalEntity")
    );

    if (!principalLegalEntity) {
      alert("Error: You must submit Expenses with Passkey at this time");
      return;
    }

    setLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/expense/create`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
          "x-principal-legal-entity-uuid":
            principalLegalEntity.legal_entity_uuid,
        },
        method: "post",
        body: JSON.stringify({
          effective_date: invoiceDate,
          total_amount: 0,
          tax_amount: 0,
          notes: "",
          file_name: file?.name ?? "",
        }),
      }
    );

    if (response.ok) {
      const expenseJSON = await response.json();

      if (expenseJSON.invoice_uuid && file) {
        setCurrentExpenseUUID(expenseJSON.invoice_uuid);

        const uploadResponse = await fetch(`${expenseJSON.presigned_url}`, {
          method: "PUT",
          body: file,
        });

        if (uploadResponse.ok) {
          const textractResponse = await fetch(
            `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/expense/${expenseJSON.invoice_uuid}/apply-textract`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
                "x-principal-legal-entity-uuid":
                  principalLegalEntity.legal_entity_uuid,
              },
              method: "post",
              body: JSON.stringify({}),
            }
          );

          if (textractResponse.ok) {
            //alert("textract success");

            const textractJSON = await textractResponse.json();

            if (
              textractJSON?.analysed_expense?.suggested_expense?.effective_date
            ) {
              setInvoiceDate(
                DateTime.fromFormat(
                  textractJSON?.analysed_expense?.suggested_expense
                    ?.effective_date,
                  "dd/MM/yyyy"
                )
              );
            } else {
              alert("Could not determine transaction date");
            }

            setTotalAmount(
              textractJSON?.analysed_expense?.suggested_expense?.total_amount
            );
            setTaxAmount(
              textractJSON?.analysed_expense?.suggested_expense?.tax_amount
            );
            setSupplierName(
              textractJSON?.analysed_expense?.suggested_expense?.manual_supplier
            );
          } else {
            alert("error autofilling");
          }
        } else {
          alert("Error uploading attachment");
        }
      }
    } else {
      alert("Error saving expense");
    }
    setLoading(false);
  };

  const updateExpense = async () => {
    const principalLegalEntity = JSON.parse(
      localStorage.getItem("principalLegalEntity")
    );

    if (!principalLegalEntity) {
      alert("Error: You must submit Expenses with Passkey at this time");
    }

    setLoading(true);
    const response = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/expense/${currentExpenseUUID}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
          "x-principal-legal-entity-uuid":
            principalLegalEntity.legal_entity_uuid,
        },
        method: "put",
        body: JSON.stringify({
          effective_date: invoiceDate,
          total_amount: totalAmount ?? "",
          tax_amount: taxAmount ?? "",
          notes: invoiceNotes ?? "",
        }),
      }
    );

    if (response.ok) {
      history.push("/dashboard/expenses");
    }
  };

  const getSupplierList = async (inputValue) => {
    const principalLegalEntity = JSON.parse(
      localStorage.getItem("principalLegalEntity")
    );

    if (principalLegalEntity?.agent_legal_entity?.customer_uuid) {
      const response = await fetch(
        `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/passkey/counterparty/supplier-search`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${await getPasskeyJWTAsync()}`,
            "x-principal-legal-entity-uuid":
              principalLegalEntity?.legal_entity_uuid,
          },
          method: "post",
          body: JSON.stringify({
            query: inputValue,
          }),
        }
      );

      const json = await response.json();

      const options = json.counterparties
        .filter((counterparty) => !counterparty.supplier)
        .map((counterparty) => {
          const label = counterparty.business_name
            ? counterparty.business_name
            : `${counterparty.first_name ?? ""} ${
                counterparty.last_name ?? ""
              }`;
          return {
            value: counterparty.uuid,
            label: label,
          };
        });

      return options;
    } else {
      const authorization = await getAuthorization();
      const response = await fetch(
        `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/counterparty/supplier-search`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authorization,
          },
          method: "post",
          body: JSON.stringify({
            query: inputValue,
          }),
        }
      );

      const json = await response.json();

      const options = json.contacts.map((contact) => {
        const label = contact.business_name
          ? contact.business_name
          : `${contact.first_name ?? ""} ${contact.last_name ?? ""}`;
        return {
          value: contact.uuid,
          label: label,
        };
      });

      return options;
    }
  };

  const loadAsyncSelectSupplierList = async (inputValue) => {
    const options = await getSupplierList(inputValue);

    return options;
  };

  return (
    <>
      <Container className="mt--5" fluid>
        <Row>
          <Col
            className="order-xl-1"
            xs={file || invoiceAttachmentPresignedURL ? "7" : "12"}
          >
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs={12}>
                    <h3 className="mb-0">Expense</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row className="align-items-center d-sm-none d-block">
                  <Col className="text-center mb-4" xs="12">
                    <Button
                      color="primary"
                      onClick={() => setPreviewPDFModalIsOpen(true)}
                      size="sm"
                    >
                      Preview PDF
                    </Button>
                  </Col>
                </Row>
                <Form>
                  <Row>
                    <Col className="mb-4" xs="12">
                      <h6 className="heading-small text-muted mb-4">
                        Expense File Upload
                      </h6>
                      <div className="pl-lg-4">
                        <Input
                          className="form-control"
                          type="file"
                          onChange={(e) => fileSelected(e.target.files[0])}
                        />
                        <br />
                        <Button
                          color="primary"
                          onClick={() => saveExpense()}
                          size="md"
                          disabled={loading}
                        >
                          {loading ? (
                            <Spinner
                              animation="border"
                              variant="light"
                              size="sm"
                            />
                          ) : null}{" "}
                          Upload & AUTOFILL
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Invoice Information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Invoice Date
                          </label>
                          <DatePicker
                            value={invoiceDate}
                            onChange={(value) => setInvoiceDate(value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="8">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            Total Amount
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={totalAmount}
                            placeholder="Expense Amount Including Tax"
                            type="text"
                            onChange={(e) => setTotalAmount(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="8">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            Tax Amount
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={taxAmount}
                            placeholder="Tax Amount"
                            type="text"
                            onChange={(e) => setTaxAmount(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    {/*<Row>
                      <Col xs="8">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            Supplier Name
                          </label>
                          <Input
                            className="form-control-alternative"
                            value={supplierName}
                            placeholder="Supplier Name"
                            type="text"
                            onChange={(e) => setSupplierName(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>*/}
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">Notes</h6>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <label>Expense notes</label>
                      <Input
                        className="form-control-alternative"
                        rows="4"
                        type="textarea"
                        value={invoiceNotes}
                        onChange={(e) => setInvoiceNotes(e.target.value)}
                      />
                    </FormGroup>
                  </div>
                </Form>
              </CardBody>
              <CardFooter>
                <Row className="align-items-center">
                  <Col className="text-right d-sm-none" xs="6"></Col>
                  <Col className="text-right d-sm-none" xs="6">
                    <Button
                      color="success"
                      onClick={updateExpense}
                      size="md"
                      disabled={!currentExpenseUUID || loading}
                    >
                      {loading ? (
                        <Spinner animation="border" variant="light" size="sm" />
                      ) : null}{" "}
                      Save Expense
                    </Button>
                  </Col>
                  <Col className="text-right d-none d-sm-block" sm="12">
                    <Button
                      color="success"
                      onClick={updateExpense}
                      size="md"
                      disabled={!currentExpenseUUID || loading}
                    >
                      {loading ? (
                        <Spinner animation="border" variant="light" size="sm" />
                      ) : null}{" "}
                      Save Expense
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
          {file ? (
            <Col className="order-xl-1" xs={file ? "5" : "0"}>
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col>
                      <h3 className="mb-0">Preview</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="mb-4 text-center" xs="12">
                      {file?.type === "application/pdf" ? (
                        <>
                          {temporaryFileDataURL ? (
                            <iframe
                              title="Expense Attachment"
                              width="100%"
                              height={700}
                              src={temporaryFileDataURL}
                            />
                          ) : undefined}
                        </>
                      ) : (
                        <img
                          src={temporaryFileDataURL}
                          style={{ width: 500, maxWidth: "100%" }}
                          alt="Customer receipt"
                        />
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          ) : undefined}
          {invoiceAttachmentPresignedURL ? (
            <Col
              className="order-xl-1"
              xs={invoiceAttachmentPresignedURL ? "5" : "0"}
            >
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col>
                      <h3 className="mb-0">Expense Document</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row className="align-items-center d-sm-none d-block"></Row>
                  <Row>
                    <Col className="mb-4 text-center" xs="12">
                      <iframe
                        title="Expense Attachment"
                        width="100%"
                        height={700}
                        src={invoiceAttachmentPresignedURL}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          ) : undefined}
        </Row>
      </Container>
    </>
  );
};

export default ExpenseDetail;
