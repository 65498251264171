import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';
import {
  Card,
  CardHeader,
  CardFooter,
  Table,
  Container,
  Row,
  Col,
  Badge,
  Button,
  Modal,
  ModalHeader,
  Form,
  FormGroup,
  CardBody,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Spinner
} from 'reactstrap';
import { getAuthorization } from 'webauthn/session';


const refreshTransactions = async () => {
  const response = await fetch(`${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/banking/tpa/transactions`, {
    headers: {
      Authorization: await getAuthorization(),
    },
  })
  const json = await response.json()
  return json.transactions;
}

const NativeBanking = (props) => {
  const features = props.features;

  const [loading, setLoading] = useState(false);
  const [transactions, setTransactions] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [isPaymentContactOpen, setPaymentContactIsOpen] = useState(false);


  useEffect(() => {
    (async () => {
      const transactions = await refreshTransactions();
      setTransactions(transactions)
    })()
  }, [setTransactions])

  // State variables and change handlers for the Make Payment modal
  const [bsb, setBSB] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  // State variables and change handlers for the Add Payment Contact modal
  const [contactAccountName, setContactAccountName] = useState('');
  const [contactBSB, setContactBSB] = useState('');
  const [contactAccountNumber, setContactAccountNumber] = useState('a');

  const validateBSB = (newBSB, changeBSB) => {
    if (newBSB.length <= 6) {
      changeBSB(newBSB.replace(/\D/, ''))
    }
  }

  const validateAccountNumber = (newAccountNumber, changeAccountNumber) => {
    changeAccountNumber(newAccountNumber.replace(/\D/, ''))
  }

  const validatePayment = async () => {
    if (bsb.length !== 6) {
      alert('BSB must be 6 digits');
      return
    }

    if (accountNumber === '') {
      alert('Account number is required');
      return
    }

    if (amount === '') {
      alert('Amount is required');
      return
    }

    setLoading(true);
    const payment = {
      bsb: bsb,
      account_number: accountNumber,
      amount: amount,
      description: description,
    }


    const result = await makePayment(payment);

    if (result.status === 'ok') {
      setIsOpen(false);
      setLoading(false);
      setPaymentContactIsOpen(false);

      setBSB('')
      setAccountNumber('')
      setAmount('')
      setDescription('')

      alert('Payment made successfully');

      const transactions = await refreshTransactions();
      setTransactions(transactions);

    } else {
      setLoading(false);
      alert(`Error: ${result.message}`);
    }

  }

  const pay = async () => {
    setIsOpen(true);
  }
  const paymentContact = async () => {
    setPaymentContactIsOpen(true);
  }

  const makePayment = async (payment) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/banking/tpa/pay`, {
        headers: {
          Authorization: await getAuthorization(),
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(payment)
      });

      if (response.ok) {
        const json = await response.json();
        return json;
      } else {
        return {};
      }

    } catch (e) {
      alert(`An unknown error occurred. (Server said: ${e})`);
    }
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
      >
        <ModalHeader className="modal-header bg-secondary" toggle={() => setIsOpen(false)}>Make Payment</ModalHeader>
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0">
            {/*<CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                <small>Payment From Weel</small>
              </div>
              <div className="btn-wrapper text-center">
                Balance: {props.businessProfile ? formatCurrency(props.businessProfile.total_balance) : ''}
              </div>
              <div className="btn-wrapper text-center mt-3">
                <Input type="select" name="select" onChange={(e) => setBudgetId(e.target.value)}>
                  {budgetList ? budgetList.map((budget) => {
                    return <option key={budget.id} value={budget.id}>{budget.name} - Limit: {formatCurrency(budget.current_amount - budget.spent_amount)}</option>
                  })
                    : undefined}

                </Input>
              </div>
            </CardHeader>*/}
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Receiving Bank Details</small>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-bank"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="BSB" type="text" value={bsb} onChange={(e) => validateBSB(e.target.value, setBSB)} />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-badge" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Account Number" type="text" value={accountNumber} onChange={(e) => validateAccountNumber(e.target.value, setAccountNumber)} />
                  </InputGroup>
                </FormGroup>
                <hr />
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-coins" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Amount" type="text" value={amount} onChange={(e) => setAmount(e.target.value)} />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-pencil" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Description" type="text" value={description} onChange={(e) => setDescription(e.target.value)} />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={() => validatePayment()}
                    disabled={loading}
                  >
                    {loading
                      ? <Spinner animation="border" variant="light" size="sm" />
                      : null
                    } Make Payment
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>

      {/* Modal for the + Payment Contact Button */}
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={isPaymentContactOpen}
        toggle={() => setPaymentContactIsOpen(!isPaymentContactOpen)}
      >
        <ModalHeader className="modal-header bg-secondary" toggle={() => setPaymentContactIsOpen(false)}>Make Payment</ModalHeader>
        <div className="modal-body p-0">
          <Card className="bg-secondary border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small>Add New Payment Contact</small>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-user"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Account Name" type="text" value={contactAccountName} onChange={(e) => setContactAccountName(e.target.value)} />
                  </InputGroup>
                </FormGroup>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-bank"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="BSB" type="text" value={contactBSB} onChange={(e) => validateBSB(e.target.value, setContactBSB)} />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-badge" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Account Number" type="text" value={contactAccountNumber} onChange={(e) => validateAccountNumber(e.target.value, setContactAccountNumber)} />
                  </InputGroup>
                </FormGroup>
                <hr />
                <div className="text-center">
                  <Button
                    className="my-4"
                    color="primary"
                    type="button"
                    onClick={() => validatePayment()}
                    disabled={loading}
                  >
                    {loading
                      ? <Spinner animation="border" variant="light" size="sm" />
                      : null
                    }Save
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </div>
      </Modal>


      {/* Page content */}
      <Container className="mt--5" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Transactions - Operating Account</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    {features.showTPAPaymentContacts?.enabled
                      ? <Button
                        color="primary"
                        onClick={() => paymentContact()}
                        size="sm"
                      >
                        <i className="fa fa-user-plus"></i>
                        &nbsp; Payment Contact
                      </Button>
                      : undefined}
                    <Button
                      color="success"
                      onClick={() => pay()}
                      size="sm"
                    >
                      Transfer Funds
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Date</th>
                    <th scope="col">Description</th>
                    <th scope="col">Status</th>
                    <th scope="col" className="text-right">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    transactions.map((transaction) => {
                      return (
                        <tr key={transaction.id}>
                          <th scope="row">
                            {DateTime.fromISO(transaction.cleared_date ?? transaction.created_at).toLocaleString(DateTime.DATE_MED)}
                          </th>
                          <td>{transaction.description || transaction.data?.line_items?.[0]?.description}</td>
                          <td>
                            <Badge color="" className="badge-dot mr-4">
                              {transaction.cleared_date
                                ? <><i className="bg-success" />cleared</>
                                : <><i className="bg-info" />pending</>
                              }
                            </Badge>
                          </td>
                          <td className="text-right">{parseFloat(parseFloat(transaction.amount).toLocaleString()).toFixed(2)} {transaction.currency}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
              <CardFooter className="py-4">
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default NativeBanking;