import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from 'reactstrap';

import { Amplify, Auth } from 'aws-amplify';

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  },
});

const ResetPassword = () => {
  const history = useHistory();

  /*
   *  State variables
   */
  const [email, setEmail] = useState(localStorage.getItem('lastAttemptedEmail'));
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  /*
   *  Password reset verification action
   */
  const resetPassword = async (email, verificationCode, newPassword) => {
    if (verificationCode === '') {
      setErrorMessage('Email and Verification Code are both required');
    } else {
      setLoading(true);

      try {
        await Auth.forgotPasswordSubmit(email, verificationCode, newPassword);
        history.push('/dashboard');

        // The AWS Amplify Hub will take over from here if successful
      } catch (e) {
        console.log(e.message)
        setErrorMessage(`Error: ${e.message}`)

        setLoading(false);
      }
    }
  }


  return (
    <>
      <DemoNavbar />
      <main>
        <section className="section section-shaped section-lg">
          <div className="shape shape-style-1 bg-gradient-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="pt-lg-7">
            <Row className="justify-content-center">
              <Col lg="5">
                <Card className="bg-secondary shadow border-0">
                  <CardHeader className="bg-white">
                    <div className="text-muted text-center mb-3">
                      <small>Verification Required</small>
                    </div>
                    <div className="text-center">

                      <i className="fas fa-lock fa-3x" />
                    </div>
                  </CardHeader>
                  <CardBody className="px-lg-5 pb-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>A verification code has been sent to your email address</small>
                    </div>
                    {errorMessage === ''
                      ? null
                      : <p className="text-center text-danger">{errorMessage}</p>
                    }
                    <Form role="form">
                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email"
                            type="text"
                            value={email}
                            onChange={e => { localStorage.setItem('lastAttemptedEmail', e.target.value); setEmail(e.target.value) }}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-key-25" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Verification Code"
                            type="text"
                            onChange={e => setVerificationCode(e.target.value)}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="New Password"
                            type="password"
                            onChange={e => setNewPassword(e.target.value)}
                          />
                        </InputGroup>
                      </FormGroup>
                      <div className="text-center">
                        <Button
                          className="mt-4"
                          color="primary"
                          type="button"
                          onClick={() => resetPassword(email, verificationCode, newPassword)}
                          disabled={loading ? true : false}
                        >
                          {loading
                            ? <>
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-circle-notch fa-spin" />
                              </span>
                              <span className="btn-inner--text">
                                Magic At Work
                              </span>
                            </>
                            : <>
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-lock" />
                              </span>
                              <span className="btn-inner--text">
                                Reset Password
                              </span>
                            </>
                          }
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <SimpleFooter />
    </>
  );
}

export default ResetPassword;
