import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Progress,
} from 'reactstrap';
import { FaRegCheckCircle } from 'react-icons/fa';

import FlowNavbar from 'components/Navbars/FlowNavbar';
import SimpleFooter from 'components/Footers/SimpleFooter.js';


const BigTick = styled(FaRegCheckCircle)`
  font-size: 8rem;
  color: #28a745;
`;

const ThankYou = () => {

    // Stripe URL parameter for one-off checkouts
    const paymentIntent = new URLSearchParams(window.location.search).get('payment_intent');

    // Stripe URL parameter for subscriptions created
    const sessionId = new URLSearchParams(window.location.search).get('session_id');

    useEffect(() => {
        window.scrollTo(0, 0);

        (async () => {
            const options = {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    payment_intent: paymentIntent,
                    session_id: sessionId,
                }),
            };
            const result = await fetch(`${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/report-conversion-value`, options);
            const resultJSON = await result.json();

            if (paymentIntent) {
                window.gtag('event', 'conversion', {
                    'send_to': 'AW-312837668/UbbFCJ7hpqEDEKSMlpUB',
                    'value': resultJSON.amount,
                    'currency': 'AUD',
                    'transaction_id': paymentIntent,
                });
            } else if (sessionId) {
                window.gtag('event', 'conversion', {
                    'send_to': 'AW-312837668/GcXFCILfx7sDEKSMlpUB',
                    'value': resultJSON.amount,
                    'currency': 'AUD',
                    'transaction_id': sessionId,
                });
            }
        })();
    });

    const checkoutTitle = (planId) => {
        const titleMap = {
            1: 'New ABN Application',
            2: 'New ABN Application',
            3: 'New ABN Application',
            4: 'New ABN Application',
            5: 'New Business Name Registration',
            6: 'New Company Registration',
        }
        return titleMap[planId];
    }

    return (
        <>
            <FlowNavbar />
            <Container>
                <br />
                <h1>{checkoutTitle(localStorage.getItem('planId'))}</h1>
                <div className="progress-wrapper">
                    <div className="progress-info">
                        <div className="progress-label">
                            <span>Step 5 of 5: Thank you</span>
                        </div>
                        <div className="progress-percentage">
                            <span>100%</span>
                        </div>
                    </div>
                    <Progress max="100" value="100" />
                </div>
                <br />
                <Container>
                    <Row className="justify-content-center">
                        <Col lg="12">
                            <Row className="row-grid">
                                <Col lg="12">
                                    <Card className="shadow border-0">
                                        <CardBody className="text-center py-5">

                                            <h2 className="display-3">Thank you</h2>
                                            <br />
                                            <BigTick />
                                            <br />
                                            <p className="lead mb-md">
                                                Your new Company, ABN, business name and/or accounts will be emailed to you shortly
                                            </p>
                                            <h4>We have processed your transaction and will email you with confirmation details shortly</h4>

                                            <br />
                                            <br />

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <br />
            <br />
            <SimpleFooter />
        </>
    );
};

export default ThankYou;