/*
 * Note that this is required because we are using AWS Cognito
 * for session management. With AWS Cognito, passwords are a
 * required field at sign up.
 * See https://aws.amazon.com/blogs/mobile/implementing-passwordless-email-authentication-with-amazon-cognito/
 * for more information
 */
export const getRandomString = (bytes) => {
  const randomValues = new Uint8Array(bytes);
  window.crypto.getRandomValues(randomValues);
  return Array.from(randomValues).map((n) => n.toString(16).padStart(2, '0')).join('');
}