import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { DateTime } from "luxon";
import {
  Card,
  CardHeader,
  CardFooter,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Table,
  Container,
  Row,
  Col,
  Button,
  Badge,
} from "reactstrap";
//import { coerceToBase64Url, coerceToArrayBuffer } from 'fido2-lib/lib/utils';
//import { base64url } from 'base64-url';
import {
  encode as b64URLArrayBufferEncode,
  decode as decodeToBase64URLArrayBuffer,
} from "@qix/base64url-arraybuffer";

import { getAuthorization } from "webauthn/session";

const ListPasskeys = () => {
  const history = useHistory();

  const [passkeys, setPasskeys] = useState([]);

  useEffect(() => {
    (async () => {
      const authorization = await getAuthorization();
      const response = await fetch(
        `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/passkey`,
        {
          headers: {
            Authorization: authorization,
          },
        }
      );
      const json = await response.json();
      setPasskeys(json.passkeys);
    })();
  }, [setPasskeys]);

  const addPasskey = async () => {
    const authorization = await getAuthorization();
    const passkeyRequest = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/add-passkey/challenge`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
          Authorization: authorization,
        },
        body: JSON.stringify({}),
      }
    );
    if (passkeyRequest.ok) {
      const passkeyRequestJSON = await passkeyRequest.json();

      try {
        const publicKeyCredentialCreationOptions = {
          ...passkeyRequestJSON.credRequest,
          challenge: decodeToBase64URLArrayBuffer(
            passkeyRequestJSON.credRequest.challenge
          ),
          user: {
            ...passkeyRequestJSON.credRequest.user,
            id: decodeToBase64URLArrayBuffer(
              passkeyRequestJSON.credRequest.user.id
            ),
          },
        };

        const credentials = await navigator.credentials.create({
          publicKey: publicKeyCredentialCreationOptions,
        });

        const challenge = passkeyRequestJSON.credRequest.challenge;
        const credentialResponse = {
          rawId: b64URLArrayBufferEncode(credentials.rawId),
          response: {
            attestationObject: b64URLArrayBufferEncode(
              credentials.response.attestationObject
            ),
            clientDataJSON: b64URLArrayBufferEncode(
              credentials.response.clientDataJSON
            ),
          },
        };

        const passkeyChallengeResponse = await fetch(
          `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/customer/add-passkey/response?challenge=${challenge}`,
          {
            method: "post",
            headers: {
              "Content-Type": "application/json",
              Authorization: authorization,
            },
            body: JSON.stringify(credentialResponse),
          }
        );

        if (passkeyChallengeResponse.ok) {
          alert("passkey added successfully");
        }
      } catch (e) {
        alert(e.message);
      }
    }
  };

  return (
    <>
      {/* Page content */}
      <Container className="mt--5" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Passkeys</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="success"
                      onClick={() => addPasskey()}
                      size="sm"
                    >
                      Add Passkey
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Key Type</th>
                    <th scope="col">Authorised At</th>
                    <th scope="col" className="text-center">
                      Status
                    </th>
                    <th scope="col" className="text-center">
                      {/*Edit*/}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {passkeys.map((passkey, index) => {
                    return (
                      <tr key={index}>
                        <td>Passkey 🔑</td>
                        <td>
                          {DateTime.fromISO(passkey.created_at).toLocaleString(
                            DateTime.DATETIME_MED
                          )}
                        </td>
                        <td className="text-center">
                          {passkey.primary ? "Primary" : ""}
                        </td>
                        <td></td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <CardFooter className="py-4"></CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ListPasskeys;
