import React, { useState } from "react";
import { useHistory } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import { Amplify, Auth } from "aws-amplify";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_ID,
    userPoolWebClientId:
      process.env.REACT_APP_AWS_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
  },
});

const Register = () => {
  const history = useHistory();

  /*
   *  State variables
   */
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  /*
   *  Registration action
   */
  const registerUser = async ({ email, password }) => {
    if (email === "") {
      setErrorMessage("Email and Password are both required");
    } else {
      setLoading(true);

      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
        },
        autoSignIn: {
          enabled: true,
        },
      });

      localStorage.setItem("unverififedEmail", email);

      setLoading(false);

      history.push("/verify");
    }
  };
  return (
    <>
      <DemoNavbar />
      <main>
        <section className="section section-shaped section-lg">
          <div className="shape shape-style-1 bg-gradient-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
          <Container className="pt-lg-7">
            <Row className="justify-content-center">
              <Col lg="5">
                <Card className="bg-secondary shadow border-0">
                  <CardHeader className="bg-white">
                    <div className="text-muted text-center mb-3">
                      <small>Register for accountedfor.com.au</small>
                    </div>
                    <div className="text-center">
                      <i className="fas fa-lock fa-3x" />
                    </div>
                  </CardHeader>
                  <CardBody className="px-lg-5 pb-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>
                        Please provide an email address and a password
                      </small>
                    </div>
                    {errorMessage === "" ? null : (
                      <p className="text-center text-danger">{errorMessage}</p>
                    )}
                    <Form
                      role="form"
                      onSubmit={(e) => {
                        e.preventDefault();
                        registerUser({
                          email: email,
                          password: password,
                        });
                      }}
                    >
                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email"
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <InputGroup className="input-group-alternative mb-3">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-key-25" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Password"
                            type="password"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </InputGroup>
                      </FormGroup>
                      <div className="text-center">
                        <Button
                          className="mt-4"
                          color="primary"
                          type="button"
                          onClick={() =>
                            registerUser({
                              email: email,
                              password: password,
                            })
                          }
                          disabled={loading ? true : false}
                        >
                          {loading ? (
                            <>
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-circle-notch fa-spin" />
                              </span>
                              <span className="btn-inner--text">
                                Magic At Work
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="btn-inner--icon mr-1">
                                <i className="fas fa-lock" />
                              </span>
                              <span className="btn-inner--text">
                                Create account
                              </span>
                            </>
                          )}
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>
                <Row className="mt-3">
                  <Col className="text-left" xs="6">
                    <a className="text-light" href="/register">
                      <small>Register with biometrics</small>
                    </a>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <SimpleFooter />
    </>
  );
};

export default Register;
