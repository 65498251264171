import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Label,
  Input,
  Spinner,
  Button,
  Container,
  Row,
  Col,
  Badge,
} from "reactstrap";
import { FaArrowRight, FaCheck, FaTimes } from "react-icons/fa";

import FlowNavbar from "components/Navbars/FlowNavbar";
import ProgressBar from "../../../components/progressBar";
import SimpleFooter from "components/Footers/SimpleFooter.js";

const CompanyName = () => {
  const history = useHistory();
  const stepNumber = 1;

  const [pricing, setPricing] = useState("");

  const [companyName, setCompanyName] = useState("");
  const [companyNameSearchAttempted, setCompanyNameSearchAttempted] =
    useState(false);
  const [companyNameLoading, setCompanyNameLoading] = useState(false);
  const [companyNameAvailable, setCompanyNameAvailable] = useState(false);

  useEffect(() => {
    (async () => {
      const result = await fetch(
        `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/company-application/fees`
      );
      const resultJSON = await result.json();

      setPricing(resultJSON.pricing.price);
    })();
  }, [setPricing]);

  const searchName = async () => {
    setCompanyNameSearchAttempted(true);
    setCompanyNameLoading(true);

    const result = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/name-search?name=${companyName}`
    );
    const resultJSON = await result.json();

    localStorage.setItem("nameSearchResultId", resultJSON.nameSearchResultId);
    localStorage.setItem("companyName", companyName);

    if (resultJSON.result.code !== "Unavailable") {
      setCompanyNameAvailable(true);
    } else {
      setCompanyNameAvailable(false);
    }

    setCompanyNameLoading(false);
  };

  const submitForm = async () => {
    if (!companyName) {
      alert("Your company name must not be empty");
      return;
    }

    if (!companyNameSearchAttempted) {
      alert("You must click search before you can continue");
      return;
    }

    const result = await fetch(
      `${process.env.REACT_APP_ACCOUNTEDFOR_API_URL}/company-application`,
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          proposed_name: localStorage.getItem("companyName"),
        }),
      }
    );
    const json = await result.json();

    localStorage.setItem("companyUUID", json.company_uuid);

    history.push("/lodge/company-registration/members");
  };

  return (
    <>
      <FlowNavbar />
      <Container>
        <br />
        <ProgressBar
          title="New Company Registration"
          step={stepNumber}
          stepCount={5}
          stepName="Registration Deatils"
        />
        <br />
        <div>
          <Form
            onSubmit={async (e) => {
              e.preventDefault();
              await submitForm();
            }}
          >
            <Card className="text-left shadow border-1 mb-5">
              <CardHeader>Step {stepNumber}. Company Name Search</CardHeader>
              <CardBody>
                <h3>What you need to get started:</h3>
                <br />
                <Row>
                  <Col xs={12}>
                    <h3>Select a name</h3>
                    <p>
                      You must select a name for your company. Start typing to
                      search for an available name.
                    </p>
                  </Col>
                </Row>
                <br />
                <FormGroup tag="fieldset" row>
                  <Label sm={4} className="text-left">
                    Company Name
                  </Label>
                  <Col sm={5}>
                    <InputGroup>
                      <Input
                        placeholder="Type to search"
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                      <InputGroupAddon addonType="append">
                        PTY LTD
                      </InputGroupAddon>
                    </InputGroup>
                    <br />
                    <Button
                      type="submit"
                      color="success"
                      onClick={(_) => searchName()}
                      disabled={companyNameLoading}
                    >
                      {companyNameLoading ? (
                        <Spinner animation="border" variant="light" size="sm" />
                      ) : null}{" "}
                      Search
                    </Button>
                    <br />
                    <br />
                  </Col>
                  <Col sm={2}>
                    {companyNameSearchAttempted && !companyNameLoading ? (
                      companyNameAvailable ? (
                        <h3>
                          <Badge color="success">
                            Available <FaCheck />
                          </Badge>
                        </h3>
                      ) : (
                        <h3>
                          <Badge color="danger">
                            Unavailable <FaTimes />
                          </Badge>
                        </h3>
                      )
                    ) : null}
                  </Col>
                </FormGroup>
                <br />
              </CardBody>
              <CardFooter className="text-right">
                {companyNameSearchAttempted &&
                !companyNameLoading &&
                !companyNameAvailable
                  ? "By continuing you agree that you already own this business name"
                  : ""}
                &nbsp;
                <Button
                  type="submit"
                  color="success"
                  disabled={companyNameLoading}
                >
                  Continue <FaArrowRight />
                </Button>
              </CardFooter>
            </Card>
          </Form>
        </div>
      </Container>
      <SimpleFooter />
    </>
  );
};

export default CompanyName;
