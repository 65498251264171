/*
 *  Publishable Tax Documents utilities
 *
 */
export const taxDocument = () => {};

export const emptyLiveInvoiceUUID = "cf25eec8-6b83-403f-a920-4f6da5570f09";

export const taxDocumentLabel = (invoiceStatus) => {
  let label = "Invoice";

  switch (invoiceStatus) {
    case "QUOTE":
      label = "Quote";
      break;
    case "SENT":
      label = "Tax Invoice";
    case "PAID":
      label = "Tax Invoice";
      break;
    case "DRAFT":
      label = "Draft Invoice";
      break;
    case "TEST":
      label = "Test Invoice";
      break;
    case "TEST_PRO_FORMA":
      label = "Test Pro Forma Invoice";
      break;
    case "PRO_FORMA":
      label = "Pro Forma Invoice";
      break;
    default:
      label = "Invoice";
      break;
  }

  return label;
};

export const taxDocumentNavbarMerchantName = (businessName) => {
  return businessName ? businessName : "";
};

export const taxDocumentMerchantName = (businessName) => {
  return businessName ? businessName : "";
};

export const taxDocumentAddressLine1 = (addressLine1) => {
  return addressLine1 ? addressLine1 : "";
};

export const taxDocumentAddressLine2 = (addressLine2) => {
  return addressLine2 ? addressLine2 : "";
};
